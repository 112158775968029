import { FETCH_REQUIRED_CERT, LOADING_REQUIRED_CERT, LOADEN_REQUIRED_CERT } from "../../actions/Constants";

export default (requiredCertification = { requiredCertification: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_REQUIRED_CERT:
            return { ...requiredCertification, requiredCertification: action.data, loading: false };
        case LOADING_REQUIRED_CERT:
            return { ...requiredCertification, loading: true };
        case LOADEN_REQUIRED_CERT:
            return { ...requiredCertification, loading: false };
        default:
            return requiredCertification;
    }
}