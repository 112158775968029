import React, { useEffect, useState } from "react";
import CountUp from "react-countup";

//import
import CanvasChartTab from "../../Common/HomeCharts/CanvasChartTab";
import pMinDelay from "p-min-delay";
import loadable from "@loadable/component";
import VacanyTab from "../../Common/HomeCharts/VacanyTab";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAnalyticsStatistics } from "../../../../store/actions/applicants";
import moment from "moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Loading from "../../../layouts/loadingComponents/Loading";
import "./Statistics.css";

let lng = JSON.parse(localStorage.getItem("language"))?.lang;
let chartData = ["#0B365C", "#008C77", "#FC563E", "#0E8C00", "#fc9a3e"];
const NewStatistics = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const NewCustomers1 = loadable(() =>
    pMinDelay(import("../../Common/HomeCharts/NewCustomers1"), 1000)
  );
  const NewCustomers2 = loadable(() =>
    pMinDelay(import("../../Common/HomeCharts/NewCustomers2"), 1000)
  );
  const NewCustomers3 = loadable(() =>
    pMinDelay(import("../../Common/HomeCharts/NewCustomers3"), 1000)
  );
  const NewCustomers4 = loadable(() =>
    pMinDelay(import("../../Common/HomeCharts/NewCustomers4"), 1000)
  );
  const ApexPieChart = loadable(() =>
    pMinDelay(import("../../Common/HomeCharts/ApexPieChart"), 1000)
  );
  const [locationJobSwitch, setLocationJobSwitch] = useState(1);
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [customDate, setCustomDate] = useState(false);
  const [customStartDate, setCustomStartDate] = useState();
  const [customEndDate, setCustomEndDate] = useState();
  const [type, setType] = useState("TODAY");
  const { statistics, loading } = useSelector((state) => state?.statistics);

  useEffect(() => {
    console.log("moment", moment(new Date()).format("DD MMMM YYYY hh:mm A"));
    if (type) {
      setLoadingModalShow(true);
      let start_date;
      let end_date = moment(new Date()).format("YYYY-MM-DD");
      if(type === "CUSTOM") {
        setLoadingModalShow(false);
        setCustomDate(true);
        return false;
      } else {
        setCustomDate(false)
        if (type === "TODAY") {
          start_date = end_date;
        } else if (type === "WEEK") {
          start_date = moment(end_date).subtract(1, "WEEK").format("YYYY-MM-DD");
        } else if (type === "MONTH") {
          start_date = moment(end_date).subtract(1, "MONTH").format("YYYY-MM-DD");
        }
      }
      dispatch(
        getAnalyticsStatistics(
          start_date,
          end_date,
          setLoadingModalShow,
          lng === "arab" ? 1 : 2
        )
      );
    }
  }, [type]);

  useEffect(() => {
    if (customStartDate && customEndDate) {
      setLoadingModalShow(true);
      dispatch(
        getAnalyticsStatistics(
          moment(customStartDate).format("YYYY-MM-DD"),
          moment(customEndDate).format("YYYY-MM-DD"),
          setLoadingModalShow,
          lng === "arab" ? 1 : 2
        )
      );
    }
  }, [customStartDate, customEndDate]);

  return (
    <>
      {loadingModalShow ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "306px",
          }}
        >
          <Loading />
        </div>
      ) : (
        <>
          <div
            className="row pb-5"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div className="col-lg-2 mb-4">
              <label className="form-label font-w600">
                {t("Search By Date")}
              </label>
              <select
                onChange={(e) => setType(e.target.value)}
                name="state"
                className="form-control"
                value={type}
              >
                {/* <option value="Select">Select</option> */}
                <option value="TODAY">Today</option>
                <option value="WEEK">Last 7 Days</option>
                <option value="MONTH">Last 30 Days</option>
                <option value="CUSTOM">Custom</option>
              </select>
            </div>
            {customDate ? (
              <>
                <div className="col-lg-2 mb-4">
                  <label className="form-label font-w600">
                    {t("Start Date")}
                  </label>
                  <div className="input-group">
                    <div className="input-group-text">
                      <i className="far fa-clock"></i>
                    </div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk
                        label=""
                        clearable
                        className="form-control form_datetime"
                        name="start_date"
                        format="dd/MM/yyyy"
                        value={customStartDate}
                        onChange={(date) => setCustomStartDate(date)}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div className="col-lg-2 mb-4">
                  <label className="form-label font-w600">
                    {t("End Date")}
                  </label>
                  <div className="input-group">
                    <div className="input-group-text">
                      <i className="far fa-clock"></i>
                    </div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk
                        label=""
                        clearable
                        className="form-control form_datetime"
                        name="end_date"
                        format="dd/MM/yyyy"
                        value={customEndDate}
                        onChange={(date) => setCustomEndDate(date)}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </>
            ) : null}
          </div>

          <div className="statistics-page-row">
            <div className="col-xl-6 col-lg-12 col-md-12">
              <div
                className="card"
                style={{ minHeight: "400px", marginBottom: "unset" }}
              >
                <div className="card-body">
                  <div className="row">
                    <h4 className="fs-20 mb-3">{t("Applications Statuses")}</h4>
                    {statistics?.applicationsStatuses?.length === 0 ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          minHeight: "200px",
                        }}
                      >
                        <h6>No data found check with other date</h6>
                      </div>
                    ) : (
                      statistics?.applicationsStatuses?.map((item, index) => (
                        <div className="col-6">
                          <div className="job-icon pb-4 d-flex justify-content-between">
                            <div>
                              <div className="d-flex align-items-center mb-1">
                                <h2 className="mb-0">{item?.count}</h2>
                              </div>
                              <span className="fs-14 d-block mb-2">
                                {item?.status?.name}
                              </span>
                            </div>
                            <div id="NewCustomers">
                              <NewCustomers1 />
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-12 col-md-12">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card" style={{ minHeight: "400px" }}>
                    <div className="card-body">
                      <div className="row align-items-center">
                        <h4 className="fs-20 mb-3">
                          {t("Application Summary")}
                        </h4>
                        <div className="custom_switcher">
                          <label
                            style={{
                              padding: "3px",
                              borderBottom:
                                locationJobSwitch === 2
                                  ? "1px solid #008C77"
                                  : "unset",
                              color: locationJobSwitch === 2 ? "#008C77" : null,
                            }}
                            onClick={() => setLocationJobSwitch(2)}
                          >
                            {t("Citizenship")}
                          </label>
                          <label
                            style={{
                              padding: "3px",
                              borderBottom:
                                locationJobSwitch === 0
                                  ? "1px solid #0B365C"
                                  : "unset",
                              color: locationJobSwitch === 0 ? "#0B365C" : null,
                            }}
                            onClick={() => setLocationJobSwitch(0)}
                          >
                            {t("Location")}
                          </label>
                          <label
                            style={{
                              padding: "3px",
                              borderBottom:
                                locationJobSwitch === 1
                                  ? "1px solid #FC563E"
                                  : "unset",
                              color: locationJobSwitch === 1 ? "#FC563E" : null,
                            }}
                            onClick={() => setLocationJobSwitch(1)}
                          >
                            {t("Jobs")}
                          </label>
                        </div>

                        {locationJobSwitch === 0 ? (
                          <div className="col-xl-6 col-sm-6">
                            {statistics?.applicants?.applicantsByLocations
                              ?.slice(0, 5)
                              ?.map((item, i) => (
                                <div
                                  className="mt-2"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span
                                    style={{
                                      backgroundColor: chartData[i],
                                      width: "12px",
                                      height: "12px",
                                      float: "left",
                                      borderRadius: "50%",
                                    }}
                                  ></span>
                                  <span className="fs-14 font-w500">
                                    {item?.country}
                                  </span>
                                  <span className="fs-16">
                                    <span className="text-black pe-2"></span>
                                    {item?.count}
                                  </span>
                                </div>
                              ))}
                          </div>
                        ) : locationJobSwitch === 1 ? (
                          <div className="col-xl-6 col-sm-6">
                            {statistics?.applicants?.applicantsByJobs
                              ?.slice(0, 5)
                              ?.map((item, i) => (
                                <div
                                  className="mt-2"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span
                                    style={{
                                      backgroundColor: chartData[i],
                                      width: "12px",
                                      height: "12px",
                                      float: "left",
                                      borderRadius: "50%",
                                    }}
                                  ></span>
                                  <span className="fs-14 font-w500">
                                    {item?.job?.title}
                                  </span>
                                  <span className="fs-16">
                                    <span className="text-black pe-2"></span>
                                    {item?.count}
                                  </span>
                                </div>
                              ))}
                          </div>
                        ) : locationJobSwitch === 2 ? (
                          <div className="col-xl-6 col-sm-6">
                            {statistics?.applicants?.applicantsByCitizenships
                              ?.slice(0, 5)
                              ?.map((item, i) => (
                                <div
                                  className="mt-2"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span
                                    style={{
                                      backgroundColor: chartData[i],
                                      width: "12px",
                                      height: "12px",
                                      float: "left",
                                      borderRadius: "50%",
                                    }}
                                  ></span>
                                  <span className="fs-14 font-w500">
                                    {item?.name}
                                  </span>
                                  <span className="fs-16">
                                    <span className="text-black pe-2"></span>
                                    {item?.count}
                                  </span>
                                </div>
                              ))}
                          </div>
                        ) : (
                          <></>
                        )}
                        {locationJobSwitch === 0 ? (
                          <div className="col-xl-6 col-sm-6">
                            <div id="pieChart1">
                              <ApexPieChart
                                data={statistics?.applicants?.applicantsByLocations?.slice(
                                  0,
                                  5
                                )}
                                color={chartData}
                              />
                            </div>
                          </div>
                        ) : locationJobSwitch === 1 ? (
                          <div className="col-xl-6 col-sm-6">
                            <div id="pieChart1">
                              <ApexPieChart
                                data={statistics?.applicants?.applicantsByJobs?.slice(
                                  0,
                                  5
                                )}
                                color={chartData}
                              />
                            </div>
                          </div>
                        ) : locationJobSwitch === 2 ? (
                          <div className="col-xl-6 col-sm-6">
                            <div id="pieChart1">
                              <ApexPieChart
                                data={statistics?.applicants?.applicantsByCitizenships?.slice(
                                  0,
                                  5
                                )}
                                color={chartData}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default NewStatistics;
