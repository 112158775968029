import * as api from "../../services/API";
import checkErr from "./api_error_func";
import { toastAlert } from "../../jsx/layouts/toastAlert/toastAlert";

export const createHelpRequest =
  (data, setLoadingModalShow) => async (dispatch) => {
    try {
      const result = await api.customerService(data);
      setLoadingModalShow(false);
      toastAlert("Your Request Sended Successfully", undefined, "success");
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  };
