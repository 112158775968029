import { lazy, Suspense, useEffect, useState, useContext } from "react";
import Index from "./jsx";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Loading from "./jsx/layouts/loadingComponents/Loading";
import { getUserInfo } from "./store/actions/Auth";
import { getDraftsJobs, getAcademyJobs, getJobStatus } from "./store/actions/Jobs";
import {useLocation} from "react-router-dom";
import { useSelector } from "react-redux";
import { initReactI18next } from "react-i18next";
import { TRANSLATIONS_ARAB } from "./translations/arab/translations";
import i18n from "i18next";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./assets/css/style.css";
import { ThemeContext } from "./context/ThemeContext";
import axios from "axios";
import AuthError from "./jsx/pages/authError";
import { getAcademyApplicants } from "./store/actions/applicants";

const resources = {
  arab: {
    translation: TRANSLATIONS_ARAB,
  },
};
let languageFromLocal = JSON.parse(localStorage.getItem("language"));
i18n.use(initReactI18next).init({
  resources,
  lng: languageFromLocal ? languageFromLocal.lang : "arab",
});
let allTextTypes = [
  "label",
  "a",
  "button",
  "div",
  "li",
  "p",
  "text",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "span",
  "input",
];

function App(props) {
  const { changeDirectionLayout } = useContext(ThemeContext);
  useEffect(() => {
    for (let i = 0; i < allTextTypes.length; i++) {
      let allElements = document.getElementsByTagName(allTextTypes[i]);
      for (let j = 0; j < allElements.length; j++) {
        allElements[j].style.fontFamily = languageFromLocal?.lang
          ? languageFromLocal?.lang === "arab"
            ? "cairo"
            : "Poppins"
          : "Poppins";
      }
    }
  },[]);
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const tokenFromUrl = new URLSearchParams(search).get('t');
  useEffect(() => {
    if (tokenFromUrl) {
      localStorage.setItem("sess", tokenFromUrl);
      window.location.replace('/')
    } else if (localStorage.getItem("sess")) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("sess")}`;
      dispatch(getUserInfo(history));
    } else {
      window.location.replace('https://cvsa.mvp-apps.ae/')
    }
  }, [tokenFromUrl])
  const useAuth = useSelector((state) => state?.userDetails?.subTypeData);
  const loading = useSelector((state) => state?.userDetails?.authLoading);
  const [loadingState, setLoadingState] = useState(true);
  const [viewAs, setViewAs] = useState(3);


  let languageFromLocal = JSON.parse(localStorage.getItem("language"));
  const [changeOfLanguage, setChangeOfLanguage] = useState(0);
  useEffect(() => {
    if (!languageFromLocal) {
      setChangeOfLanguage(changeOfLanguage + 1);
      localStorage.setItem("language", JSON.stringify({ lang: "arab" }));
      changeDirectionLayout({ value: "rtl", label: "RTL" });
    }
  }, []);

  useEffect(() => {
    if (languageFromLocal) {
      i18n.changeLanguage(languageFromLocal.lang);
      if (languageFromLocal.lang === "arab") {
        changeDirectionLayout({ value: "rtl", label: "RTL" });
      } else {
        changeDirectionLayout({ value: "ltr", label: "LTR" });
      }
    }
  }, [changeOfLanguage]);
  useEffect(() => {
    setLoadingState(true);
    if ( !loading && Object.keys(useAuth).length > 0 ) {
      if (useAuth?.is_email_verified === '0' || useAuth?.is_active === '0' || !useAuth?.type) {
        localStorage.removeItem("LogedOut");
        window.location.replace('https://cvsa.mvp-apps.ae/')
      } else {
        if (useAuth?.type === "T") {
          setViewAs(1)
          localStorage.removeItem("LogedOut");
          setLoadingState(false);
        } else if (useAuth?.type === "A") {
          dispatch(getDraftsJobs(languageFromLocal?.lang === "arab" ? 1 : 2));
          localStorage.removeItem("LogedOut");
          setViewAs(0)
          setLoadingState(false);
        } else {
          setLoadingState(false);
        }
      }
    } else if (!loading && Object.keys(useAuth).length === 0) {
      setViewAs(4)
      setLoadingState(false);
    }
  }, [useAuth, loading]);
    return (
      <>
      {loadingState?
      <BigLoadingAuth loadingState={loadingState} />:
      viewAs === 4?
      localStorage.getItem("LogedOut")?
      <BigLoadingAuth loadingState={loadingState} />:
      <AuthError/>:
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index userType={viewAs} loading={loadingState} setLoadingState={setLoadingState}/>
        </Suspense>}
      </>
    );
}

export default App;

const BigLoadingAuth = ({loadingState}) => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loading />
    </div>
  );
};
