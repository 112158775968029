import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { savedJob, unSaveJob } from "../../../../store/actions/Jobs";
import Loading from "../../../layouts/loadingComponents/Loading";
import { Jobs } from "../home/NewHomeTeacher";
import { useTranslation } from "react-i18next";

let lng = JSON.parse(localStorage.getItem("language"))?.lang;

const NewSavedJobs = () => {
  const dispatch = useDispatch();
  const [loadingModalShow, setLoadingModalShow] = useState(true);
  const savedJobs = useSelector((state) => state?.savedJobs?.savedJobs);
  const savedJobsLoading = useSelector((state) => state?.savedJobs?.loading);
  const [forceLoad, setForceLoad] = useState(0)
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(savedJob(lng === "arab" ? 1 : 2));
  }, [forceLoad]);

  useEffect(() => {
    setLoadingModalShow(savedJobsLoading);
  }, [savedJobsLoading]);

  const handleUnSave = (item) => {
    setLoadingModalShow(true);
    dispatch(unSaveJob(item.id, setForceLoad, forceLoad, setLoadingModalShow));
  }

  return (
    <Fragment>
      {loadingModalShow ? (
        <div
          style={{
            minHeight: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        // <div className="bg-white">
        //   <div className="home-teacher-page container">
            <>
            <div className="home-teacher-page-group-1">
              <div className="home-teacher-page-group-1-label">{t("Saved Jobs")}</div>
            </div>
            <Jobs
              title={"There are no saved jobs :("}
              data={savedJobs}
              type={"Saved"}
              handleUnSave={handleUnSave}
            />
            </>
        //   </div>
        // </div>
      )}
    </Fragment>
  );
};

export default NewSavedJobs;
