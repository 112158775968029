import {
  FETCH_SAVED_JOBS,
  SAVED_JOBS_LOAD,
  SAVED_JOBS_LOADED,
} from "../../actions/Constants";

export default (savedJobs = { savedJobs: [], loading: false }, action) => {
  switch (action.type) {
    case FETCH_SAVED_JOBS:
      return { ...savedJobs, savedJobs: action.data, loading: false };
    case SAVED_JOBS_LOAD:
      return { ...savedJobs, loading: true };
    case SAVED_JOBS_LOADED:
      return { ...savedJobs, loading: false };
    default:
      return savedJobs;
  }
};
