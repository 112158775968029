import {
    FETCH_STAR,
    STAR_LOAD,
    STAR_LOADED,
  } from "../actions/Constants";
  
  export default (stars = { stars: [], perPage: 15, total: 0, currentPage: 1, nextPage: 1, lastPage: 1, loading: false}, action) => {
    switch (action.type) {
      case FETCH_STAR:
        return { 
          ...stars, 
          stars: action.data.data, 
          currentPage: action.data.current_page, 
          nextPage: 
              action.data.current_page === action.data.last_page? 
              action?.data?.current_page:
              action.data.last_page > action.data.current_page?
              action?.data?.current_page + 1:
              action.data.last_page, 
          lastPage: action.data.last_page, 
          perPage: action.data.per_page,
          total: action.data.total,
          loading: false 
        };
      case STAR_LOAD:
        return { ...stars, loading: true };
      case STAR_LOADED:
        return { ...stars, loading: false };
      default:
        return stars;
    }
  };