import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AuthError = () => {
    const { t } = useTranslation();
  return (
    <div style={{height: '100vh'}} className="container errorPage">
      <div className="row justify-content-center h-100 align-items-center ">
        <div style={{width: '100%'}}>
          <div className="form-input-content text-center error-page">
            <h1 className="font-weight-bold">{t("Something Went Wrong!")}</h1>
            <h5>
              <i className="fa fa-exclamation-triangle text-warning" />
              {t("An error occurred while processing data")}
            </h5>
            <p>
              {t(
                "Please try again later."
              )}
            </p>
            <div>
              <a className="btn btn-primary" href="/dashboard">
                {t("Back to Home")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthError;
