import {
  FETCH_TEACHER_INFO,
  TEACHER_INFO_LOAD,
  TEACHER_INFO_LOADED,
  FETCH_TEACHERS,
  LOAD_TEACHERS,
  LOADED_TEACHERS,
  FETCH_TEACHER_APPLICANTIONS,
  TEACHER_APPLICANTIONS_LOADED,
  TEACHER_APPLICANTIONS_LOAD,
  LICENSE_LOADED,
  FETCH_LICENSE,
  LICENSE_LOAD,
  DOCUMENT_LOAD,
  FETCH_DOCUMENT,
  DOCUMENT_LOADED,
  FETCH_SUGGESTED,
  SUGGESTED_LOAD,
  SUGGESTED_LOADED,
  INDUSTRY_LOAD,
  FETCH_INDUSTRY,
  INDUSTRY_LOADED,
} from "../actions/Constants";
import {
  updateTeacherEducation,
  destroyTeacherResume,
  getTeachers,
  getTeacherApplicantion,
  teacherInfo,
  updateTeacherGeneralInformation,
  teacherEducation,
  destroyTeacherEducation,
  destroyTeacherExperience,
  updateTeacherExperience,
  teacherExperience,
  teacherLicense,
  destroyTeacherLicense,
  updateTeacherLicense,
  licenseTypes,
  documentTypes,
  document,
  destroyTeacherDocument,
  suggestedForYou,
  industry,
  hideProfile,
  unHideProfile
} from "../../services/TeacherService";
import { toastAlert } from "../../jsx/layouts/toastAlert/toastAlert";
import checkErr from "./api_error_func";
import { useDispatch } from "react-redux";
import { t } from "i18next";

let lng = JSON.parse(localStorage.getItem("language"))?.lang;

export const getAllTeachers = (pageNumber) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_TEACHERS });
    var { data } = await getTeachers(pageNumber, lng === "arab" ? 1 : 2);
    await dispatch({ type: FETCH_TEACHERS, data: data });
  } catch (error) {
    dispatch({ type: LOADED_TEACHERS });
    checkErr(error, false, toastAlert, false, dispatch);
  }
};

export const getTeacherApplicantions = (lang) => async (dispatch) => {
  try {
    dispatch({ type: TEACHER_APPLICANTIONS_LOAD });
    var { data } = await getTeacherApplicantion(lang);
    await dispatch({ type: FETCH_TEACHER_APPLICANTIONS, data: data.data });
  } catch (error) {
    dispatch({ type: TEACHER_APPLICANTIONS_LOADED });
    checkErr(error, false, toastAlert, false, dispatch);
  }
};

export const getTeacherInfo = (lang) => async (dispatch) => {
  await dispatch({ type: TEACHER_INFO_LOAD });
  try {
    const { data } = await teacherInfo(lang);
    await dispatch({ type: FETCH_TEACHER_INFO, data: data.data });
  } catch (error) {
    dispatch({ type: TEACHER_INFO_LOADED });
    checkErr(error, false, toastAlert, false, dispatch);
  }
};

export const editTeacherGeneralInformation =
  (formData, setLoadingModalShow, onHide, setForceLoad, forceLoad) =>
  async (dispatch) => {
    try {
      const { data } = await updateTeacherGeneralInformation(formData);
      toastAlert(
        t("Teacher Profile General Information Form Submitted Successfully"),
        undefined,
        "success"
      );
      onHide();
      setForceLoad(forceLoad + 1);
      setLoadingModalShow(false);
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  };

export const deleteTeacherResume = (setLoading, onHide, setForceLoad, forceLoad) => async (dispatch) => {
  try {
    const result = await destroyTeacherResume();
    setLoading(false);
    setForceLoad(forceLoad + 1)
    onHide()
    toastAlert(t("Resume Removed Successfully"), undefined, "success");
  } catch (error) {
    checkErr(error, false, toastAlert, false, dispatch);
  }
};

export const editTeacherEducation =
  (userData, setLoadingModalShow, onHide, forceLoad, setForceLoad) =>
  async (dispatch) => {
    try {
      const { data } = await updateTeacherEducation(userData);
      toastAlert(t("Education Updated Successfully"), undefined, "success");
      setLoadingModalShow(false);
      onHide();
      setForceLoad(forceLoad + 1);
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  };

export const addTeacherEducation =
  (userData, setLoadingModalShow, onHide, forceLoad, setForceLoad) =>
  async (dispatch) => {
    try {
      const { data } = await teacherEducation(userData);
      toastAlert(t("Education Added Successfully"), undefined, "success");
      setLoadingModalShow(false);
      onHide();
      setForceLoad(forceLoad + 1);
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  };

export const deleteTeacherEducation =
  (id, setLoadingModalShow, onHide, forceLoad, setForceLoad) =>
  async (dispatch) => {
    try {
      const result = await destroyTeacherEducation(id);
      setLoadingModalShow(false);
      toastAlert(t("Education Removed Successfully"), undefined, "success");
      onHide();
      setForceLoad(forceLoad + 1);
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  };

export const deleteTeacherExperience =
  (id, setLoadingModalShow, onHide, forceLoad, setForceLoad) =>
  async (dispatch) => {
    try {
      const result = await destroyTeacherExperience(id);
      setLoadingModalShow(false);
      toastAlert(t("Experience Removed Successfully"), undefined, "success");
      onHide();
      setForceLoad(forceLoad + 1);
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  };

export const editTeacherExperience =
  (userData, setLoadingModalShow, onHide, forceLoad, setForceLoad) =>
  async (dispatch) => {
    try {
      const { data } = await updateTeacherExperience(userData);
      toastAlert(t("Done Successfully"), undefined, "success");
      setLoadingModalShow(false);
      onHide();
      setForceLoad(forceLoad + 1);
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  };

export const addTeacherExperience =
  (userData, setLoadingModalShow, onHide, setView, forceLoad, setForceLoad) =>
  async (dispatch) => {
    try {
      const { data } = await teacherExperience(userData);
      toastAlert(t("Experience Added Successfully"), undefined, "success");
      setLoadingModalShow(false);
      setView(0);
      onHide();
      setForceLoad(forceLoad + 1);
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  };

export const addTeacherLicense =
  (userData, setLoadingModalShow, onHide, forceLoad, setForceLoad) =>
  async (dispatch) => {
    try {
      const { data } = await teacherLicense(userData);
      toastAlert(t("License Added Successfully"), undefined, "success");
      setLoadingModalShow(false);
      onHide();
      setForceLoad(forceLoad + 1);
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  };

export const deleteTeacherLicense =
  (id, setLoadingModalShow, onHide, forceLoad, setForceLoad) =>
  async (dispatch) => {
    try {
      const result = await destroyTeacherLicense(id);
      setLoadingModalShow(false);
      toastAlert(t("License Removed Successfully"), undefined, "success");
      onHide();
      setForceLoad(forceLoad + 1);
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  };

export const editTeacherLicense =
  (userData, setLoadingModalShow, onHide, forceLoad, setForceLoad) =>
  async (dispatch) => {
    try {
      console.log("userData", userData);
      const { data } = await updateTeacherLicense(userData);
      toastAlert(t("Done Successfully"), undefined, "success");
      setLoadingModalShow(false);
      onHide();
      setForceLoad(forceLoad + 1);
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  };

export const getLicenseTypes = (lng) => async (dispatch) => {
  dispatch({ type: LICENSE_LOAD });
  try {
    var { data } = await licenseTypes(lng);
    await dispatch({ type: FETCH_LICENSE, data: data?.data });
  } catch (error) {
    dispatch({ type: LICENSE_LOADED });
    checkErr(error, false, toastAlert, false, dispatch);
  }
};

export const getDocumentTypes = (lng) => async (dispatch) => {
  dispatch({ type: DOCUMENT_LOAD });
  try {
    var { data } = await documentTypes(lng);
    await dispatch({ type: FETCH_DOCUMENT, data: data?.data });
  } catch (error) {
    dispatch({ type: DOCUMENT_LOADED });
    checkErr(error, false, toastAlert, false, dispatch);
  }
};

export const addDocument =
  (userData, setLoadingModalShow, onHide, forceLoad, setForceLoad) =>
  async (dispatch) => {
    try {
      const { data } = await document(userData);
      toastAlert(t("Document Added Successfully"), undefined, "success");
      setLoadingModalShow(false);
      onHide();
      setForceLoad(forceLoad + 1);
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  };

export const deleteTeacherDocument =
  (id, setLoadingModalShow, forceLoad, setForceLoad) =>
  async (dispatch) => {
    try {
      const result = await destroyTeacherDocument(id);
      setLoadingModalShow(false);
      toastAlert(t("Document Removed Successfully"), undefined, "success");
      setForceLoad(forceLoad + 1);
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  };

export const getSuggested = (lng) => async (dispatch) => {
  dispatch({ type: SUGGESTED_LOAD });
  try {
    var { data } = await suggestedForYou(lng);
    await dispatch({ type: FETCH_SUGGESTED, data: data?.data });
  } catch (error) {
    dispatch({ type: SUGGESTED_LOADED });
    checkErr(error, false, toastAlert, false, dispatch);
  }
};


export const getIndustry = (lng) => async (dispatch) => {
  dispatch({ type: INDUSTRY_LOAD });
  try {
    var { data } = await industry(lng);
    await dispatch({ type: FETCH_INDUSTRY, data: data?.data });
  } catch (error) {
    dispatch({ type: INDUSTRY_LOADED });
    checkErr(error, false, toastAlert, false, dispatch);
  }
};

export const hideTeacherProfile = (setLoadingModalShow) => async (dispatch) => {
  try {
    var result = await hideProfile();
    console.log("result", result)
    setLoadingModalShow(false)
    toastAlert(t("Teacher Profile Hide Successfully"), undefined, "success");
  } catch (error) {
    checkErr(error, false, toastAlert, false, dispatch);
  }
};

export const unHideTeacherProfile = (setLoadingModalShow) => async (dispatch) => {
  try {
    var result = await unHideProfile();
    console.log("result", result)
    setLoadingModalShow(false)
    toastAlert(t("Teacher Profile unHide Successfully"), undefined, "success");
  } catch (error) {
    checkErr(error, false, toastAlert, false, dispatch);
  }
};