import {
    FETCH_TEACHER_APPLICANTIONS,
    TEACHER_APPLICANTIONS_LOAD,
    TEACHER_APPLICANTIONS_LOADED,
  } from "../actions/Constants";
  
  export default (teacherApplicantions = { teacherApplicantions: [], loading: false }, action) => {
    switch (action.type) {
      case FETCH_TEACHER_APPLICANTIONS:
        return { ...teacherApplicantions, teacherApplicantions: action.data, loading: false };
      case TEACHER_APPLICANTIONS_LOAD:
        return { ...teacherApplicantions, loading: true };
      case TEACHER_APPLICANTIONS_LOADED:
        return { ...teacherApplicantions, loading: false };
      default:
        return teacherApplicantions;
    }
  };
  