import { GRADES, LOADING_GRADES, LOADED_GRADES } from "../../actions/Constants";

export default (grades = { grades: [], innerLoading: false }, action) => {
    switch (action.type) {
        case GRADES:
            return { ...grades, grades: action.data, innerLoading: false };
        case LOADING_GRADES:
            return { ...grades, innerLoading: true };
        case LOADED_GRADES:
            return { ...grades, innerLoading: false };
        default:
            return grades;
    }
}