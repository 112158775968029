import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getGrades,
  getNewSubjects,
} from "../../../../../../store/actions/Jobs";
import { Country, State, City } from "country-state-city";
import Loading from "../../../../../layouts/loadingComponents/Loading";
import Select from "react-select";
import { t } from "i18next";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Rte from "../../../../Common/CreateArticle/Summernote/Rte";
import Steps from "./Steps";
import { getGender } from "../../../../../../store/actions/gender";
let lng = JSON.parse(localStorage.getItem("language"))?.lang;
let city;
let years = [
  { id: "0", name: "0" },
  { id: "1", name: "1" },
  { id: "2", name: "2" },
  { id: "3", name: "3" },
  { id: "4", name: "4" },
  { id: "5", name: "+5" },
];

let months = [
  { id: "0", name: "0" },
  { id: "1", name: "1" },
  { id: "2", name: "2" },
  { id: "3", name: "3" },
  { id: "4", name: "4" },
  { id: "5", name: "5" },
  { id: "6", name: "6" },
  { id: "7", name: "7" },
  { id: "8", name: "8" },
  { id: "9", name: "9" },
  { id: "10", name: "10" },
  { id: "11", name: "11" },
];
const Details = ({
  submitNewJobStep1,
  handleGeneralInfoChange,
  generalInfo,
  setGeneralInfo,
  editPost,
  editPostHandler,
  draft
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadingModalShow, setLoadingModalShow] = useState(true);

  useEffect(() => {
    dispatch(getNewSubjects(lng === "arab" ? 1 : 2));
    dispatch(getGrades(lng === "arab" ? 1 : 2));
    dispatch(getGender(lng === "arab" ? 1 : 2));
  }, []);
  useEffect(() => {
    if (generalInfo?.state) {
      const code = State.getStatesOfCountry("SA").find(
        (x) => x.name === generalInfo?.state
      ).isoCode;
      city = City.getCitiesOfState("SA", code);
    }
  }, [generalInfo?.state]);

  const subjects = useSelector((state) => state?.newSubjects?.subjects);
  const grades = useSelector((state) => state?.grades?.grades);
  const subjectsLoading = useSelector(
    (state) => state?.newSubjects?.newLoading
  );
  const gradesLoading = useSelector((state) => state?.grades?.innerLoading);
  const gender = useSelector((state) => state?.gender?.gender);
  const genderLoading = useSelector((state) => state?.gender?.loading);

  useEffect(() => {
    setLoadingModalShow(subjectsLoading || gradesLoading || genderLoading);
  }, [subjectsLoading, gradesLoading, genderLoading]);

  const handleState = (e) => {
    handleGeneralInfoChange(e);
    const code = State.getStatesOfCountry("SA").find(
      (x) => x.name === e.target.value
    ).isoCode;
    city = City.getCitiesOfState("SA", code);
  };
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  const customStyles = {
    control: () => ({
      display: "flex",
      alignItems: "center",
      width: width > 669 ? "100%" : "100%",
      minHeight: "50px",
      backgroundColor: "white",
      border: "1px solid",
      borderRadius: "6px",
      borderColor: "rgba(112, 112, 112, 1)",
    }),
    container: (provided) => ({
      ...provided,
      width: width > 1100 ? "100%" : "95%",
    }),
  };

  return (
    <Fragment>
      {loadingModalShow ? (
        <div
          style={{
            minHeight: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <div className={editPost? "add-job-step-1 mt-5" : "add-job-step-1"} >
          {/* <div className={!editPost && "add-job-step-1-steps"}> */}
          {/* <div className={!editPost && "container"}> */}
          <div className={!editPost && "container"}>
            <Steps currentStep={1} />
            <div style={editPost? {boxShadow: 'unset', marginTop: '0px', marginBottom: '0px', paddingTop:'0px'}:null} className="add-job-step-1-main">
              <div className="add-job-step-1-container">
                {/* General Info Section 1 */}
                <div className="add-job-step-1-title">General</div>
                <div className="add-job-step-1-general-main">
                  <div className="add-job-step-1-general-item">
                    <div className="add-job-step-1-label">Job Title</div>
                    <input
                      className="add-job-step-1-input-field"
                      type="text"
                      name="title"
                      value={generalInfo.title}
                      onChange={handleGeneralInfoChange}
                    />
                  </div>
                  <div className="add-job-step-1-general-item">
                    <div className="add-job-step-1-label">Subject</div>
                    <Select
                      styles={customStyles}
                      value={generalInfo.subjects}
                      name="subjects"
                      onChange={(selectedOption) =>
                        handleGeneralInfoChange({
                          target: { value: selectedOption, name: "subjects" },
                        })
                      }
                      options={subjects?.map((item) => ({
                        label: item?.name,
                        value: item?.id,
                      }))}
                      isMulti
                    />
                  </div>
                </div>

                <div className="add-job-step-1-general-item">
                  <div className="add-job-step-1-label">Teaching Level</div>
                  <Select
                    styles={customStyles}
                    value={generalInfo.grades}
                    name="grades"
                    onChange={(selectedOption) =>
                      handleGeneralInfoChange({
                        target: { value: selectedOption, name: "grades" },
                      })
                    }
                    options={grades?.map((item) => ({
                      label: item?.name,
                      value: item?.id,
                    }))}
                    isMulti
                  />
                </div>

                {/* Location Section 2 */}
                <div className="add-job-step-1-location">
                  <div className="add-job-step-1-title">Location</div>
                  <div className="add-job-step-1-general-main">
                    <div className="add-job-step-1-general-item">
                      <div className="add-job-step-1-label">State</div>
                      <select
                        value={generalInfo.state}
                        onChange={(e) => handleState(e)}
                        name="state"
                        className="add-job-step-1-input-field"
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {State.getStatesOfCountry("SA")?.map((item, i) => (
                          <option key={i} value={item?.name}>
                            {item?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {generalInfo?.state ? (
                      <div className="add-job-step-1-general-item">
                        <div className="add-job-step-1-label">City</div>
                        <select
                          className="add-job-step-1-input-field"
                          value={generalInfo.city}
                          onChange={(e) => handleGeneralInfoChange(e)}
                          name="city"
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          {city?.map((item, i) => (
                            <option key={i} value={item?.name}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* Gender Section */}
                <div className="add-job-step-1-general-item">
                  <div className="add-job-step-1-label">Gender</div>
                  <Select
                    styles={customStyles}
                    value={generalInfo.genders}
                    name="gender"
                    onChange={(selectedOption) =>
                      handleGeneralInfoChange({
                        target: { value: selectedOption, name: "genders" },
                      })
                    }
                    options={gender?.map((item) => ({
                      label: item?.name,
                      value: item?.id,
                    }))}
                    isMulti
                  />
                </div>

                {/* Salary Section 3 */}
                <div
                  className={
                    editPost
                      ? "add-job-step-1-monthly-salary-main-edit"
                      : "add-job-step-1-monthly-salary-main"
                  }
                >
                  {/* Monthly Salary */}
                  <div
                    className={
                      editPost
                        ? "add-job-step-1-general-item-edit"
                        : "add-job-step-1-general-item"
                    }
                  >
                    <div className="add-job-step-1-title">Monthly Salary</div>

                    <div className="add-job-step-1-monthly-salary-min-max">
                      <div>
                        <div className="add-job-step-1-label">Minimum</div>
                        <input
                          className="add-job-step-1-monthly-salary-input-field"
                          name="minimum_salary"
                          value={generalInfo.minimum_salary}
                          onChange={(e) =>
                            handleGeneralInfoChange({
                              target: {
                                value: e.target.value.replace(/\D/, ""),
                                name: "minimum_salary",
                              },
                            })
                          }
                          placeholder={t("SAR")}
                          type="text"
                        />
                      </div>

                      <div>
                        <div className="add-job-step-1-label">Maximum</div>
                        <div className="add-job-step-1-salary-info">
                          <input
                            className="add-job-step-1-monthly-salary-input-field"
                            name="maximum_salary"
                            value={generalInfo.maximum_salary}
                            onChange={(e) =>
                              handleGeneralInfoChange({
                                target: {
                                  value: e.target.value.replace(/\D/, ""),
                                  name: "maximum_salary",
                                },
                              })
                            }
                            type="text"
                            placeholder={t("SAR")}
                          />
                          <input
                            className="add-job-step-1-monthly-salary-check-box-field"
                            type="checkbox"
                            checked={
                              generalInfo.publish_salary_information == "0"
                            }
                            value={generalInfo.publish_salary_information}
                            onChange={() =>
                              handleGeneralInfoChange({
                                target: {
                                  value:
                                    generalInfo.publish_salary_information ==
                                    "0"
                                      ? "1"
                                      : "0",
                                  name: "publish_salary_information",
                                },
                              })
                            }
                          />
                          <div className="add-job-step-1-monthly-salary-label">
                            Don’t publish salary information
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Contract Length */}
                  <div
                    className={
                      editPost
                        ? "add-job-step-1-general-item-edit"
                        : "add-job-step-1-general-item"
                    }
                  >
                    <div
                      className={
                        editPost
                          ? "add-job-step-1-title add-job-step-1-contract-length-title-edit"
                          : "add-job-step-1-title"
                      }
                    >
                      Contract Length
                    </div>
                    <div
                      className={
                        editPost
                          ? "add-job-step-1-contract-length-years-month-edit"
                          : "add-job-step-1-contract-length-years-month"
                      }
                    >
                      <div>
                        <div className="add-job-step-1-label">Years</div>
                        <select
                          className="add-job-step-1-contract-length-input-field"
                          name="contract_length_years"
                          value={generalInfo.contract_length_years}
                          onChange={handleGeneralInfoChange}
                        >
                          <option value="">{t("None")}</option>
                          {years?.map((item, i) => (
                            <option key={i} value={item.id}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div>
                        <div className="add-job-step-1-label">Month</div>
                        <select
                          name="contract_length_months"
                          value={generalInfo.contract_length_months}
                          onChange={handleGeneralInfoChange}
                          className="add-job-step-1-contract-length-input-field"
                        >
                          <option value="">{t("None")}</option>
                          {months?.map((item, i) => (
                            <option key={i} value={item.id}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Start and Close Date */}
                <div className="add-job-step-1-monthly-salary-main">
                  <div className="add-job-step-1-general-item">
                    <div className="add-job-step-1-title">Start Date</div>
                    <div>
                      <div className="add-job-step-1-label">Post Date</div>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk
                          label=""
                          clearable
                          className="add-job-step-1-date-input-field"
                          name="start_date"
                          format="dd/MM/yyyy"
                          minDate={new Date()}
                          value={generalInfo.start_date}
                          onChange={(date) =>
                            handleGeneralInfoChange({
                              target: { value: date, name: "start_date" },
                            })
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>

                  <div className="add-job-step-1-general-item">
                    <div className="add-job-step-1-title">Close Date</div>
                    <div>
                      <div className="add-job-step-1-label">End Date</div>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk
                          label=""
                          clearable
                          className="add-job-step-1-date-input-field"
                          name="close_date"
                          format="dd/MM/yyyy"
                          minDate={new Date()}
                          value={generalInfo.close_date}
                          onChange={(date) =>
                            handleGeneralInfoChange({
                              target: { value: date, name: "close_date" },
                            })
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </div>

                {/* Description Section 4 */}
                <div className="add-job-step-1-description">
                  <div className="add-job-step-1-title">Description</div>
                  <div className="add-job-step-1-label">
                    Include a brief job description should outline the role and
                    school environment{" "}
                  </div>
                  <Rte changeFunc={setGeneralInfo} obj={generalInfo} />
                  {/* <textarea
                    className="add-job-step-1-description-text-area"
                    rows="16"
                    value={generalInfo.description}
                    onChange={(e) =>
                      setGeneralInfo({
                        ...generalInfo,
                        description: e.target.value,
                      })
                    }
                  /> */}
                </div>

                {/* Next And Back Button Section 5 */}
                <div className="add-job-step-1-next-back-btn">
                  <button
                    className="add-job-step-1-next-btn"
                    onClick={
                      draft? submitNewJobStep1
                      :
                      editPost? editPostHandler 
                      :
                      submitNewJobStep1
                    }
                  >
                    Continue To Requirements
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        id="Icon_material-arrow_back"
                        data-name="Icon material-arrow_back"
                        d="M6,16.5H24.255L15.87,8.115,18,6,30,18,18,30l-2.115-2.115,8.37-8.385H6Z"
                        transform="translate(-6 -6)"
                        fill="#fff"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Details;
