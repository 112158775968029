import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { State, City } from "country-state-city";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";
import "react-phone-number-input/style.css";
import placeHolderImage from "../../../../assets/images/user.png";
import upload from "../../../../assets/images/upload.png";
import { getGender } from "../../../../store/actions/gender";
import {
  getCitizenships,
  getJobTitle,
  getLanguages,
} from "../../../../store/actions/Jobs";
import { editTeacherGeneralInformation } from "../../../../store/actions/teachers";
import { toastAlert } from "../../../layouts/toastAlert/toastAlert";
import Loading from "../../../layouts/loadingComponents/Loading";
import SignleFilePicker from "../../Common/SignleFilePicker";

let lng = JSON.parse(localStorage.getItem("language"))?.lang;
let city;
function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

const GeneralInformation = ({ data, setView, forceLoad, setForceLoad }) => {
  const dispatch = useDispatch();

  // UseState
  const [teacherData, setTeacherData] = useState({
    first_name: data?.first_name || "",
    last_name: data?.last_name || "",
    gender_id: data?.gender_id || "1",
    date_of_birth:
      data?.dob ||
      `${formatDate(new Date().setFullYear(new Date().getFullYear() - 18))}`,
    country: data?.location?.country || "Saudi Arabia",
    state: data?.location?.state || "",
    city: data?.location?.city || "",
    languages:
      data?.languages?.map((item) => {
        return { value: item?.id, label: item?.name };
      }) || [],
    citizenships:
      data?.citizenships?.map((item) => {
        return { value: item?.id, label: item?.name };
      }) || [],
    avatar: data?.avatar || "",
    banner: data?.banner || "",
    resume: data?.resume || "",
    job_title_id: data?.job_title_id || "1",
    allow_share_docs: data?.allow_share_docs || "0",
    contact_number: data?.contact_number || "",
    allow_contact_directly: data?.allow_contact_directly || "0",
  });
  const [profileFile, setProfileFile] = useState("");
  const [profileState, setProfileState] = useState("");
  const [logo, setLogo] = useState("");
  const [banner, setBanner] = useState("");
  const [bannerState, setBannerState] = useState("");
  const [bannerFile, setBannerFile] = useState("");
  const [resumeState, setResumeState] = useState("");
  const [resumeFile, setResumeFile] = useState("");
  const [resume, setResume] = useState("");
  const [loading, setLoading] = useState(false);

  // UseEffects
  useEffect(() => {
    dispatch(getGender(lng === "arab" ? 1 : 2));
    dispatch(getJobTitle(lng === "arab" ? 1 : 2));
    dispatch(getCitizenships(lng === "arab" ? 1 : 2));
    dispatch(getLanguages(lng === "arab" ? 1 : 2));
  }, []);

  useEffect(() => {
    setTeacherData({
      first_name: data?.first_name || "",
      last_name: data?.last_name || "",
      gender_id: data?.gender_id || "1",
      date_of_birth:
        data?.dob ||
        `${formatDate(new Date().setFullYear(new Date().getFullYear() - 18))}`,
      country: data?.location?.country || "Saudi Arabia",
      state: data?.location?.state || "",
      city: data?.location?.city || "",
      languages:
        data?.languages?.map((item) => {
          return { value: item?.id, label: item?.name };
        }) || [],
      citizenships:
        data?.citizenships?.map((item) => {
          return { value: item?.id, label: item?.name };
        }) || [],
      avatar: data?.avatar || "",
      banner: data?.banner || "",
      resume: data?.resume || "",
      job_title_id: data?.job_title_id || "1",
      contact_number: data?.contact_number || "",
      allow_share_docs: data?.allow_share_docs || "0",
      allow_contact_directly: data?.allow_contact_directly || "0",
    });
    setLogo(data?.avatar);
    setBanner(data?.banner);
    setResume(data?.resume);
  }, [data?.first_name]);

  useEffect(() => {
    if (data?.location?.state) {
      handleState(data?.location?.state);
    }
  }, [data?.location?.state]);

  // UseSelector
  const { gender } = useSelector((state) => state?.gender);
  const { jobTitles } = useSelector((state) => state?.jobTitles);
  const { citizenships } = useSelector((state) => state?.citizenships);
  const { languages } = useSelector((state) => state?.languages);

  // General Function
  const handleState = (state) => {
    setTeacherData((prev) => ({ ...prev, state }));
    const code = State.getStatesOfCountry("SA").find(
      (x) => x.name === state
    ).isoCode;
    city = City.getCitiesOfState("SA", code);
  };

  const submitGeneralForm = () => {
    setLoading(true);
    let errorArr = [];
    let userData = {
      ...teacherData,
      avatar: profileState,
      banner: bannerState,
      resume: resumeState,
      date_of_birth: formatDate(teacherData.date_of_birth),
    };
    if (!userData.first_name) {
      errorArr.push(t("First Name is required"));
    }
    if (!userData.country) {
      errorArr.push(t("Country is required"));
    }
    if (!userData.state) {
      errorArr.push(t("State is required"));
    }
    if (!userData.city) {
      errorArr.push(t("City is required"));
    }
    if (!userData.contact_number) {
      errorArr.push(t("Contact Number is required"));
    }
    if (!userData.languages?.length) {
      errorArr.push(t("Language is required"));
    }
    if (!userData.citizenships?.length) {
      errorArr.push(t("Citizenships is required"));
    }
    if (errorArr.length > 0) {
      toastAlert(errorArr, undefined, "warning");
      setLoading(false);
      return false;
    }
    console.log("userData", userData);
    dispatch(editTeacherGeneralInformation(userData, setLoading, setView, setForceLoad, forceLoad ));
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            minHeight: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-xl-12  col-md-12 mb-4">
              <label className="form-label font-w600">
                {t("Profile Picture")}
              </label>
              <ul style={{ listStyle: "none" }}>
                <li>{t('Maximum Size 10 MB')}</li>
                <li>{t('Recommended Files:- jpg, png, jpeg')}</li>
              </ul>
              <SignleFilePicker
                setFile={setLogo}
                file={logo}
                FileState={profileState}
                setFileState={setProfileState}
                setNewFile={setProfileFile}
                type="Logo"
              >
                <div style={{ cursor: "pointer" }} className="userProfile">
                  <img
                    src={logo || profileFile || placeHolderImage}
                    alt="upload_image"
                  />
                </div>
              </SignleFilePicker>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12  col-md-12 mb-4">
              <label className="form-label font-w600">
                {t("Banner Picture")}
              </label>
              <ul style={{ listStyle: "none" }}>
                <li>{t("Maximum Size 10 MB")}</li>
                <li>{t("Recommended Files:- jpg, png, jpeg")}</li>
              </ul>
              <SignleFilePicker
                setFile={setBanner}
                file={banner}
                FileState={bannerState}
                setFileState={setBannerState}
                setNewFile={setBannerFile}
                type="Banner"
              >
                <div style={{ cursor: "pointer" }} className="userProfile">
                  <img
                    src={banner || bannerFile || upload}
                    alt="upload_image"
                  />
                </div>
              </SignleFilePicker>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6  col-md-6 mb-4">
              <label className="form-label font-w600">{t("First Name")}</label>
              <input
                type="text"
                className="form-control solid"
                placeholder={t("First Name")}
                value={teacherData.first_name}
                name="first_name"
                onChange={(e) =>
                  setTeacherData({
                    ...teacherData,
                    first_name: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-xl-6  col-md-6 mb-4">
              <label className="form-label font-w600">{t("Last Name")}</label>
              <input
                type="text"
                className="form-control solid"
                placeholder={t("Last Name")}
                value={teacherData.last_name}
                name="last_name"
                onChange={(e) =>
                  setTeacherData({
                    ...teacherData,
                    last_name: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6  col-md-6 mb-4">
              <label className="form-label font-w600">{t("Gender")}</label>
              <select
                value={teacherData.gender_id}
                onChange={(e) =>
                  setTeacherData({
                    ...teacherData,
                    gender_id: e.target.value,
                  })
                }
                name="gender_id"
                className="form-control solid"
              >
                {gender.map((item, i) => (
                  <option key={i} value={item?.id}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-xl-6  col-md-6 mb-4">
              <label className="form-label font-w600">
                {t("Date of Birth")}
              </label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  autoOk
                  label=""
                  clearable
                  className="form-control solid"
                  name="date_of_birth"
                  format="dd/MM/yyyy"
                  value={teacherData.date_of_birth}
                  maxDate={`${formatDate(
                    new Date().setFullYear(new Date().getFullYear() - 18)
                  )}`}
                  disableFuture
                  onChange={(date) =>
                    setTeacherData({
                      ...teacherData,
                      date_of_birth: date,
                    })
                  }
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6  col-md-6 mb-4">
              <label className="form-label font-w600">{t("State")}</label>
              <select
                value={teacherData.state}
                onChange={(e) => handleState(e.target.value)}
                name="state"
                className="form-control solid"
              >
                {State.getStatesOfCountry("SA")?.map((item, i) => (
                  <option key={i} value={item?.name}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-xl-6  col-md-6 mb-4">
              <label className="form-label font-w600">{t("City")}</label>
              <select
                value={teacherData.city}
                onChange={(e) =>
                  setTeacherData({
                    ...teacherData,
                    city: e.target.value,
                  })
                }
                name="city"
                className="form-control solid"
              >
                {city?.map((item, i) => (
                  <option key={i} value={item?.name}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6  col-md-6 mb-4">
              <label className="form-label font-w600">
                {t("Contact Number")}
              </label>
              <PhoneInput
                id="phone"
                className="form-control solid"
                style={{ display: "flex" }}
                placeholder={t("Enter phone number")}
                value={teacherData.contact_number}
                onChange={(phone) =>
                  setTeacherData({
                    ...teacherData,
                    contact_number: phone,
                  })
                }
              />
            </div>
            <div className="col-xl-6  col-md-6 mb-4">
              <label className="form-label font-w600">
                {t("Which job title fits you best?")}
              </label>
              <select
                value={teacherData.job_title_id}
                onChange={(e) =>
                  setTeacherData({
                    ...teacherData,
                    job_title_id: e.target.value,
                  })
                }
                className="form-control solid"
              >
                {jobTitles?.map((item, i) => (
                  <option key={i} value={item?.id}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12  col-md-12 mb-4">
              <label className="form-label font-w600">
                {t("Citizenship(s)")}
              </label>
              <Select
                onChange={(selectedOption) =>
                  setTeacherData({
                    ...teacherData,
                    citizenships: selectedOption,
                  })
                }
                value={teacherData.citizenships}
                placeholder={t("Select citizenship(s)")}
                options={citizenships?.map((item) => ({
                  label: item?.name,
                  value: item?.id,
                }))}
                isMulti
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12  col-md-12 mb-4">
              <label className="form-label font-w600">
                {t("Fluent Languages")}
              </label>
              <Select
                onChange={(selectedOption) =>
                  setTeacherData({
                    ...teacherData,
                    languages: selectedOption,
                  })
                }
                value={teacherData.languages}
                placeholder={t("Select Language(s)")}
                options={languages?.map((item) => ({
                  label: item?.name,
                  value: item?.id,
                }))}
                isMulti
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12  col-md-12 mb-4">
              <label className="form-label font-w600">{t("Resume")}</label>
              <ul style={{ listStyle: "none" }}>
                <li>{t('Maximum Size 10 MB')}</li>
                <li>{t('Recommended Files:- doc, docx, pdf')}</li>
              </ul>
              <SignleFilePicker
                setFile={setResume}
                file={resume}
                FileState={resumeState}
                setFileState={setResumeState}
                setNewFile={setResumeFile}
                type="Resume"
              >
                <div style={{ cursor: "pointer" }} className="userProfile">
                  <img src={upload} alt="upload_image" />
                </div>
              </SignleFilePicker>
            </div>
          </div>

          <div style={{ display: "flex", gap: "7px" }} className="form-check">
            <input
              style={{
                float: "unset",
                marginRight: "unset",
                marginLeft: "unset",
              }}
              checked={teacherData.allow_share_docs == 1}
              value={teacherData.allow_share_docs}
              onChange={(e) =>
                setTeacherData({
                  ...teacherData,
                  allow_share_docs: teacherData.allow_share_docs === 1 ? 0 : 1,
                })
              }
              className="form-check-input"
              type="checkbox"
            />
            <label className="form-check-label">
              {t(
                "I consent to Madares receiving this document and sharing it with employers"
              )}
            </label>
          </div>
          <div className="row">
            <div
              style={{ display: "flex", gap: "7px" }}
              className="col-xl-6  col-md-6 mb-4"
            ></div>
            <div className="card-footer text-end">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Link
                  to={"#"}
                  onClick={() => setView(0)}
                  className="btn btn-secondry"
                >
                  {t("Back")}
                </Link>
                <Link
                  to={"#"}
                  onClick={submitGeneralForm}
                  className="btn btn-primary"
                >
                  {t("Save")}
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default GeneralInformation;
