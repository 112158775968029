import { t } from "i18next";
import React, { Fragment } from "react";
import Rte from "../../../../Common/CreateArticle/Summernote/Rte";
import Steps from "./Steps";

const Benefits = ({
  editPostHandler,
  back,
  setBenefitsInfo,
  handleBenefitsInfoChange,
  benefitsInfo,
  submitNewJobStep3,
  setLoadingModalShow,
  editPost,
  draft
}) => {
  return (
    <Fragment>
      <div className= {editPost? "add-job-step-3 mt-5" : "add-job-step-3"}>
        {/* <div className={!editPost ? "add-job-step-3-steps" : null}> */}
        <div className={!editPost ? "container" : null}>
          <Steps currentStep={3} />
          <div style={editPost? {boxShadow: 'unset', marginTop: '0px', marginBottom: '0px', paddingTop:'0px'}:null} className="add-job-step-3-main">
            <div>
              <div className="add-job-step-3-title">Job Benefits</div>
              <div className="add-job-step-3-label">Airfare</div>
              <input
                className="add-job-step-3-input-field"
                type="text"
                name="airfare"
                value={benefitsInfo.airfare}
                onChange={handleBenefitsInfoChange}
                placeholder={t(
                  "E.g., Full airfare reimbursement for an economy ticket and airport pickup"
                )}
              />
              <div className="add-job-step-3-label">Accommodation</div>
              <input
                className="add-job-step-3-input-field"
                name="accommodation"
                value={benefitsInfo.accommodation}
                onChange={handleBenefitsInfoChange}
                type="text"
                placeholder={t(
                  "E.g., Housing allowance provided on a monthly basis"
                )}
              />
            </div>
            <div>
              <div className="add-job-step-3-title add-job-step-3-title-job-benefits">
                All Other Benefit
              </div>
              <div className="add-job-step-3-label">Other Benefit</div>
              <Rte changeFunc={setBenefitsInfo} obj={benefitsInfo} benefits= 'other_benefits' />

              {/* <textarea
                className="add-job-step-3-text-area"
                rows="6"
                value={benefitsInfo.other_benefits}
                onChange={(e) =>
                  setBenefitsInfo({
                    ...benefitsInfo,
                    other_benefits: e.target.value,
                  })
                }
              /> */}
            </div>
            <div className="add-job-step-3-next-back-btn">
              <button
                className="add-job-step-3-next-btn"
                onClick={draft? submitNewJobStep3 : editPost ? editPostHandler : submitNewJobStep3}
              >
                Continue To Other Info
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="Icon_material-arrow_back"
                    data-name="Icon material-arrow_back"
                    d="M6,16.5H24.255L15.87,8.115,18,6,30,18,18,30l-2.115-2.115,8.37-8.385H6Z"
                    transform="translate(-6 -6)"
                    fill="#fff"
                  />
                </svg>
              </button>
              <div className="add-job-step-3-back-btn" onClick={() => back(1)}>
                Back
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Benefits;
