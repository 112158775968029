import { FETCH_ALL_SUBJECTS, LOADING_SUBJECTS, LOADED_SUBJECTS } from "../../actions/Constants";

export default (newSubjects = { subjects: [], newLoading: false }, action) => {
    switch (action.type) {
        case LOADING_SUBJECTS:
            return {...newSubjects, newLoading: true };
        case FETCH_ALL_SUBJECTS:
            return {...newSubjects, subjects: action.payload, newLoading: false };
        case LOADED_SUBJECTS:
            return { ...newSubjects, newLoading: false };
        default:
            return newSubjects;
    }
}
