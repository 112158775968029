import { FETCH_LICENSE, LICENSE_LOAD, LICENSE_LOADED } from '../actions/Constants'

export default (licenseTypes = { licenseTypes: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_LICENSE:
            return { ...licenseTypes, licenseTypes: action.data, loading: false };
        case LICENSE_LOAD:
            return { ...licenseTypes, loading: true };
        case LICENSE_LOADED:
            return { ...licenseTypes, loading: false };
        default:
            return licenseTypes;
    }
}