import * as api from "../../services/JobService";
import {
  LOADING_SUBJECTS, FETCH_ALL_SUBJECTS, LOADED_SUBJECTS,
  LOADING_GRADES, GRADES, LOADED_GRADES, ADD_TEMPO_JOB_POST_DETAILS,
  LOADING_JOB_TITLES, JOB_TITLES, LOADED_JOB_TITLES,
  LOADING_DEGREES, DEGREES, LOADEN_DEGREES,
  LOADING_REQUIRED_CERT, FETCH_REQUIRED_CERT, LOADEN_REQUIRED_CERT,
  LOADING_CITIZENSHIPS, CITIZENSHIPS, LOADED_CITIZENSHIPS,
  ACADEMY_JOBS_LOAD, FETCH_ALL_ACADEMY_JOBS, ACADEMY_JOBS_LOADED,
  FETCH_ALL_ACADEMY_DRAFTS, ACADEMY_DRAFTS_LOAD, ACADEMY_DRAFTS_LOADED,
  LOADING_JOB_STATUS, JOB_STATUS, LOADED_JOB_STATUS, 
  FETCH_SAVED_JOBS, SAVED_JOBS_LOADED, SAVED_JOBS_LOAD,
  LOAD_ALL_JOB_TITLE, FETCH_ALL_JOB_TITLES, ALL_JOB_TITLES_LOADED,
  LOAD_APPLICANTS_STATUS, FETCH_APPLICANTS_STATUS, APPLICANTS_STATUS_LOADED,
  LOADING_LANGUAGES, LANGUAGES, LOADED_LANGUAGES,
  LOADING_CURRICULUMS, CURRICULUMS, LOADED_CURRICULUMS
} from "./Constants";
import checkErr from "./api_error_func";
import { toastAlert } from "../../jsx/layouts/toastAlert/toastAlert";
let lng = JSON.parse(localStorage.getItem("language"))?.lang;


export const getNewSubjects = (lng, setLoadingModalShow) => async (dispatch) => {
    try {
      dispatch({ type: LOADING_SUBJECTS });
      var {data} = await api.getSubjects(lng);
      await dispatch({ type: FETCH_ALL_SUBJECTS, payload: data.data });
    } catch (error) {
        dispatch({ type: LOADED_SUBJECTS });
    }
};

export const getJobStatus = (lng) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_JOB_STATUS });
    var {data} = await api.getJobStatus(lng);
    await dispatch({ type: JOB_STATUS, payload: data.data });
  } catch (error) {
      dispatch({ type: LOADED_JOB_STATUS });
  }
};

export const getGrades = (lng) => async (dispatch) => {
    try {
      dispatch({ type: LOADING_GRADES });
      var {data} = await api.getGrades(lng);
      await dispatch({ type: GRADES, data: data?.data });
    } catch (error) {
        dispatch({ type: LOADED_GRADES });
    }
};
export const getJobTitle = (lng) => async (dispatch) => {
    dispatch({ type: LOADING_JOB_TITLES });
    try {
      var {data} = await api.getJobTitles(lng);
      await dispatch({ type: JOB_TITLES, data: data?.data });
    } catch (error) {
        dispatch({ type: LOADED_JOB_TITLES });
    }
};

export const getLanguages = (lng) => async (dispatch) => {
  dispatch({ type: LOADING_LANGUAGES });
  try {
    var {data} = await api.getLanguages(lng);
    await dispatch({ type: LANGUAGES, data: data?.data });
  } catch (error) {
      dispatch({ type: LOADED_LANGUAGES });
  }
};

export const getCurriculums = (lng) => async (dispatch) => {
  dispatch({ type: LOADING_CURRICULUMS });
  try {
    var {data} = await api.getCurriculums(lng);
    await dispatch({ type: CURRICULUMS, data: data?.data });
  } catch (error) {
      dispatch({ type: LOADED_CURRICULUMS });
  }
};

export const getDegreeLevels = (lng) => async (dispatch) => {
  dispatch({ type: LOADING_DEGREES });
  try {
    var {data} = await api.getDegreeLevels(lng);
    await dispatch({ type: DEGREES, data: data?.data });
  } catch (error) {
      dispatch({ type: LOADEN_DEGREES });
  }
};
export const getRequiredCertification = (lng) => async (dispatch) => {
  dispatch({ type: LOADING_REQUIRED_CERT });
  try {
    var {data} = await api.getRequiredCertification(lng);
    await dispatch({ type: FETCH_REQUIRED_CERT, data: data?.data });
  } catch (error) {
      dispatch({ type: LOADEN_REQUIRED_CERT });
  }
};
export const getCitizenships = (lng) => async (dispatch) => {
  dispatch({ type: LOADING_CITIZENSHIPS });
  try {
    var {data} = await api.getCitizenships(lng);
    await dispatch({ type: CITIZENSHIPS, data: data?.data });
  } catch (error) {
      dispatch({ type: LOADED_CITIZENSHIPS });
  }
};
export const createNewJobStep1 = (jobData, setLoadingModalShow, history, setView) => async (dispatch) => {
    try {
        var {data} = await api.create_new_job_step1(jobData);
        toastAlert(data.message, undefined, "success")
        await dispatch({ type: ADD_TEMPO_JOB_POST_DETAILS, data: data?.data });
        setView(1)
        setLoadingModalShow(false)  
    } catch (error) {
        checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
}
export const createNewJobStep2 = (jobData, setLoadingModalShow, history, setView) => async (dispatch) => {
  try {
      var {data} = await api.create_new_job_step2(jobData);
      toastAlert(data.message, undefined, "success")
      await dispatch({ type: ADD_TEMPO_JOB_POST_DETAILS, data: data?.data });
      setView(2)
      setLoadingModalShow(false)  
  } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
  }
}
export const createNewJobStep3 = (jobData, setLoadingModalShow, history, setView) => async (dispatch) => {
  try {
      var {data} = await api.create_new_job_step3(jobData);
      toastAlert(data.message, undefined, "success")
      await dispatch({ type: ADD_TEMPO_JOB_POST_DETAILS, data: data?.data });
      setView(3)
      setLoadingModalShow(false)  
  } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
  }
}
export const createNewJobStep4 = (jobData, setLoadingModalShow, history, setView) => async (dispatch) => {
  try {
      var {data} = await api.create_new_job_step4(jobData);
      toastAlert(data.message, undefined, "success")
      await dispatch({ type: ADD_TEMPO_JOB_POST_DETAILS, data: data?.data });
      history.push('/manage-jobs')
      setLoadingModalShow(false)  
  } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
  }
}
export const getDraftsJobs = (lang) => async (dispatch) => {
    try {
        dispatch({ type: ACADEMY_DRAFTS_LOAD });
        var {data} = await api.get_drafts(lang);
        await dispatch({ type: FETCH_ALL_ACADEMY_DRAFTS, data: data.data });
    } catch (error) {
        dispatch({ type: ACADEMY_DRAFTS_LOADED });
        checkErr(error, false, toastAlert, false, dispatch);
    }
}
export const getAcademyJobs = (pageNumber, lang, jobStatus) => async (dispatch) => {
  try {
    dispatch({ type: ACADEMY_JOBS_LOAD });
    var {data} = await api.get_academy_jobs(pageNumber, lang, jobStatus);
    await dispatch({ type: FETCH_ALL_ACADEMY_JOBS, data: data.data });
  } catch (error) {
    dispatch({ type: ACADEMY_JOBS_LOADED });
    checkErr(error, false, toastAlert, false, dispatch);
  }
}
export const getAllJobTitles = (lang) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_ALL_JOB_TITLE });
    var {data} = await api.getAllJobTitles(lang);
    await dispatch({ type: FETCH_ALL_JOB_TITLES, data: data.data });
  } catch (error) {
    dispatch({ type: ALL_JOB_TITLES_LOADED });
    checkErr(error, false, toastAlert, false, dispatch);
  }
}
export const getApplicationStatus = (lang) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_APPLICANTS_STATUS });
    var {data} = await api.getApplicantsStatus(lang);
    await dispatch({ type: FETCH_APPLICANTS_STATUS, data: data.data });
  } catch (error) {
    dispatch({ type: APPLICANTS_STATUS_LOADED });
    checkErr(error, false, toastAlert, false, dispatch);
  }
}
export const changeJobStatus = (jobID, status, setLoadingModalShow) => async (dispatch) => {
  try {
    var {data} = await api.changeJobStatus(jobID, status);
    toastAlert(data.message, undefined, "success")
    setLoadingModalShow(false)  
  } catch (error) {
    checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
  }
};
export const deleteJob = (jobID, setLoadingModalShow) => async (dispatch) => {
  try {
    var {data} = await api.deleteJob(jobID);
    toastAlert(data.message, undefined, "success")
    setLoadingModalShow(false)  
  } catch (error) {
    checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
  }
};

export const checkCompanyJob = (formData, setLoadingModalShow, setView, setSimilarPost) => async (dispatch) => {
    // try {
    //     var userData = await checkJob(formData);
    //     console.log(userData)
    //     setLoadingModalShow(false)
    //     if (userData.data.data.job) {
    //         setSimilarPost(userData.data.data.job)
    //         setView(2)
    //     } else {
    //         setView(1)
    //     }
    // } catch (error) {
    //     checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    // }
}
export const updateJobStatus = (id, status, setLoadingModalShow) => async (dispatch) => {
    // try {
    //     setLoadingModalShow(true)
    //     var response = await updateJobPost(id, status);
    //     await dispatch({ type: UPDATE_JOB_STATUS, data: response?.data?.data?.job });
    //     setLoadingModalShow(false)
    //     toastAlert(response.data.message, undefined, "success");
    // } catch (error) {
    //     console.log(error.message)
    //     checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    // }
}
export const getAllJobs = () => async (dispatch) => {
    // try {
    //     dispatch({ type: LOAD_JOB });
    //     var {data} = await getJobs(lng === 'arab'? 1:2);
    //       await dispatch({ type: FETCH_JOB, data: data?.data });
    // } catch (error) {
    //     dispatch({ type: JOB_LOADED });
    // }
  };
  export const getAllTeacherJobs = () => async (dispatch) => {
    // try {
    //     dispatch({ type: LOAD_TEACHER_JOBS });
    //     var {data} = await getTeacherJobs(lng === 'arab'? 1:2);
    //       await dispatch({ type: FETCH_TEACHER_JOB, data: data?.data });
    // } catch (error) {
    //     dispatch({ type: TEACHER_JOBS_LOADED });
    // }
  };
  export const applyJob = (jobId, setLoadingModalShow) => async (dispatch) => {
    // try {
    //     var response = await jobApply(jobId);
    //     console.log(response)
    //     setLoadingModalShow(false)
    //     toastAlert(response.data.message, undefined, "success");
    // } catch (error) {
    //     checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    // }
  };

  export const savedJob = (lng) => async (dispatch) => {
    try {
      dispatch({ type: SAVED_JOBS_LOAD });
      var { data } = await api.savedJobs(lng);
      await dispatch({ type: FETCH_SAVED_JOBS, data: data.data });
    } catch (error) {
      dispatch({ type: SAVED_JOBS_LOADED });
      checkErr(error, false, toastAlert, false, dispatch)
    }
  }

  export const saveJob = (job_id, setLoadingModalShow) => async(dispatch) => {
    try {
      var response = await api.saveJob(job_id);
      setLoadingModalShow(false);
      toastAlert(response?.data?.message, undefined, "success");
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  }

  export const unSaveJob = (job_id, setForceLoad, forceLoad, setLoadingModalShow) => async(dispatch) => {
    try {
      var response = await api.unSaveJob(job_id);
      setLoadingModalShow(false);
      setForceLoad(forceLoad + 1);
      toastAlert(response?.data?.message, undefined, "success");
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  }

  export const updateAcademyJob = (data, setLoadingModalShow, history, onHide) => async(dispatch) => {
    try {
      var response = await api.updateJob(data);
      toastAlert(response?.data?.message, undefined, "success");
      onHide()
      history.push('/manage-jobs')
      setLoadingModalShow(false);
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  }