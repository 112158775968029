import { toastAlert } from "../../jsx/layouts/toastAlert/toastAlert";
import checkErr from "./api_error_func";
import {
  FETCH_APPLICANTS,
  APPLICANTS_LOADED,
  LOAD_APPLICANTS,
  FETCH_ACADEMY_APPLICANTS,
  ACADEMY_APPLICANTS_LOADED,
  ACADEMY_APPLICANTS_LOAD,
  FETCH_ACADEMY_INFO,
  ACADEMY_INFO_LOAD,
  ACADEMY_INFO_LOADED,
  FETCH_ACADEMY_TYPE,
  ACADEMY_TYPE_LOAD,
  ACADEMY_TYPE_LOADED,
  DOCUMENT_LOAD,
  FETCH_DOCUMENT,
  DOCUMENT_LOADED,
  BEST_CANDIDATES_LOADED,
  FETCH_BEST_CANDIDATES,
  BEST_CANDIDATES_LOAD,
  STATISTICS_LOAD,
  FETCH_STATISTICS,
  STATISTICS_LOADED,
  STAR_LOAD,
  FETCH_STAR,
  STAR_LOADED,
  FETCH_STAR_APPLICANTS,
  STAR_APPLICANTS_LOAD,
  STAR_APPLICANTS_LOADED,
  FETCH_TEACHER_INFO_BY_ACADEMY,
  TEACHER_INFO_BY_ACADEMY_LOAD,
  TEACHER_INFO_BY_ACADEMY_LOADED,
} from "./Constants";
import * as api from "../../services/API";
import {
  destoryAcademyFiles,
  AcademyApplicants,
  changeApplicationStatus,
  academyInfo,
  academyType,
  removeBanner,
  removeAvatar,
  updateAcademyGeneralProfile,
  academyFile,
  academyPassword,
  academyEmail,
  bestCandidates,
  getStatistics,
  databaseAccess,
  starApplicants,
  teacherInfoByAcademy,
} from "../../services/AcademyService";
import { t } from "i18next";

export const getApplicants = (status, page, searchData) => async (dispatch) => {
  await dispatch({ type: LOAD_APPLICANTS });
  try {
    const result = await api.getApplicants(status, page, searchData);
    await dispatch({ type: FETCH_APPLICANTS, data: result.data });
  } catch (error) {
    dispatch({ type: APPLICANTS_LOADED });
    checkErr(error, false, toastAlert, false, dispatch);
  }
};

export const getAcademyApplicants =
  (formData, lang, pageNumber) => async (dispatch) => {
    try {
      dispatch({ type: ACADEMY_APPLICANTS_LOAD });
      var { data } = await AcademyApplicants(formData, lang, pageNumber);
      console.log("data", data)
      await dispatch({ type: FETCH_ACADEMY_APPLICANTS, data: data.data });
    } catch (error) {
      dispatch({ type: ACADEMY_APPLICANTS_LOADED });
      checkErr(error, false, toastAlert, false, dispatch);
    }
  };

export const updateApplicationStatus =
  (userData, lng, setLoadingModalShow, setForceLoad, forceLoad) =>
  async (dispatch) => {
    try {
      console.log("userData", userData)
      var response = await changeApplicationStatus(userData, lng);
      setForceLoad(forceLoad + 1);
      setLoadingModalShow(false);
      toastAlert(response?.data?.message, undefined, "success");
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  };

export const getAcademyInfo = (lang) => async (dispatch) => {
  await dispatch({ type: ACADEMY_INFO_LOAD });
  try {
    const { data } = await academyInfo(lang);
    await dispatch({ type: FETCH_ACADEMY_INFO, data: data.data });
  } catch (error) {
    dispatch({ type: ACADEMY_INFO_LOADED });
    checkErr(error, false, toastAlert, false, dispatch);
  }
};

export const deleteAcademyAvatar =
  (setLoading, onHide, setForceLoad, forceLoad) => async (dispatch) => {
    try {
      const { data } = await removeAvatar();
      setLoading(false);
      setForceLoad(forceLoad + 1)
      onHide()
      toastAlert(t("Avatar Removed Successfully"), undefined, "success");
    } catch (error) {
      checkErr(error, setLoading, toastAlert, false, dispatch);
    }
  };

export const deleteAcademyBanner =
  (setLoading, onHide, setForceLoad, forceLoad) => async (dispatch) => {
    try {
      const result = await removeBanner();
      setForceLoad(forceLoad + 1);
      setLoading(false);
      onHide()
      toastAlert(t("Banner Removed Successfully"), undefined, "success");
    } catch (error) {
      checkErr(error, setLoading, toastAlert, false, dispatch);
    }
  };

export const editAcademyGeneralProfile =
  (formData, setLoadingModalShow, setForceLoad, forceLoad) =>
  async (dispatch) => {
    try {
      const { data } = await updateAcademyGeneralProfile(formData);
      toastAlert(
        t("Academy Profile Form Submitted Successfully"),
        undefined,
        "success"
      );
      setForceLoad(forceLoad + 1);
      // setView(0);
      setLoadingModalShow(false);
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  };

export const getAcademyType = (lang) => async (dispatch) => {
  await dispatch({ type: ACADEMY_TYPE_LOAD });
  try {
    const { data } = await academyType(lang);
    await dispatch({ type: FETCH_ACADEMY_TYPE, data: data.data });
  } catch (error) {
    dispatch({ type: ACADEMY_TYPE_LOADED });
    checkErr(error, false, toastAlert, false, dispatch);
  }
};

export const addAcademyFile =
  (formData, setLoadingModalShow, setForceLoad, forceLoad) =>
  async (dispatch) => {
    try {
      const result = await academyFile(formData);
      toastAlert(t("File Added Successfully"), undefined, "success");
      setForceLoad(forceLoad + 1)
      setLoadingModalShow(false);
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  };

export const deleteAcademyFile =
  (file_id, setLoadingModalShow, forceLoad, setForceLoad) =>
  async (dispatch) => {
    try {
      const { data } = await destoryAcademyFiles(file_id);
      toastAlert(t("File Removed Successfully"), undefined, "success");
      setForceLoad(forceLoad + 1);
      setLoadingModalShow(false);
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  };

export const changeAcademyPassword =
  (data, setLoadingModalShow, cb) => async (dispatch) => {
    try {
      const result = await academyPassword(data);
      setLoadingModalShow(false);
      toastAlert(t("Password Changed Successfully"), undefined, "success");
      cb(result);
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
      cb({ success: false });
    }
  };

export const getBestCandidates =
  (job_id, setLoadingModalShow, lng) => async (dispatch) => {
    dispatch({ type: BEST_CANDIDATES_LOAD });
    try {
      const { data } = await bestCandidates(job_id, lng);
      await dispatch({ type: FETCH_BEST_CANDIDATES, data: data?.data });
    } catch (error) {
      dispatch({ type: BEST_CANDIDATES_LOADED });
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  };

export const getAnalyticsStatistics =
  (start_date, end_date, setLoadingModalShow, lng) => async (dispatch) => {
    dispatch({ type: STATISTICS_LOAD });
    try {
      const { data } = await getStatistics(start_date, end_date, lng);
      await dispatch({ type: FETCH_STATISTICS, data: data?.data });
      setLoadingModalShow(false);
    } catch (error) {
      dispatch({ type: STATISTICS_LOADED });
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  };

export const getStars = (lang, isStarred) => async (dispatch) => {
  try {
    dispatch({ type: STAR_LOAD });
    var { data } = await databaseAccess(lang, isStarred);
    await dispatch({ type: FETCH_STAR, data: data.data });
  } catch (error) {
    dispatch({ type: STAR_LOADED });
    checkErr(error, false, toastAlert, false, dispatch);
  }
};

export const applicantStar = (id) => async (dispatch) => {
  try {
    var { data } = await starApplicants(id);
  } catch (error) {
    checkErr(error, false, toastAlert, false, dispatch);
  }
};

export const getTeacherInfoByAcademy = (id, lang, job_id) => async (dispatch) => {
  try {
    dispatch({ type: TEACHER_INFO_BY_ACADEMY_LOAD });
    var { data } = await teacherInfoByAcademy(id, lang, job_id);
    await dispatch({ type: FETCH_TEACHER_INFO_BY_ACADEMY, data: data.data });
  } catch (error) {
    dispatch({ type: TEACHER_INFO_BY_ACADEMY_LOADED });
    checkErr(error, false, toastAlert, false, dispatch);
  }
};