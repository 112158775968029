import { slide as Menu } from 'react-burger-menu'
import './sideMenu.css'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from "react";

const SideMenuIcon = (props) => {
    return (
      <img style={{width:'22px', height:'22px'}} alt={props.title} src={props.src}/>
    );
}
export default SideMenuIcon