import { FETCH_GENDER, GENDER_LOAD, GENDER_LOADED } from '../actions/Constants'

export default (gender = { gender: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_GENDER:
            return { ...gender, gender: action.data, loading: false };
        case GENDER_LOAD:
            return { ...gender, loading: true };
        case GENDER_LOADED:
            return { ...gender, loading: false };
        default:
            return gender;
    }
}