import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getSuggested } from '../../../../store/actions/teachers';
import Loading from '../../../layouts/loadingComponents/Loading';
import { Jobs } from '../home/NewHomeTeacher';
import { useTranslation } from "react-i18next";

let lng = JSON.parse(localStorage.getItem("language"))?.lang;

const Suggested = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loadingModalShow, setLoadingModalShow] = useState(true);
  const suggested = useSelector((state) => state?.suggested?.suggested);
  const suggestedLoading = useSelector((state) => state?.suggested?.loading);
  useEffect(() => {
    dispatch(getSuggested(lng === "arab" ? 1 : 2));
  }, []);
  useEffect(() => {
    setLoadingModalShow(suggestedLoading);
  }, [suggestedLoading]);
  return (
    <Fragment>
      {loadingModalShow ? (
        <div
          style={{
            minHeight: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <>
          {/* <div className="bg-white">
            <div className="home-teacher-page container"> */}
          <div className="home-teacher-page-group-1">
            <div className="home-teacher-page-group-1-label">
              {t("Suggested For You")}
            </div>
          </div>
          <Jobs
            title={"There are no jobs :("}
            data={suggested}
            type={"Suggested"}
          />
          {/* </div>
          </div> */}
        </>
      )}
    </Fragment>)
}

export default Suggested