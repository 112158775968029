import { FETCH_APPLICANTS_STATUS, LOAD_APPLICANTS_STATUS, APPLICANTS_STATUS_LOADED } from "../../actions/Constants";

export default (applicantsStatus = { applicantsStatus: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_APPLICANTS_STATUS:
            return { ...applicantsStatus, applicantsStatus: action.data, loading: false };
        case LOAD_APPLICANTS_STATUS:
            return { ...applicantsStatus, loading: true };
        case APPLICANTS_STATUS_LOADED:
            return { ...applicantsStatus, loading: false };
        default:
            return applicantsStatus;
    }
}