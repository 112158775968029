import React, { Fragment, useEffect, useState } from "react";
import "./HomeTeacher.css";
import bannerDefault from "../../../../assets/images/svg/banner.svg";
import logoDefault from "../../../../assets/images/svg/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getSuggested,
  getTeacherApplicantions,
} from "../../../../store/actions/teachers";
import Loading from "../../../layouts/loadingComponents/Loading";
import moment from "moment";
import noData from "../../../../assets/images/svg/noData.svg";
import { useHistory } from "react-router-dom";
import { savedJob, unSaveJob } from "../../../../store/actions/Jobs";
import { useTranslation } from "react-i18next";

const NewHomeTeacher = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;

  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const { teacherApplicantions, loading } = useSelector(
    (state) => state?.teacherApplicantions
  );
  const savedJobs = useSelector((state) => state?.savedJobs?.savedJobs);
  const savedJobsLoading = useSelector((state) => state?.savedJobs?.loading);
  const suggested = useSelector((state) => state?.suggested?.suggested);
  const suggestedLoading = useSelector((state) => state?.suggested?.loading);
  const [forceLoad, setForceLoad] = useState(0);

  useEffect(() => {
    dispatch(getTeacherApplicantions(lng === "arab" ? 1 : 2));
    dispatch(getSuggested(lng === "arab" ? 1 : 2));
  }, []);
  useEffect(() => {
    dispatch(savedJob(lng === "arab" ? 1 : 2));
  }, [forceLoad]);
  const handleUnSave = (item) => {
    setLoadingModalShow(true);
    dispatch(unSaveJob(item.id, setForceLoad, forceLoad, setLoadingModalShow));
  };
  useEffect(() => {
    setLoadingModalShow(loading || savedJobsLoading || suggestedLoading);
  }, [loading || savedJobsLoading || suggestedLoading]);
  console.log("savedJobs", savedJobs);
  return (
    <Fragment>
      {loadingModalShow ? (
        <div
          style={{
            minHeight: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <Fragment>
          <div className="home-teacher-page-group-1">
            <div className="home-teacher-page-group-1-label">
              {t("Applications")}
            </div>
            {teacherApplicantions?.data?.length === 0 ? null : (
              <button
                className="home-teacher-page-group-1-btn"
                onClick={() => history.push("/applications")}
              >
                {t("View All Applicantions")}
              </button>
            )}
          </div>
          <Jobs
            title={t("There are no applications :(")}
            data={teacherApplicantions?.data?.slice(0, 3)}
            type={"Applications"}
          />

          {/* Group 2 */}
          <div className="saved-jobs home-teacher-page-group-1">
            <div className="home-teacher-page-group-1-label">
              {t("Saved Jobs")}
            </div>
            {savedJobs?.length === 0 ? null : (
              <button
                className="home-teacher-page-group-1-btn"
                onClick={() => history.push("/saved-jobs")}
              >
                {t("View All Saved Jobs")}
              </button>
            )}
          </div>
          <Jobs
            title={t("There are no saved jobs :(")}
            data={savedJobs?.slice(0, 3)}
            type={"Saved"}
            handleUnSave={handleUnSave}
          />

          {/* Group 3 */}
          <div className="suggested-for-you home-teacher-page-group-1">
            <div className="home-teacher-page-group-1-label">
              {t("Suggested For You")}
            </div>
            {suggested?.length === 0 ? null : (
              <button
                className="home-teacher-page-group-1-btn"
                onClick={() => history.push("/suggested")}
              >
                {t("View All Jobs")}
              </button>
            )}
          </div>
          <Jobs
            title={t("There are no jobs :(")}
            data={suggested?.slice(0, 3)}
            type={"Suggested"}
          />
          {/* </div>
          </div> */}
        </Fragment>
      )}
    </Fragment>
  );
};

export default NewHomeTeacher;

export const Jobs = ({ title, data, type, handleUnSave }) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  return (
    <Fragment>
      <div className="home-teacher-page-group-2">
        <div className="home-teacher-page-group-2-main">
          {data?.length === 0 ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <img src={noData} />
                <div
                  style={{
                    color: "rgba(0, 0, 0, 1)",
                    fontSize: "20px",
                    fontWeight: 600,
                  }}
                >
                  {title}
                </div>
              </div>
            </>
          ) : (
            data?.map((item, index) => (
              <Fragment>
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12 home-teacher-container  position-relative">
                    <div className="home-teacher-banner">
                      <img
                        src={
                          item?.job?.academy?.banner ||
                          item?.academy?.banner ||
                          bannerDefault
                        }
                        alt="banner"
                      />
                    </div>
                    <img
                      src={
                        item?.job?.academy?.avatar ||
                        item?.academy?.avatar ||
                        logoDefault
                      }
                      alt={"logo"}
                      className="home-teacher-logo"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 home-teacher-container">
                    <div className="home-teacher-page-group-2-2">
                      <div>
                        <div
                          className="home-teacher-page-group-2-2-label-1"
                          style={{ textTransform: "capitalize" }}
                        >
                          {item?.job?.title || item?.title || t("N/A")}
                        </div>
                        <div className="home-teacher-page-group-2-2-label-2">
                          {t("Start in") +
                            " " +
                            (item?.job?.location?.city ||
                              item?.location?.city) +
                            " " +
                            "-" +
                            " " +
                            moment(
                              item?.job?.start_date || item?.start_date
                            ).format("MMM YYYY")}
                        </div>
                      </div>
                      <div>
                        <div className="home-teacher-page-group-2-2-label-3">
                          {t("Eligible Candidates:")}
                        </div>
                        <div className="home-teacher-page-group-2-2-label-4">
                          {item?.job?.types_of_educators
                            ?.map((item) => item?.name)
                            ?.join(", ") ||
                            item?.types_of_educators
                              ?.map((item) => item?.name)
                              ?.join(", ")}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 home-teacher-container">
                    <div className="home-teacher-page-group-2-3">
                      <div>
                        <div className="home-teacher-page-group-2-3-label-1">
                          {t("Grade Level:")}
                        </div>
                        <div className="home-teacher-page-group-2-3-label-2">
                          {item?.job?.grades
                            ?.map((item) => item?.name)
                            ?.join(", ") ||
                            item?.grades?.map((item) => item?.name)?.join(", ")}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 home-teacher-container">
                    <div className="home-teacher-page-group-2-4">
                      {item?.job?.status?.name === "Active" ||
                      item?.job?.status?.name === "Closed" ||
                      type === "Suggested" ? (
                        <div
                          className="home-teacher-page-group-2-4-label-1"
                          onClick={() =>
                            window.open(`https://cvsa.mvp-apps.ae/job-details/${
                              item?.job_id || item?.id
                            }`, '_blank')
                          }
                        >
                          {t("View Job Details")}
                          <svg
                            style={{
                              marginLeft: lng === "arab" ? "0px" : "5px",
                              marginRight: lng === "arab" ? "5px" : "0px",
                              transform:
                                lng === "arab" ? "rotate(180deg)" : null,
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16.522"
                            height="16.522"
                            viewBox="0 0 16.522 16.522"
                          >
                            <path
                              id="Icon_ionic-ios-arrow-dropup-circle"
                              data-name="Icon ionic-ios-arrow-dropup-circle"
                              d="M8.261,16.522A8.261,8.261,0,1,0,0,8.261,8.26,8.26,0,0,0,8.261,16.522Zm0-9.711L5.04,10.064A.767.767,0,0,1,3.956,8.98l3.8-3.789a.766.766,0,0,1,1.056-.024L12.558,8.9a.765.765,0,1,1-1.08,1.084Z"
                              transform="translate(16.522) rotate(90)"
                              fill="#fc563e"
                            />
                          </svg>
                        </div>
                      ) : (
                        <div
                          className="home-teacher-page-group-2-4-label-1"
                          style={{ opacity: "0.4" }}
                        >
                          {t("N/A")}
                        </div>
                      )}
                            
                      <div>
                        {type === "Applications" ? (
                          <Fragment>
                            <div className="home-teacher-page-group-2-4-label-2 mt-3">{t("Applied On")}</div>
                            <div className="home-teacher-page-group-2-3-label-2 mb-3">{moment(item?.apply_date).format("DD MMM YYYY") || "N/A"}</div>
                            <div className="home-teacher-page-group-2-4-label-2">
                              {t("Application Status")}
                            </div>
                            <div className="home-teacher-page-group-2-4-label-3-container">
                              <div className="home-teacher-page-group-2-4-label-3">
                                {(item?.status?.name === "Rejected"? "Not Shortlisted": item?.status?.name) || "N/A"}
                              </div>
                            </div>
                          </Fragment>
                        ) : type === "Saved" ? (
                          <Fragment>
                            <button
                              className="saved-jobs-btn"
                              onClick={() => handleUnSave(item)}
                            >
                              {t("Unsave")}
                            </button>
                          </Fragment>
                        ) : type === "Suggested" ? (
                          <Fragment>
                            <button
                              className="apply-now-btn"
                              onClick={() =>
                                window.location.replace(
                                  `https://cvsa.mvp-apps.ae/job-details/${
                                    item?.job_id || item?.id
                                  }`
                                )
                              }
                            >
                              {t("Apply Now")}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14.494"
                                height="14.494"
                                viewBox="0 0 14.494 14.494"
                              >
                                <g
                                  id="Icon_feather-plus"
                                  data-name="Icon feather-plus"
                                  transform="translate(-6.5 -6.5)"
                                >
                                  <path
                                    id="Path_931"
                                    data-name="Path 931"
                                    d="M18,7.5V19.994"
                                    transform="translate(-4.253)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                  <path
                                    id="Path_932"
                                    data-name="Path 932"
                                    d="M7.5,18H19.994"
                                    transform="translate(0 -4.253)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                </g>
                              </svg>
                            </button>
                          </Fragment>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="home-teacher-page-2-1">
                      <div>
                      <img
                        src={
                          item?.job?.academy?.banner ||
                          item?.academy?.banner ||
                          bannerDefault
                        }
                        alt={"banner"}
                        className="home-teacher-page-2-1-img"
                      />
                      </div>
                      <img
                        src={
                          item?.job?.academy?.avatar ||
                          item?.academy?.avatar ||
                          logoDefault
                        }
                        alt={"logo"}
                        className="home-teacher-page-2-1-logo-frame"
                      />
                    </div>
                  </div> */}
                  {/* <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="home-teacher-page-group-2-2">
                      <div>
                        <div className="home-teacher-page-group-2-2-label-1" style={{ textTransform: "capitalize" }}>
                          {item?.job?.title || item?.title || "N/A"}
                        </div>
                        <div className="home-teacher-page-group-2-2-label-2">
                          {`Start in ${
                            item?.job?.location?.city || item?.location?.city
                          } - ${moment(
                            item?.job?.start_date || item?.start_date
                          ).format("MMM YYYY")}`}
                        </div>
                      </div>
                      <div>
                        <div className="home-teacher-page-group-2-2-label-3">
                          Eligible Candidates:
                        </div>
                        <div className="home-teacher-page-group-2-2-label-4">
                          {item?.job?.types_of_educators
                            ?.map((item) => item?.name)
                            ?.join(", ") ||
                            item?.types_of_educators
                              ?.map((item) => item?.name)
                              ?.join(", ")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="home-teacher-page-group-2-3">
                      <div>
                        <div className="home-teacher-page-group-2-3-label-1">
                          Grade Level:
                        </div>
                        <div className="home-teacher-page-group-2-3-label-2">
                          {item?.job?.grades
                            ?.map((item) => item?.name)
                            ?.join(", ") ||
                            item?.grades?.map((item) => item?.name)?.join(", ")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="home-teacher-page-group-2-4">
                      <div className="home-teacher-page-group-2-4-label-1" onClick={() => window.location.replace(`https://cvsa.mvp-apps.ae/job-details/${item?.job_id || item?.id}`)}>
                        View Job Details
                        <svg
                          style={{ marginLeft: "5px" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.522"
                          height="16.522"
                          viewBox="0 0 16.522 16.522"
                        >
                          <path
                            id="Icon_ionic-ios-arrow-dropup-circle"
                            data-name="Icon ionic-ios-arrow-dropup-circle"
                            d="M8.261,16.522A8.261,8.261,0,1,0,0,8.261,8.26,8.26,0,0,0,8.261,16.522Zm0-9.711L5.04,10.064A.767.767,0,0,1,3.956,8.98l3.8-3.789a.766.766,0,0,1,1.056-.024L12.558,8.9a.765.765,0,1,1-1.08,1.084Z"
                            transform="translate(16.522) rotate(90)"
                            fill="#fc563e"
                          />
                        </svg>
                      </div>
                      <div>
                        {type === "Applications" ? (
                          <Fragment>
                            <div className="home-teacher-page-group-2-4-label-2">
                              Application Status
                            </div>
                            <div className="home-teacher-page-group-2-4-label-3-container">
                              <div className="home-teacher-page-group-2-4-label-3">
                                {item?.status?.name || "N/A"}
                              </div>
                            </div>
                          </Fragment>
                        ) : type === "Saved" ? (
                          <Fragment>
                            <button className="saved-jobs-btn" onClick={() => handleUnSave(item)}>Unsave</button>
                          </Fragment>
                        ) : type === "Suggested" ? (
                          <Fragment>
                            <button className="apply-now-btn" onClick={() => window.location.replace(`https://cvsa.mvp-apps.ae/job-details/${item?.job_id || item?.id}`) }>
                              Apply Now
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14.494"
                                height="14.494"
                                viewBox="0 0 14.494 14.494"
                              >
                                <g
                                  id="Icon_feather-plus"
                                  data-name="Icon feather-plus"
                                  transform="translate(-6.5 -6.5)"
                                >
                                  <path
                                    id="Path_931"
                                    data-name="Path 931"
                                    d="M18,7.5V19.994"
                                    transform="translate(-4.253)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                  <path
                                    id="Path_932"
                                    data-name="Path 932"
                                    d="M7.5,18H19.994"
                                    transform="translate(0 -4.253)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                </g>
                              </svg>
                            </button>
                          </Fragment>
                        ) : null}
                      </div>
                    </div>
                  </div> */}
                </div>
                {data?.length > index + 1 ? (
                  <hr style={{ margin: "30px 0px" }}></hr>
                ) : null}
              </Fragment>
            ))
          )}
        </div>
      </div>
    </Fragment>
  );
};
