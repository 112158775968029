import { FETCH_ACADEMY_TYPE, ACADEMY_TYPE_LOADED, ACADEMY_TYPE_LOAD } from '../actions/Constants'

export default (academyType = { academyType: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_ACADEMY_TYPE:
            return { ...academyType, academyType: action.data, loading: false };
        case ACADEMY_TYPE_LOAD:
            return { ...academyType, loading: true };
        case ACADEMY_TYPE_LOADED:
            return { ...academyType, loading: false };
        default:
            return academyType;
    }
}