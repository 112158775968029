import { JOB_STATUS, LOADING_JOB_STATUS, LOADED_JOB_STATUS } from "../../actions/Constants";

export default (jobStatus = { jobStatus: [], loading: false }, action) => {
    switch (action.type) {
        case JOB_STATUS:
            return { ...jobStatus, jobStatus: action.payload, loading: false };
        case LOADING_JOB_STATUS:
            return { ...jobStatus, loading: true };
        case LOADED_JOB_STATUS:
            return { ...jobStatus, loading: false };
        default:
            return jobStatus;
    }
}