import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  applicantStar,
  getTeacherInfoByAcademy,
  updateApplicationStatus,
} from "../../../../store/actions/applicants";
import Loading from "../../../layouts/loadingComponents/Loading";
import moment from "moment";
import "./ApplicantProfile.css";
import { getApplicationStatus } from "../../../../store/actions/Jobs";
import { toastAlert } from "../../../layouts/toastAlert/toastAlert";
let lng = JSON.parse(localStorage.getItem("language"))?.lang;

function getAge(dateString) {
  var ageInMilliseconds = new Date() - new Date(dateString);
  return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365); // convert to years
}

const ApplicantProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const applicantsStatus = useSelector(
    (state) => state?.applicantsStatus?.applicantsStatus
  );
  const teacherInfo = useSelector(
    (state) => state?.teacherInfoByAcademy?.teacherInfoByAcademy
  );
  const teacherLoading = useSelector(
    (state) => state?.teacherInfoByAcademy?.loading
  );
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const type = new URLSearchParams(search).get("type");
  const jobId = new URLSearchParams(search).get("job");

  const [star, setStar] = useState(teacherInfo?.is_starred);
  const [active, setActive] = useState(true);
  const [loadingModalShow, setLoadingModalShow] = useState(true);
  const [forceLoad, setForceLoad] = useState(0);
  const [reason, setReason] = useState(null);
  const [applicationStatusID, setApplicationStatusID] = useState(
    parseInt(teacherInfo?.application_status_id?.application_status_id)
  );

  const handleStar = (bol) => {
    setStar(bol);
    dispatch(applicantStar(teacherInfo?.id));
  };

  useEffect(() => {
    dispatch(getApplicationStatus(lng === "arab" ? 1 : 2));
    dispatch(getTeacherInfoByAcademy(id, lng === "arab" ? 1 : 2, jobId));
  }, []);

  useEffect(() => {
    setStar(teacherInfo?.is_starred);
    setApplicationStatusID(
      parseInt(teacherInfo?.application_status_id?.application_status_id)
    );
  }, [teacherInfo]);

  useEffect(() => {
    setLoadingModalShow(teacherLoading);
  }, [teacherLoading]);

  useEffect(() => {
    if (forceLoad != 0) {
      dispatch(getApplicationStatus(lng === "arab" ? 1 : 2));
      dispatch(getTeacherInfoByAcademy(id, lng === "arab" ? 1 : 2, jobId));
    }
  }, [forceLoad]);

  const handleSubmitStatus = () => {
    setLoadingModalShow(true);
    if (
      applicationStatusID ==
      teacherInfo?.application_status_id?.application_status_id
    ) {
      setLoadingModalShow(false);
      return toastAlert("Please select different status", undefined, "success");
    }
    let userData = {
      changes: [
        {
          application_id: teacherInfo?.application_status_id?.id,
          job_id: jobId,
        },
      ],
      status: applicationStatusID,
      reason: reason,
    };
    dispatch(
      updateApplicationStatus(
        userData,
        lng === "arab" ? 1 : 2,
        setLoadingModalShow,
        setForceLoad,
        forceLoad
      )
    );
  };
  const isJobIdExist = window.location.href.includes('job')
  return (
    <>
      {loadingModalShow ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "306px",
          }}
        >
          <Loading />
        </div>
      ) : Object.keys(teacherInfo).length === 0 ||
        ((!jobId && isJobIdExist) || (isJobIdExist && teacherInfo?.application_status_id === null)) ? (
        <div className="group-invalid-id">
          <h5 className="group-1-name">{t("You don't have this applicant")}</h5>
          <button className="group-home-btn" onClick={() => history.push("")}>
            {t("Back To Home")}
          </button>
        </div>
      ) : (
        <div className="applicant-profile-page">
          <div className="row">
            {/* Profile  */}
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="personal-info">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    position: "relative",
                  }}
                >
                  {teacherInfo.avatar ? (
                    <img
                      src={teacherInfo.avatar}
                      style={{
                        width: "76px",
                        height: "76px",
                        borderRadius: "38px",
                      }}
                    />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="76"
                      height="76"
                      viewBox="0 0 76 76"
                    >
                      <defs>
                        <linearGradient
                          id="linear-gradient"
                          x1="0.5"
                          y1="1"
                          x2="0.5"
                          gradientUnits="objectBoundingBox"
                        >
                          <stop offset="0" stopColor="#008c77" />
                          <stop offset="1" stopColor="#04c4a7" />
                        </linearGradient>
                        <linearGradient
                          id="linear-gradient-2"
                          x1="0.5"
                          y1="1"
                          x2="0.5"
                          gradientUnits="objectBoundingBox"
                        >
                          <stop offset="0" stopColor="#addcff" />
                          <stop offset="0.503" stopColor="#eaf6ff" />
                          <stop offset="1" stopColor="#eaf6ff" />
                        </linearGradient>
                      </defs>
                      <g
                        id="user_5_"
                        data-name="user (5)"
                        transform="translate(0.55 0.719)"
                      >
                        <g
                          id="Group_1219"
                          data-name="Group 1219"
                          transform="translate(-0.579 -0.747)"
                        >
                          <g
                            id="Group_1218"
                            data-name="Group 1218"
                            transform="translate(0 0)"
                          >
                            <circle
                              id="Ellipse_6"
                              data-name="Ellipse 6"
                              cx="38"
                              cy="38"
                              r="38"
                              transform="translate(0.028 0.028)"
                              fill="url(#linear-gradient)"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_1221"
                          data-name="Group 1221"
                          transform="translate(17.635 13.243)"
                        >
                          <g id="Group_1220" data-name="Group 1220">
                            <path
                              id="Path_930"
                              data-name="Path 930"
                              d="M151.7,101.963a10.963,10.963,0,1,0-10.963,10.963A10.976,10.976,0,0,0,151.7,101.963Zm-10.963,10.963A19.756,19.756,0,0,0,121,132.66v2.055a2.192,2.192,0,0,0,.729,1.633,28.043,28.043,0,0,0,38.009,0,2.192,2.192,0,0,0,.729-1.633V132.66A19.756,19.756,0,0,0,140.734,112.926Z"
                              transform="translate(-121 -91)"
                              fill="url(#linear-gradient-2)"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  )}
                  {star ? (
                    <i
                      style={{
                        right: "22px",
                        position: "absolute",
                        margin: "0",
                        cursor: "pointer",
                        color: "#FFBF00",
                      }}
                      className="bi bi-star-fill fa-2x"
                      onClick={() => handleStar(false)}
                    ></i>
                  ) : (
                    <i
                      style={{
                        right: "22px",
                        position: "absolute",
                        margin: "0",
                        cursor: "pointer",
                      }}
                      className="bi bi-star fa-2x"
                      onClick={() => handleStar(true)}
                    ></i>
                  )}
                </div>
                <div className="group-1-main">
                  <div className="group-1-name">
                    {teacherInfo?.first_name + " " + teacherInfo?.last_name}
                  </div>
                  <div className="group-1-title">
                    {teacherInfo?.location?.country}
                  </div>
                </div>
                <div className="group-1-personal-qualification">
                  <div
                    className="group-1-qualification"
                    onClick={() => setActive(true)}
                    style={{
                      borderBottom: active ? "2px solid #008C77" : "none",
                    }}
                  >
                    {t("Qualification")}
                  </div>
                  <div
                    className="group-1-personal"
                    onClick={() => setActive(false)}
                    style={{
                      borderBottom: active ? "none" : "2px solid #008C77",
                    }}
                  >
                    {t("Personal")}
                  </div>
                </div>
                <div
                  style={{
                    height: "1.5px",
                    opacity: "1.5",
                    border: 0,
                    backgroundColor: "#E0E0E0",
                    marginTop: "5px",
                  }}
                />
                {active ? (
                  <div className="group-1-qualification-details">
                    <div className="group-1-qualification-main">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="group-1-qualification-icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          id="Icon_awesome-flag"
                          data-name="Icon awesome-flag"
                          d="M16.827,4.63C14.276,4.63,12.168,3,8.96,3a9.386,9.386,0,0,0-3.24.563,2.585,2.585,0,0,0,.171-1.1,2.668,2.668,0,0,0-5.328.166A2.61,2.61,0,0,0,1.705,4.779v18.1A1.134,1.134,0,0,0,2.848,24H3.61a1.134,1.134,0,0,0,1.143-1.125V18.45A13.487,13.487,0,0,1,10.2,17.413c2.552,0,4.659,1.63,7.867,1.63A10.073,10.073,0,0,0,23.9,17.128a1.491,1.491,0,0,0,.659-1.236V4.5A1.522,1.522,0,0,0,22.4,3.137,14.034,14.034,0,0,1,16.827,4.63Z"
                          transform="translate(-0.563 0)"
                          fill="#0b365c"
                        />
                      </svg>

                      <div className="group-1-qualification-title">
                        {getAge(teacherInfo?.dob) + t(" Years Old")}
                      </div>
                    </div>
                    <div className="group-1-qualification-main">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="group-1-qualification-icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <defs>
                          <clipPath id="clip-path">
                            <rect
                              id="Rectangle_18451"
                              data-name="Rectangle 18451"
                              width="24"
                              height="24"
                              transform="translate(103 496)"
                              fill="#0b365c"
                              stroke="#707070"
                              stroke-width="1"
                            />
                          </clipPath>
                        </defs>
                        <g
                          id="Mask_Group_12"
                          data-name="Mask Group 12"
                          transform="translate(-103 -496)"
                          clip-path="url(#clip-path)"
                        >
                          <g id="graduation-hat" transform="translate(103 496)">
                            <path
                              id="Path_998"
                              data-name="Path 998"
                              d="M23.548,8.312,12.3,3.436a.749.749,0,0,0-.6,0L.451,8.312a.751.751,0,0,0,0,1.376L11.7,14.563a.749.749,0,0,0,.6,0l7.952-3.446V19.1a.764.764,0,0,0,.714.77.75.75,0,0,0,.786-.749v-8.28a.234.234,0,0,0-.2-.232L11.9,9.369a.75.75,0,0,1,.139-1.493c.034,0,10.722,1.381,10.715,1.38a.565.565,0,0,1,.443.327.237.237,0,0,0,.316.12.752.752,0,0,0,.033-1.392Z"
                              fill="#0b365c"
                            />
                            <path
                              id="Path_999"
                              data-name="Path 999"
                              d="M11.1,15.94l-6.278-2.72a.234.234,0,0,0-.328.215v2.691c0,2.523,3.295,4.5,7.5,4.5s7.5-1.977,7.5-4.5V13.434a.234.234,0,0,0-.328-.215l-6.278,2.72A2.247,2.247,0,0,1,11.1,15.94Z"
                              fill="#0b365c"
                            />
                          </g>
                        </g>
                      </svg>

                      <div className="group-1-qualification-title">
                        {teacherInfo?.highest_education_degree?.degree_level
                          ?.name +
                          t(", ") +
                          teacherInfo?.highest_education_degree?.title}
                      </div>
                    </div>
                    <div className="group-1-qualification-main">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        className="group-1-qualification-icon"
                      >
                        <defs>
                          <style>
                            {
                              ".a,.c{fill:#0b365c;}.a{stroke:#707070;}.b{clip-path:url(#a);}"
                            }
                          </style>
                          <clipPath id="a">
                            <rect
                              className="a"
                              width={24}
                              height={24}
                              transform="translate(119 535)"
                            />
                          </clipPath>
                        </defs>
                        <g className="b" transform="translate(-119 -535)">
                          <g transform="translate(119 536.453)">
                            <path
                              className="c"
                              d="M9.891,11.25h4.219v1.406H9.891Zm0,0"
                            />
                            <path
                              className="c"
                              d="M19.471,12.656H15.516v.7a.7.7,0,0,1-.7.7H9.188a.7.7,0,0,1-.7-.7v-.7H4.529a2.107,2.107,0,0,1-2-1.442L0,3.629V18.984a2.112,2.112,0,0,0,2.109,2.109H21.891A2.112,2.112,0,0,0,24,18.984V3.629l-2.528,7.585A2.107,2.107,0,0,1,19.471,12.656Zm0,0"
                            />
                            <path
                              className="c"
                              d="M14.813,0H9.188A2.112,2.112,0,0,0,7.078,2.109v.7H1.21l2.652,7.957a.7.7,0,0,0,.667.481H8.484v-.7a.7.7,0,0,1,.7-.7h5.625a.7.7,0,0,1,.7.7v.7h3.955a.7.7,0,0,0,.667-.481L22.79,2.813H16.922v-.7A2.112,2.112,0,0,0,14.813,0ZM8.484,2.813v-.7a.7.7,0,0,1,.7-.7h5.625a.7.7,0,0,1,.7.7v.7Zm0,0"
                            />
                          </g>
                        </g>
                      </svg>
                      <div
                        // style={{ marginBottom: "80px" }}
                        className="group-1-qualification-title"
                      >
                        {teacherInfo?.total_teaching_experience_years +
                          t(" Years teaching experience")}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="group-1-personal-details">
                      <div className="group-1-personal-label">{t("Age")}</div>
                      <div className="group-1-personal-title">
                        {getAge(teacherInfo?.dob) + t(" Years Old") || t("N/A")}
                      </div>
                      <div className="group-1-personal-label">
                        {t("Date of Birth")}
                      </div>
                      <div className="group-1-personal-title">
                        {moment(teacherInfo?.dob).format("MMM, DD, YYYY") ||
                          "N/A"}
                      </div>
                      <div className="group-1-personal-label">{t("Phone Number")}</div>
                      <div className="group-1-personal-title">
                        {teacherInfo?.contact_number || "N/A"}
                      </div>
                    </div>
                  </>
                )}

                {jobId ? (
                  <>
                    <div className="group-1-status-input-submit-btn">
                      <select
                        className="group-1-status-input"
                        placeholder="status"
                        disabled={
                          teacherInfo?.application_status_id
                            ?.application_status_id == 3 ||
                          teacherInfo?.application_status_id
                            ?.application_status_id == 7
                        }
                        value={applicationStatusID}
                        onChange={(e) => setApplicationStatusID(e.target.value)}
                      >
                        {/* <option value="">Select</option> */}
                        {applicantsStatus?.map((item, index) => (
                          <option
                            value={item?.id}
                            key={index}
                            disabled={
                              item?.id == 1 ||
                              item?.id ==
                              teacherInfo?.application_status_id
                                ?.application_status_id ||
                              (teacherInfo?.application_status_id
                                ?.application_status_id == 4 &&
                                item?.id == 1) ||
                              (teacherInfo?.application_status_id
                                ?.application_status_id == 4 &&
                                item?.id == 2) ||
                              (teacherInfo?.application_status_id
                                ?.application_status_id == 5 &&
                                item?.id == 1) ||
                              (teacherInfo?.application_status_id
                                ?.application_status_id == 5 &&
                                item?.id == 2) ||
                              (teacherInfo?.application_status_id
                                ?.application_status_id == 5 &&
                                item?.id == 4) ||
                              (teacherInfo?.application_status_id
                                ?.application_status_id == 6 &&
                                item?.id == 1) ||
                              (teacherInfo?.application_status_id
                                ?.application_status_id == 6 &&
                                item?.id == 2) ||
                              (teacherInfo?.application_status_id
                                ?.application_status_id == 6 &&
                                item?.id == 4) ||
                              (teacherInfo?.application_status_id
                                ?.application_status_id == 6 &&
                                item?.id == 5)
                            }
                          >
                            {item?.name}
                          </option>
                        ))}
                      </select>
                      <button
                        className="group-1-submit-btn"
                        onClick={() => handleSubmitStatus()}
                      >
                        {t("Change Status")}
                      </button>
                    </div>
                    <div className="m-3 pb-5 pt-3">
                      {applicationStatusID === "3" && (
                        <input
                          style={{ width: "100%" }}
                          className="group-1-status-input"
                          placeholder={t("Reason")}
                          value={reason}
                          onChange={(e) => setReason(e.target.value)}
                        ></input>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="m-3 pb-5 pt-3"></div>
                  </>
                )}
              </div>
            </div>

            <div className="col">
              {/* Education  */}
              {teacherInfo?.educations?.length > 0 ? (
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="group-heading">{t("Education")}</div>
                  <div className="group-2-main">
                    {teacherInfo?.educations?.map((item, index) => (
                      <>
                        <div className="group-2-info">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="17.251"
                              viewBox="0 0 24 17.251"
                            >
                              <g
                                id="graduation-hat"
                                transform="translate(0 -3.375)"
                              >
                                <path
                                  id="Path_1003"
                                  data-name="Path 1003"
                                  d="M23.548,8.312,12.3,3.436a.749.749,0,0,0-.6,0L.451,8.312a.751.751,0,0,0,0,1.376L11.7,14.563a.749.749,0,0,0,.6,0l7.952-3.446V19.1a.764.764,0,0,0,.714.77.75.75,0,0,0,.786-.749v-8.28a.234.234,0,0,0-.2-.232L11.9,9.369a.75.75,0,0,1,.139-1.493c.034,0,10.722,1.381,10.715,1.38a.565.565,0,0,1,.443.327.237.237,0,0,0,.316.12.752.752,0,0,0,.033-1.392Z"
                                  fill="#0b365c"
                                />
                                <path
                                  id="Path_1004"
                                  data-name="Path 1004"
                                  d="M11.1,15.94l-6.278-2.72a.234.234,0,0,0-.328.215v2.691c0,2.523,3.295,4.5,7.5,4.5s7.5-1.977,7.5-4.5V13.434a.234.234,0,0,0-.328-.215l-6.278,2.72A2.247,2.247,0,0,1,11.1,15.94Z"
                                  fill="#0b365c"
                                />
                              </g>
                            </svg>
                            <div className="group-2-title">{item?.college}</div>
                          </div>
                          <div className="group-2-education">
                            <div className="group-2-body">
                              {item?.degree_level?.name + ", " + item?.title}
                            </div>
                            <div className="group-2-body">
                              {item?.year_of_graduation +
                                t(", year of graduation")}
                            </div>
                          </div>
                        </div>
                        {teacherInfo?.educations[index + 1] ? (
                          <div className="group-divider">
                            <hr />
                          </div>
                        ) : null}
                      </>
                    ))}
                  </div>
                </div>
              ) : null}

              {/* Certificate and license  */}
              {teacherInfo?.teaching_licenses?.length > 0 ? (
                <div className="col-lg-12 col-md-12 col-sm-12 ">
                  <div className="group-heading">{t("CERTIFICATE & LICENSE")}</div>
                  <div className="group-2-main">
                    {teacherInfo?.teaching_licenses?.map((item, index) => (
                      <>
                        <div className="group-2-info">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <svg width={24} height={24} viewBox="0 0 24 24">
                              <defs>
                                <style>
                                  {
                                    ".a,.c{fill:#0b365c;}.a{stroke:#707070;}.b{clip-path:url(#a);}"
                                  }
                                </style>
                                <clipPath id="aa">
                                  <rect
                                    className="aa"
                                    width={24}
                                    height={24}
                                    transform="translate(645 642)"
                                  />
                                </clipPath>
                              </defs>
                              <g
                                className="bb"
                                transform="translate(-645 -642)"
                              >
                                <g transform="translate(645 642)">
                                  <path
                                    className="cc"
                                    d="M4.5,5.25h15a.75.75,0,0,0,0-1.5H4.5a.75.75,0,0,0,0,1.5Z"
                                  />
                                  <path
                                    className="cc"
                                    d="M4.5,8.25H12a.75.75,0,0,0,0-1.5H4.5a.75.75,0,0,0,0,1.5Z"
                                  />
                                  <path
                                    className="cc"
                                    d="M4.5,11.25H9a.75.75,0,1,0,0-1.5H4.5a.75.75,0,0,0,0,1.5Z"
                                  />
                                  <path
                                    className="cc"
                                    d="M16.5,14.25a1.5,1.5,0,1,0-1.5-1.5A1.5,1.5,0,0,0,16.5,14.25Z"
                                  />
                                  <path
                                    className="cc"
                                    d="M23.25,0H.75A.75.75,0,0,0,0,.75v15a.752.752,0,0,0,.75.75h12v6.75a.75.75,0,0,0,1.2.6l2.55-1.912,2.55,1.912a.75.75,0,0,0,1.2-.6V16.5h3a.752.752,0,0,0,.75-.75V.75A.752.752,0,0,0,23.25,0ZM13.611,11.622l-.132-.616.6-.193a.75.75,0,0,0,.484-.483l.194-.6.616.132a.748.748,0,0,0,.661-.177l.468-.423.467.423a.749.749,0,0,0,.661.177l.616-.132.194.6a.75.75,0,0,0,.484.483l.6.193-.132.616a.749.749,0,0,0,.177.661l.423.467-.423.467a.749.749,0,0,0-.177.661l.132.616-.6.193a.75.75,0,0,0-.484.483l-.194.6-.616-.133a.744.744,0,0,0-.661.177l-.467.423-.468-.423a.75.75,0,0,0-.661-.177l-.616.133-.193-.6a.75.75,0,0,0-.484-.483l-.6-.193.132-.616a.749.749,0,0,0-.177-.661l-.423-.467.423-.467a.749.749,0,0,0,.177-.661ZM16.95,20.4a.75.75,0,0,0-.9,0l-1.8,1.35V17.384a.727.727,0,0,0,.158,0l.9-.194.686.62a.75.75,0,0,0,1.006,0l.686-.62.9.194a.724.724,0,0,0,.158,0V21.75l-1.8-1.35ZM22.5,15H21.134a.722.722,0,0,0,0-.158l-.194-.9.62-.686a.751.751,0,0,0,0-1.006l-.62-.686.194-.9a.75.75,0,0,0-.5-.871l-.88-.283-.284-.88a.75.75,0,0,0-.871-.5l-.9.194L17,7.694a.75.75,0,0,0-1.006,0l-.686.62-.9-.194a.752.752,0,0,0-.871.5l-.284.88-.88.283a.75.75,0,0,0-.5.871l.194.9-.62.686a.751.751,0,0,0,0,1.006l.62.686-.194.9a.721.721,0,0,0,0,.158H1.5V1.5h21V15Z"
                                  />
                                </g>
                              </g>
                            </svg>

                            <div className="group-2-title">
                              {item?.license_type}
                            </div>
                          </div>
                          <div className="group-2-education">
                            <>
                              <div className="group-2-body">
                                {item?.place_of_issuance +
                                  " - " +
                                  item?.country}
                              </div>
                              <div className="group-2-body">
                                {(item?.valid_from
                                  ? moment(item?.valid_from).format("MMM YYYY")
                                  : "") +
                                  " - " +
                                  (item?.valid_to
                                    ? moment(item?.valid_to).format("MMM YYYY")
                                    : t("Ongoing"))}
                              </div>
                              {item?.license_type === "Teaching License" && (
                                <>
                                  <div
                                    className="group-subject-grade"
                                    style={{ marginTop: "18px" }}
                                  >
                                    <div className="group-subject">
                                      {t("Subjects :")}
                                    </div>
                                    <div className="group-grade semi-bold-black">
                                      {item?.subjects
                                        ?.map((item) => item?.name)
                                        ?.join(", ")}
                                    </div>
                                  </div>
                                  <div className="group-subject-grade">
                                    <div className="group-subject">
                                      {t("Grade level:")}
                                    </div>
                                    {item?.grades?.map((i, key) => (
                                      <div className="group-grade semi-bold-black">
                                        {i?.name +
                                          (item?.grades[key + 1] ? ", " : "")}
                                      </div>
                                    ))}
                                  </div>
                                </>
                              )}
                            </>
                          </div>
                        </div>
                        {teacherInfo?.teaching_licenses[index + 1] ? (
                          <div className="group-divider">
                            <hr />
                          </div>
                        ) : null}
                      </>
                    ))}
                  </div>
                </div>
              ) : null}

              {/* Experience  */}
              {teacherInfo?.experiences?.length > 0 ? (
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="group-heading">{t("EXPERIENCE")}</div>
                  <div className="group-2-main">
                    {teacherInfo?.experiences?.map((item, index) => (
                      <>
                        <div className="group-2-info">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="17.251"
                              viewBox="0 0 24 17.251"
                            >
                              <g
                                id="graduation-hat"
                                transform="translate(0 -3.375)"
                              >
                                <path
                                  id="Path_1003"
                                  data-name="Path 1003"
                                  d="M23.548,8.312,12.3,3.436a.749.749,0,0,0-.6,0L.451,8.312a.751.751,0,0,0,0,1.376L11.7,14.563a.749.749,0,0,0,.6,0l7.952-3.446V19.1a.764.764,0,0,0,.714.77.75.75,0,0,0,.786-.749v-8.28a.234.234,0,0,0-.2-.232L11.9,9.369a.75.75,0,0,1,.139-1.493c.034,0,10.722,1.381,10.715,1.38a.565.565,0,0,1,.443.327.237.237,0,0,0,.316.12.752.752,0,0,0,.033-1.392Z"
                                  fill="#0b365c"
                                />
                                <path
                                  id="Path_1004"
                                  data-name="Path 1004"
                                  d="M11.1,15.94l-6.278-2.72a.234.234,0,0,0-.328.215v2.691c0,2.523,3.295,4.5,7.5,4.5s7.5-1.977,7.5-4.5V13.434a.234.234,0,0,0-.328-.215l-6.278,2.72A2.247,2.247,0,0,1,11.1,15.94Z"
                                  fill="#0b365c"
                                />
                              </g>
                            </svg>
                            <div className="group-2-title">{item?.title}</div>
                          </div>
                          <div className="group-2-education">
                            <div className="group-2-body">
                              {item?.school + " - " + item?.country}
                            </div>
                            <div className="group-2-body">
                              {(item?.start_date
                                ? moment(item?.start_date).format("MMM YYYY")
                                : "") +
                                " - " +
                                (item?.end_date
                                  ? moment(item?.end_date).format("MMM YYYY")
                                  : t("Ongoing")) +
                                ", " +
                                item?.years_of_experience +
                                t(" year of experience")}
                            </div>

                            {item?.experience_type === "Teaching" && (
                              <>
                                <div
                                  className="group-subject-grade"
                                  style={{ marginTop: "18px" }}
                                >
                                  <div className="group-subject">
                                    {t("Curriculum :")}
                                  </div>
                                  <div className="group-grade semi-bold-black">
                                    {item?.curriculum?.name}
                                  </div>
                                </div>

                                <div className="group-subject-grade">
                                  <div className="group-subject">{t("Subjects :")}</div>
                                  <div className="group-grade semi-bold-black">
                                    {item?.subjects
                                      ?.map((item) => item?.name)
                                      ?.join(", ")}
                                  </div>
                                </div>

                                <div className="group-subject-grade">
                                  <div className="group-subject">
                                    {t("Grade level:")}
                                  </div>
                                  <div className="group-grade semi-bold-black">
                                    {item?.grades
                                      ?.map((item) => item?.name)
                                      ?.join(", ")}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        {teacherInfo?.experiences[index + 1] ? (
                          <div className="group-divider">
                            <hr />
                          </div>
                        ) : null}
                      </>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ApplicantProfile;
