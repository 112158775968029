import checkErr from "./api_error_func";
import { FETCH_NOTIFICATIONS, NOTIFICATIONS_LOAD, NOTIFICATIONS_LOADED } from "./Constants";
import * as api from "../../services/API";
import { toastAlert } from "../../jsx/layouts/toastAlert/toastAlert";

export const getNotifications = (setLoadingModalShow, lng) => async (dispatch) => {
    dispatch({ type: NOTIFICATIONS_LOAD });
    try {
      const { data } = await api.notifications(lng);
      await dispatch({ type: FETCH_NOTIFICATIONS, data: data?.data });
      setLoadingModalShow(false)
    } catch (error) {
      dispatch({ type: NOTIFICATIONS_LOADED });
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  };

  export const markNotificationsAsRead = (ids, setLoadingModalShow) => async (dispatch) => {
    try {
      const { data } = await api.readNotifications(ids);
      await dispatch({ type: FETCH_NOTIFICATIONS, data: data?.data });
      setLoadingModalShow(false)
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
  };