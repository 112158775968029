import { LANGUAGES, LOADING_LANGUAGES, LOADED_LANGUAGES } from "../../actions/Constants";

export default (languages = { languages: [], loading: false }, action) => {
    switch (action.type) {
        case LANGUAGES:
            return { ...languages, languages: action.data, loading: false };
        case LOADING_LANGUAGES:
            return { ...languages, loading: true };
        case LOADED_LANGUAGES:
            return { ...languages, loading: false };
        default:
            return languages;
    }
}