import { FETCH_ACADEMY_INFO, ACADEMY_INFO_LOADED, ACADEMY_INFO_LOAD } from '../actions/Constants'

export default (academyInfo = { academyInfo: {}, loading: false }, action) => {
    switch (action.type) {
        case FETCH_ACADEMY_INFO:
            return { ...academyInfo, academyInfo: action.data, loading: false };
        case ACADEMY_INFO_LOAD:
            return { ...academyInfo, loading: true };
        case ACADEMY_INFO_LOADED:
            return { ...academyInfo, loading: false };
        default:
            return academyInfo;
    }
}