import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getDraftsJobs } from "../../../../../store/actions/Jobs";
import { useLocation } from "react-router-dom";
import JobDataTable from "../ManageJobs/JobDataTable";
import Loading from "../../../../layouts/loadingComponents/Loading";
import NewJobDataTable from "../ManageJobs/NewJobDataTable";
const NewDrafts = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const dispatch = useDispatch();
  const academyDrafts = useSelector((state) => state?.drafts?.drafts);
  const academyDraftsLoading = useSelector((state) => state?.drafts?.loading);
  const [loadingModalShow, setLoadingModalShow] =
    useState(academyDraftsLoading);
  const [forceLoad, setForceLoad] = useState(0);
  useEffect(() => {
    dispatch(getDraftsJobs(lng === "arab" ? 1 : 2));
  }, [forceLoad]);

  useEffect(() => {
    setLoadingModalShow(academyDraftsLoading);
  }, [academyDraftsLoading]);
  return (
    <>
      {/* <div style={{display: 'flex', alignItems:'center', justifyContent:'space-between', gap:'15px'}} className="mb-2 flex-wrap">
                <div className="d-flex align-items-center mb-2 flex-wrap">
				    <h4 className="fs-20 font-w600 me-auto">{t("You didn't complete these jobs")}</h4>
			    </div>
			</div> */}
      <div className="home-page container">
        {loadingModalShow ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "200px",
            }}
          >
            <Loading />
          </div>
        ) : (
          <>
            <div className="col-lg-4 col-sm-12">
              <p className="title semi-bold-black">
                You didn't complete these jobs
              </p>
            </div>
            <NewJobDataTable
              forceLoad={forceLoad}
              setForceLoad={setForceLoad}
              page
              drafts
              jobs={academyDrafts}
              loadingModalShow={loadingModalShow}
            />
          </>
        )}
      </div>
    </>
  );
};
export default NewDrafts;
