import { base_API_URL, baseURL } from "./API";
import axios from "axios";

export const getTeachers = async (pgNumber, lang) => {
  const data = await axios.get(
    `${base_API_URL}search_teachers?page=${pgNumber}&lng=${lang}`
  );
  return data;
};

export const getTeacherApplicantion = async (lang) => {
  return await axios.get(`${base_API_URL}teacherApplications?lang=${lang}`);
};

export const teacherInfo = async (lang) => {
  return await axios.get(`${base_API_URL}teacherInfo?lang=${lang}`);
};

export const updateTeacherGeneralInformation = async (userData) => {
  console.log("userData", userData);
  let finalFormData = new FormData();
  for (const item in userData) {
    if (item === "languages" || item === "citizenships") {
      for (let i = 0; i < userData[item].length; i++) {
        finalFormData.append(`${item}[${i}]`, userData[item][i]["value"]);
      }
    } else {
      finalFormData.append(item, userData[item]);
    }
  }
  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/updateTeacherInfo`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

export const destroyTeacherResume = async () => {
  return await axios.delete(`${base_API_URL}destroyResume`);
};

export const updateTeacherEducation = async (data) => {
  return await axios.post(`${base_API_URL}education/update`, data);
};

export const teacherEducation = async (userData) => {
  let finalFormData = new FormData();
  for (let i = 0; i < userData.length; i++) {
    for (const item in userData[i]) {
      finalFormData.append(`educations[${i}][${item}]`, userData[i][item]);
    }
  }
  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/education/store`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

export const destroyTeacherEducation = async (education_id) => {
  return await axios.delete(
    `${base_API_URL}education/destroy?education_id=${education_id}`
  );
};

export const updateTeacherExperience = async (userData) => {
  let finalFormData = new FormData();
  for (const item in userData) {
    if (item === "grades" || item === "subjects") {
      for (let i = 0; i < userData[item].length; i++) {
        finalFormData.append(`${item}[${i}]`, userData[item][i]["value"]);
      }
    } else {
      finalFormData.append(item, userData[item]);
    }
  }
  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/experience/update`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

export const destroyTeacherExperience = async (experience_id) => {
  return await axios.delete(
    `${base_API_URL}experience/destroy?experience_id=${experience_id}`
  );
};

export const teacherExperience = async (userData) => {
  let finalFormData = new FormData();
  for (let i = 0; i < userData.length; i++) {
    for (const item in userData[i]) {
      if (item === "grades" || item === "subjects") {
        for (let j = 0; j < userData[i][item].length; j++) {
          finalFormData.append(
            `experiences[${i}][${item}][${j}]`,
            userData[i][item][j]["value"]
          );
        }
      } else {
        finalFormData.append(`experiences[${i}][${item}]`, userData[i][item]);
      }
    }
  }
  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/experience/store`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

export const teacherLicense = async (userData) => {
  let finalFormData = new FormData();
  for (let i = 0; i < userData.length; i++) {
    for (const item in userData[i]) {
      if (item === "grades" || item === "subjects") {
        for (let j = 0; j < userData[i][item].length; j++) {
          finalFormData.append(
            `licenses[${i}][${item}][${j}]`,
            userData[i][item][j]["value"]
          );
        }
      } else {
        finalFormData.append(`licenses[${i}][${item}]`, userData[i][item]);
      }
    }
  }
  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/teachingLicense/store`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

export const destroyTeacherLicense = async (license_id) => {
  return await axios.delete(
    `${base_API_URL}teachingLicense/destroy?license_id=${license_id}`
  );
};

export const updateTeacherLicense = async (userData) => {
  let finalFormData = new FormData();
  for (const item in userData) {
    if (item === "grades" || item === "subjects") {
        for (let i = 0; i < userData[item].length; i++) {
          finalFormData.append(`${item}[${i}]`, userData[item][i]["value"]);
        }
    } else {
      finalFormData.append(item, userData[item]);
    }
  }
  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/teachingLicense/update`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

export const licenseTypes = async (langNumber) =>
  await axios.post(
    `https://api.madaresweb.mvp-apps.ae/api/teachingLicenseType?lang=${langNumber}`
  );

export const documentTypes = async (lang) =>
  await axios.post(`${base_API_URL}documentType?lang=${lang}`);

export const document = async (userData) => {
  let finalFormData = new FormData();
  for (const item in userData) {
    finalFormData.append(item, userData[item]);
  }
  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/addDocument`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

export const destroyTeacherDocument = async (document_id) => {
  return await axios.delete(
    `${base_API_URL}destroyDocument?document_id=${document_id}`
  );
};

export const suggestedForYou = async (lang) => {
  return await axios.get(
    `${base_API_URL}suggestedJobs?lang=${lang}`
  );
};

export const industry = async (lang) => {
  return await axios.post(
    `${base_API_URL}industry?lang=${lang}`
  );
};

export const hideProfile = async () => {
  return await axios.post(
    `${base_API_URL}hideProfile`
  );
};

export const unHideProfile = async () => {
  return await axios.post(
    `${base_API_URL}unHideProfile`
  );
};