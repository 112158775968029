import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getBestCandidates } from "../../../../store/actions/applicants";
import { t } from "i18next";
import { getAllJobTitles } from "../../../../store/actions/Jobs";
import { useHistory } from "react-router-dom";
import Loading from "../../../layouts/loadingComponents/Loading";

let lng = JSON.parse(localStorage.getItem("language"))?.lang;

const BestCandidate = () => {
  const bestCandidates = useSelector(
    (state) => state?.bestCandidates?.bestCandidates
  );
  const bestCandidatesLoading = useSelector(
    (state) => state?.bestCandidates?.loading
  );
  const [bestCandidatePopUp, setBestCandidatePopUp] = useState(true);
  const [jobTitle, setJobTitle] = useState("");
  const [applicantPopUp, setApplicantPopUp] = useState(false);
  const [loadingModalShow, setLoadingModalShow] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (jobTitle) {
      dispatch(
        getBestCandidates(jobTitle, setLoadingModalShow, lng === "arab" ? 1 : 2)
      );
    }
  }, [jobTitle]);

  useEffect(() => {
    setLoadingModalShow(bestCandidatesLoading);
  }, [bestCandidatesLoading]);

  const handleSearch = () => {
    setApplicantPopUp(false);
    setBestCandidatePopUp(true);
  };

  const onHide = () => {
    setBestCandidatePopUp(false);
    setApplicantPopUp(true);
  };

  return (
    <>
      <BestCandidateModal
        show={bestCandidatePopUp}
        onHide={() => onHide()}
        setApplicantPopUp={setApplicantPopUp}
        jobTitle={jobTitle}
        setJobTitle={setJobTitle}
      />
      {applicantPopUp && (
        <Fragment>
          {loadingModalShow ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "306px",
              }}
            >
              <Loading />
            </div>
          ) : (
            <div className="home-page container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className="title semi-bold-black">{t("Best Candidates")}</p>
                <button
                  onClick={handleSearch}
                  className="btn-2 semi-bold-white"
                >
                  {t("Search")}
                </button>
              </div>
              {bestCandidates?.length === 0 ? (
                <div className="no-data-message">
                  <h5
                    style={{
                      color: "black",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "15px",
                    }}
                  >
                    {t("No Data Found!")}
                  </h5>
                </div>
              ) : (
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>{t("Name")}</th>
                        <th>{t("Citizenship")}</th>
                        <th>{t("Exp")}</th>
                        <th>{t("Highest Degree")}</th>
                        <th>{t("Major")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bestCandidates?.map((item, index) => (
                        <>
                          <tr>
                            <td
                              className="applicant-name"
                              onClick={() =>
                                history.push(
                                  `applicant-profile?id=${item?.teacher?.id}`
                                )
                              }
                            >
                              {item?.teacher?.first_name +
                                " " +
                                item?.teacher?.last_name}
                            </td>
                            <td>
                              {item?.teacher?.citizenships?.slice(0, 1)?.shift()
                                ?.name || "-"}
                            </td>
                            <td>
                              {item?.teacher
                                ?.total_teaching_experience_years === "0" &&
                              item?.teacher
                                ?.total_teaching_experience_months === "0"
                                ? "-"
                                : item?.teacher
                                    ?.total_teaching_experience_years !== "0"
                                ? item?.teacher
                                    ?.total_teaching_experience_years +
                                  t("Years")
                                : ""}
                            </td>
                            <td>
                              {item?.teacher?.highest_education_degree
                                ?.degree_level?.name || "-"}
                            </td>
                            <td>
                              {item?.teacher?.highest_education_degree?.title ||
                                "-"}
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              )}
            </div>
          )}
        </Fragment>
      )}
    </>
  );
};

export default BestCandidate;

const BestCandidateModal = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllJobTitles(lng === "arab" ? 1 : 2));
  }, []);
  const { allJobTitles } = useSelector((state) => state?.allJobTitles);
  const reviewApplicants = () => {
    props?.setApplicantPopUp(true);
    props.onHide();
  };
  return (
    <Modal {...props} centered size="md" className="fade">
      <Modal.Header>
        <h3>{t("Welcome To Madares AI")}</h3>
      </Modal.Header>
      <Modal.Body className="p-5" style={{ backgroundColor: "#fff" }}>
        <h4>{t("Please select a job to find the best candidates")}</h4>
        <div className="row">
          <select
            value={props?.jobTitle}
            name="degree_level_id"
            onChange={(e) => props?.setJobTitle(e.target.value)}
            className="form-control solid"
          >
            <option value="" disabled>
              {t("Select")}
            </option>
            {allJobTitles?.map((item, i) => (
              <option key={i} value={item?.id}>
                {item?.title}
              </option>
            ))}
          </select>
        </div>
        {props?.jobTitle ? (
          <div className="row">
            <div
              style={{ display: "flex" }}
              className="col-xl-6  col-md-6 mb-4"
            ></div>
            <div className="card-footer text-end">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  className="btn-2 semi-bold-white"
                  onClick={reviewApplicants}
                >
                  {t("Review Applicants")}
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};
