import { FETCH_GENDER, GENDER_LOAD, GENDER_LOADED } from "../actions/Constants";
import { gender } from "../../services/API";
import { toastAlert } from "../../jsx/layouts/toastAlert/toastAlert";
import checkErr from "./api_error_func";

export const getGender = (lang) => async (dispatch) => { 
    await dispatch({ type: GENDER_LOAD });
    try {
      const { data } = await gender(lang);
      await dispatch({ type: FETCH_GENDER, data: data.data });
    } catch (error) {
      dispatch({ type: GENDER_LOADED });
      checkErr(error, false, toastAlert, false, dispatch);
    }
}