import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { applicantStar, getStars } from "../../../../store/actions/applicants";
import Loading from "../../../layouts/loadingComponents/Loading";
import "../homePage/HomePage.css";

let lng = JSON.parse(localStorage.getItem("language"))?.lang;

const DatabaseAccess = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [star, setStar] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [numbersOfPages, setNumbersOfPages] = useState([]);

  useEffect(() => {
    if (star) {
      dispatch(getStars(lng === "arab" ? 1 : 2, 1));
    } else {
      dispatch(getStars(lng === "arab" ? 1 : 2, 2));
    }
  }, [star]);

  const handleStar = (id) => {
    dispatch(applicantStar(id));
    if (star) {
      dispatch(getStars(lng === "arab" ? 1 : 2, 1));
    } else {
      dispatch(getStars(lng === "arab" ? 1 : 2, 2));
    }
  };
  const { stars, currentPage, nextPage, lastPage, perPage, total, loading } =
    useSelector((state) => state?.stars);
  useEffect(() => {
    let array = [];
    let pages = lastPage;
    while (pages > 0) {
      array.push(pages);
      pages--;
    }
    setNumbersOfPages(array.reverse());
  }, [lastPage]);

  useEffect(() => {
    console.log("stars", stars);
  }, [stars]);

  return (
    <>
      <div className="home-page container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className="mb-4 flex-wrap"
        >
          <div style={{ gap: "10px" }} className="d-md-flex pe-0 manageJobs">
            <button
              style={
                star === false
                  ? {
                      borderBottom: "2px solid #008C77",
                      color: "black",
                      fontSize: "15px",
                      fontWeight: 600,
                      fontStyle: "normal",
                    }
                  : {
                      color: "#707070",
                      fontSize: "15px",
                      fontWeight: 600,
                      fontStyle: "normal",
                    }
              }
              onClick={() => setStar(false)}
            >
              {t("All")}
            </button>
            <button
              style={
                star === true
                  ? {
                      borderBottom: "2px solid #008C77",
                      color: "black",
                      fontSize: "15px",
                      fontWeight: 600,
                      fontStyle: "normal",
                    }
                  : {
                      color: "#707070",
                      fontSize: "15px",
                      fontWeight: 600,
                      fontStyle: "normal",
                    }
              }
              onClick={() => setStar(true)}
            >
              {t("Starred")}
            </button>
          </div>
        </div>
        <div>
          {"Displaying " +
            (perPage - currentPage * perPage) +
            "-" +
            (total > currentPage * perPage ? currentPage * perPage : total) +
            " of " +
            total}
        </div>
        {loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "306px",
            }}
          >
            <Loading />
          </div>
        ) : !loading && stars?.length === 0 ? (
          <div className="no-data-message">
            <h5
              style={{
                color: "black",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "15px",
              }}
            >
              {t("No Data Found!")}
            </h5>
          </div>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Citizenship</th>
                    <th>Exp</th>
                    <th>Highest Degree</th>
                    <th>Major</th>
                  </tr>
                </thead>
                <tbody>
                  {stars?.map((item, index) => (
                    <>
                      <tr>
                        <td>
                          {item?.is_starred ? (
                            <i
                              style={{
                                cursor: "pointer",
                                color: "#FFBF00",
                              }}
                              className="bi bi-star-fill fa-2x"
                              onClick={() => handleStar(item?.id)}
                            ></i>
                          ) : (
                            <i
                              style={{
                                cursor: "pointer",
                              }}
                              className="bi bi-star fa-2x"
                              onClick={() => handleStar(item?.id)}
                            ></i>
                          )}
                        </td>
                        <td
                          className="applicant-name"
                          onClick={() => history.push(`applicant-profile?id=${item?.id}`)}
                        >
                          {item?.first_name + " " + item?.last_name}
                        </td>
                        <td>
                          {item?.citizenships?.slice(0, 1)?.shift()?.name ||
                            "-"}
                        </td>
                        <td>
                          {item?.total_teaching_experience_years === "0" &&
                          item?.total_teaching_experience_months === "0"
                            ? "-"
                            : (item?.total_teaching_experience_years !== "0"
                                ? item?.total_teaching_experience_years +
                                  t("Years")
                                : "") +
                              (item?.total_teaching_experience_years !== "0" &&
                              item?.total_teaching_experience_months !== "0"
                                ? t("and")
                                : "") +
                              (item?.total_teaching_experience_months !== "0"
                                ? item?.total_teaching_experience_months +
                                  t("Months")
                                : "")}
                        </td>
                        <td>
                          {item?.highest_education_degree?.degree_level?.name ||
                            "-"}
                        </td>
                        <td>{item?.highest_education_degree?.title || "-"}</td>
                      </tr>
                    </>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="table-responsive">
              <div id="example_wrapper" className="dataTables_wrapper">
                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                  <div className="dataTables_info"></div>
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="example5_paginate"
                  >
                    <Link
                      onClick={() =>
                        pageNumber === 1 ? null : setPageNumber(currentPage - 1)
                      }
                      className={
                        pageNumber === 1
                          ? "paginate_button previous disabled"
                          : "paginate_button previous"
                      }
                      to="#"
                    >
                      {lng === "arab" ? (
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-angle-double-left"
                          aria-hidden="true"
                        ></i>
                      )}
                    </Link>
                    <span>
                      {numbersOfPages.map((number, i) => (
                        <Link
                          key={i}
                          to="#"
                          className={
                            pageNumber === number
                              ? "paginate_button active"
                              : "paginate_button"
                          }
                          onClick={() =>
                            pageNumber === number ? null : setPageNumber(number)
                          }
                        >
                          {number}
                        </Link>
                      ))}
                    </span>

                    <Link
                      onClick={() =>
                        nextPage === 1 ? null : setPageNumber(nextPage)
                      }
                      className={
                        nextPage === 1
                          ? "paginate_button next disabled"
                          : "paginate_button next"
                      }
                      to="#"
                    >
                      {lng === "arab" ? (
                        <i
                          className="fa fa-angle-double-left"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      )}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DatabaseAccess;
