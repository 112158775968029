import { FETCH_DOCUMENT, DOCUMENT_LOAD, DOCUMENT_LOADED } from '../actions/Constants'

export default (documentTypes = { documentTypes: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_DOCUMENT:
            return { ...documentTypes, documentTypes: action.data, loading: false };
        case DOCUMENT_LOAD:
            return { ...documentTypes, loading: true };
        case DOCUMENT_LOADED:
            return { ...documentTypes, loading: false };
        default:
            return documentTypes;
    }
}