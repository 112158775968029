import {combineReducers} from 'redux';
import userDetails from './User.js'
import newSubjects from './jobs/newSubjects';
import grades from './jobs/grades.js';
import tempoJob from './jobs/tempoJob.js';
import jobTitles from './jobs/jobTitles.js';
import degrees from './jobs/degrees.js';
import requiredCertification from './jobs/requiredCertification.js';
import citizenships from './jobs/citizenships.js';
import drafts from './jobs/drafts.js';
import academyJobs from './jobs/academyJobs'
import jobStatus from './jobs/jobStatus.js';
import savedJobs from './jobs/savedJobs';
import teacherApplicantions from './teacherApplicantions';
import academyApplicants from './academyApplicants';
import allJobTitles from './jobs/allJobTitles.js';
import applicantsStatus from './jobs/applicationStatus'
import languages from './jobs/languages.js';
import curriculums from './jobs/curriculums.js';
import academyInfo from './academyInfo';
import academyType from './academyType';
import teacherInfo from './teacherInfo';
import gender from "./gender";
import licenseTypes from "./licenseTypes";
import documentTypes from "./documentTypes";
import bestCandidates from "./bestCandidates";
import statistics from "./statistics";
import notifications from "./notifications";
import stars from "./star"
import suggested from "./suggested"
import industry from "./industry"
import teacherInfoByAcademy from "./teacherInfoByAcademy"

const rootReducers = combineReducers({
	userDetails,
	newSubjects,
	grades,
	tempoJob,
	jobTitles,
	degrees,
	requiredCertification,
	citizenships,
	drafts,
	academyJobs,
	jobStatus,
	savedJobs,
	teacherApplicantions,
	academyApplicants,
	allJobTitles,
	applicantsStatus,
	languages,
	curriculums,
	academyInfo,
	academyType,
	teacherInfo,
	gender,
	licenseTypes,
	documentTypes,
	bestCandidates,
	statistics,
	notifications,
	stars,
	suggested,
	industry,
	teacherInfoByAcademy,
})

export default rootReducers;