import { CURRICULUMS, LOADING_CURRICULUMS, LOADED_CURRICULUMS } from "../../actions/Constants";

export default (curriculums = { curriculums: [], loading: false }, action) => {
    switch (action.type) {
        case CURRICULUMS:
            return { ...curriculums, curriculums: action.data, loading: false };
        case LOADING_CURRICULUMS:
            return { ...curriculums, loading: true };
        case LOADED_CURRICULUMS:
            return { ...curriculums, loading: false };
        default:
            return curriculums;
    }
}