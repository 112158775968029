import { t } from "i18next";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addAcademyFile,
  deleteAcademyAvatar,
  deleteAcademyBanner,
  deleteAcademyFile,
  editAcademyGeneralProfile,
  getAcademyInfo,
  getAcademyType,
} from "../../../../store/actions/applicants";
import Loading from "../../../layouts/loadingComponents/Loading";
import { Country, State, City } from "country-state-city";
import "react-phone-number-input/style.css";
import Select from "react-select";

import "./MyProfile.css";
import PhoneInput from "react-phone-number-input";
import { toastAlert } from "../../../layouts/toastAlert/toastAlert";
import FileViewer from "react-file-viewer";
import { useDropzone } from "react-dropzone";
import ManyFilesPicker from "../../Common/ManyFilesPicker/index";
import { Dialog, DialogContent } from "@mui/material";
import SignleFilePicker from "../../Common/SignleFilePicker";
import saveAs from "save-as";

let lng = JSON.parse(localStorage.getItem("language"))?.lang;
let city;

const NewMyProfile = () => {
  const { academyInfo } = useSelector((state) => state?.academyInfo);
  const academyInfoLoading = useSelector((state) => state?.academyInfo.loading);
  const { academyType } = useSelector((state) => state?.academyType);
  const academyTypeLoading = useSelector((state) => state?.academyType.loading);
  const [loadingModalShow, setLoadingModalShow] = useState(true);
  const [personalInfo, setPersonalInfo] = useState(false);
  const [aboutYou, setAboutYou] = useState(false);
  const [document, setDocument] = useState(false);
  const [viewDocument, setViewDocument] = useState(false);
  const [viewDocumentData, setViewDocumentData] = useState();
  const [profileState, setProfileState] = useState("");
  const [forceLoad, setForceLoad] = useState(0);
  const [logoFile, setLogoFile] = useState("");
  const [bannerFile, setBannerFile] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAcademyInfo(lng === "arab" ? 1 : 2));
  }, [forceLoad]);

  useEffect(() => {
    dispatch(getAcademyType(lng === "arab" ? 1 : 2));
  }, []);

  useEffect(() => {
    setLoadingModalShow(academyInfoLoading || academyTypeLoading);
  }, [academyInfoLoading, academyTypeLoading]);

  const [academyData, setAcademyData] = useState({
    name: academyInfo?.name || "",
    website: academyInfo?.website || "",
    street: academyInfo?.location?.street || "",
    contact_number: academyInfo?.contact_number || "",
    country: academyInfo?.location?.country || "Saudi Arabia",
    state: academyInfo?.location?.state || "",
    school_type: academyInfo?.school_type || "Private",
    bio: academyInfo?.bio || "",
    avatar: academyInfo?.avatar || "",
    banner: academyInfo?.banner || "",
    international_accreditation_id:
      academyInfo?.international_accreditation_id || "2",
    international_accrediting_bodies:
      academyInfo?.international_accrediting_bodies || "",
    is_recognised: academyInfo?.is_recognised || "1",
    specialization: academyInfo?.specialization || "",
    types:
      academyInfo?.types?.map((item) => {
        return { value: item.id, label: item.name };
      }) || [],
    city: academyInfo?.location?.city || "",
  });

  useEffect(() => {
    if (academyInfo?.location?.state) {
      handleState(academyInfo?.location?.state);
    }
  }, [academyInfo?.location?.state]);

  useEffect(() => {
    console.log(academyInfo)
    setAcademyData({
      name: academyInfo?.name || "",
      website: academyInfo?.website || "",
      street: academyInfo?.location?.street || "",
      contact_number: academyInfo?.contact_number || "",
      country: academyInfo?.location?.country || "Saudi Arabia",
      state: academyInfo?.location?.state || "",
      school_type: academyInfo?.school_type || "Private",
      bio: academyInfo?.bio || "",
      avatar: academyInfo?.avatar || "",
      banner: academyInfo?.banner || "",
      international_accreditation_id:
        academyInfo?.international_accreditation_id || "2",
      international_accrediting_bodies:
        academyInfo?.international_accrediting_bodies || "",
      is_recognised: academyInfo?.is_recognised || "1",
      specialization: academyInfo?.specialization || "",
      types:
        academyInfo?.types?.map((item) => {
          return { value: item?.id, label: item?.name };
        }) || [],
      city: academyInfo?.location?.city || "",
    });
    setLoadingModalShow(false);
  }, [academyInfo?.avatar]);

  useEffect(() => {
    setAcademyData({
      name: academyInfo?.name || "",
      website: academyInfo?.website || "",
      street: academyInfo?.location?.street || "",
      contact_number: academyInfo?.contact_number || "",
      country: academyInfo?.location?.country || "Saudi Arabia",
      state: academyInfo?.location?.state || "",
      school_type: academyInfo?.school_type || "Private",
      bio: academyInfo?.bio || "",
      avatar: academyInfo?.avatar || "",
      banner: academyInfo?.banner || "",
      international_accreditation_id:
        academyInfo?.international_accreditation_id || "2",
      international_accrediting_bodies:
        academyInfo?.international_accrediting_bodies || "",
      is_recognised: academyInfo?.is_recognised || "1",
      specialization: academyInfo?.specialization || "",
      types:
        academyInfo?.types?.map((item) => {
          return { value: item?.id, label: item?.name };
        }) || [],
      city: academyInfo?.location?.city || "",
    });
    setLoadingModalShow(false);
  }, [academyInfo?.banner]);

  const handlePersonalInfoChange = (e) => {
    setAcademyData({ ...academyData, [e.target.name]: e.target.value });
  };

  const handleAboutYouChange = (e) => {
    setAcademyData({ ...academyData, [e.target.name]: e.target.value });
  };

  const handleState = (state) => {
    setAcademyData((prev) => ({ ...prev, state }));
    const code = State.getStatesOfCountry("SA").find(
      (x) => x.name === state
    ).isoCode;
    city = City.getCitiesOfState("SA", code);
  };

  const handleRemove = () => {
    setLoadingModalShow(true);
    dispatch(deleteAcademyAvatar(setLoadingModalShow, setForceLoad, forceLoad));
  };

  const handleRemoveBanner = () => {
    setLoadingModalShow(true);
    dispatch(deleteAcademyBanner(setLoadingModalShow, setForceLoad, forceLoad));
  };

  const handleFile = (file, type) => {
    type === "LOGO" ? setLogoFile(file) : setBannerFile(file);
  };

  const submitForm = () => {
    setLoadingModalShow(true);
    let userData = {
      ...academyData,
      avatar: logoFile,
      banner: bannerFile,
    };
    console.log("userData", userData);
    let errorArr = [];
    if (!userData.name) {
      errorArr.push(t("Academy Name is required"));
    }
    if (!userData.bio) {
      errorArr.push(t("Academy description is required"));
    }
    if (!userData.international_accreditation_id) {
      errorArr.push(t("International Accreditation status required"));
    }
    if (
      !userData.international_accrediting_bodies &&
      userData.international_accreditation_id == "1"
    ) {
      errorArr.push(t("International Accreditation bodies required"));
    }
    if (!userData.specialization) {
      errorArr.push(t("Specialize in is required"));
    }
    if (!userData.is_recognised) {
      errorArr.push(t("is recognised in is required"));
    }
    if (!userData.contact_number) {
      errorArr.push(t("contact number in is required"));
    }
    if (!userData.school_type) {
      errorArr.push(t("school type in is required"));
    }
    if (!userData.street) {
      errorArr.push(t("street in is required"));
    }
    if (!userData.city) {
      errorArr.push(t("city in is required"));
    }
    if (!userData.state) {
      errorArr.push(t("state in is required"));
    }
    if (userData?.types?.length === 0 || userData?.types === null) {
      errorArr.push(t("Type is required"));
    }
    if (errorArr.length > 0) {
      toastAlert(errorArr, undefined, "warning");
      setLoadingModalShow(false);
      return false;
    }
    dispatch(
      editAcademyGeneralProfile(
        userData,
        setLoadingModalShow,
        setForceLoad,
        forceLoad
      )
    );
  };

  const handleDeleteDocument = (id) => {
    dispatch(
      deleteAcademyFile(id, setLoadingModalShow, forceLoad, setForceLoad)
    );
  };

  async function handleViewDocument(item) {
    console.log('item', item)
      fetch(
        `https://api.madaresweb.mvp-apps.ae/api/viewFile?path=${item?.file_url}&type=AF`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("sess")}` },
          method: "GET",
        }
      )
      .then((response) => response.blob())
      .then((blob) => window.open(URL.createObjectURL(blob), "_blank"));
  };

  const submitDocuments = (documents) => {
    setLoadingModalShow(true);
    let userData = {
      documents: documents,
    };
    if (userData?.documents?.length === 0) {
      toastAlert("Document is required", undefined, "warning");
      setLoadingModalShow(false);
      return false;
    }
    dispatch(
      addAcademyFile(userData, setLoadingModalShow, setForceLoad, forceLoad)
    );
  };

  return (
    <Fragment>
      {loadingModalShow ? (
        <div
          style={{
            minHeight: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <Fragment>
          {/* <div className="my-profile"> */}
          <div>
            <div className="my-profile-title">My Profile</div>
            <div className="my-profile-main row">
              <div className="col-lg-4 col-md-12 col-sm-12 mb-5">
                <div className="my-profile-card">
                  <div className="my-profile-card-group-1">
                    {academyInfo?.avatar ? (
                      <img
                        src={academyInfo?.avatar}
                        style={{
                          width: "76px",
                          height: "76px",
                          borderRadius: "38px",
                        }}
                        alt="avatar"
                      />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="76"
                        height="76"
                        viewBox="0 0 76 76"
                      >
                        <defs>
                          <linearGradient
                            id="linear-gradient"
                            x1="0.5"
                            y1="1"
                            x2="0.5"
                            gradientUnits="objectBoundingBox"
                          >
                            <stop offset="0" stopColor="#008c77" />
                            <stop offset="1" stopColor="#04c4a7" />
                          </linearGradient>
                          <linearGradient
                            id="linear-gradient-2"
                            x1="0.5"
                            y1="1"
                            x2="0.5"
                            gradientUnits="objectBoundingBox"
                          >
                            <stop offset="0" stopColor="#addcff" />
                            <stop offset="0.503" stopColor="#eaf6ff" />
                            <stop offset="1" stopColor="#eaf6ff" />
                          </linearGradient>
                        </defs>
                        <g
                          id="user_5_"
                          data-name="user (5)"
                          transform="translate(0.55 0.719)"
                        >
                          <g
                            id="Group_1219"
                            data-name="Group 1219"
                            transform="translate(-0.579 -0.747)"
                          >
                            <g
                              id="Group_1218"
                              data-name="Group 1218"
                              transform="translate(0 0)"
                            >
                              <circle
                                id="Ellipse_6"
                                data-name="Ellipse 6"
                                cx="38"
                                cy="38"
                                r="38"
                                transform="translate(0.028 0.028)"
                                fill="url(#linear-gradient)"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_1221"
                            data-name="Group 1221"
                            transform="translate(17.635 13.243)"
                          >
                            <g id="Group_1220" data-name="Group 1220">
                              <path
                                id="Path_930"
                                data-name="Path 930"
                                d="M151.7,101.963a10.963,10.963,0,1,0-10.963,10.963A10.976,10.976,0,0,0,151.7,101.963Zm-10.963,10.963A19.756,19.756,0,0,0,121,132.66v2.055a2.192,2.192,0,0,0,.729,1.633,28.043,28.043,0,0,0,38.009,0,2.192,2.192,0,0,0,.729-1.633V132.66A19.756,19.756,0,0,0,140.734,112.926Z"
                                transform="translate(-121 -91)"
                                fill="url(#linear-gradient-2)"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    )}
                    <div style={{ top: 0, right: 0, position: "absolute" }}>
                      <svg
                        onClick={() => setPersonalInfo(true)}
                        xmlns="http://www.w3.org/2000/svg"
                        width="44"
                        height="44"
                        viewBox="0 0 44 44"
                      >
                        <defs>
                          <filter
                            id="Ellipse_7"
                            x="0"
                            y="0"
                            width="44"
                            height="44"
                            filterUnits="userSpaceOnUse"
                          >
                            <feOffset dy="3" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="3" result="blur" />
                            <feFlood floodOpacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                          </filter>
                        </defs>
                        <g id="edit" transform="translate(-1766.074 -229.074)">
                          <g
                            transform="matrix(1, 0, 0, 1, 1766.07, 229.07)"
                            filter="url(#Ellipse_7)"
                          >
                            <circle
                              id="Ellipse_7-2"
                              data-name="Ellipse 7"
                              cx="13"
                              cy="13"
                              r="13"
                              transform="translate(9 6)"
                              fill="#fff"
                            />
                          </g>
                          <path
                            id="Icon_material-edit"
                            data-name="Icon material-edit"
                            d="M4.5,15.3v2.842H7.342l8.381-8.381L12.881,6.916ZM17.921,7.56a.755.755,0,0,0,0-1.068L16.147,4.718a.755.755,0,0,0-1.068,0L13.692,6.1l2.842,2.842L17.921,7.56Z"
                            transform="translate(1776.753 236.756)"
                            fill="#fc563e"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className="my-profile-card-group-1-name">
                      {academyInfo?.name || "N/A"}
                    </div>
                    <div className="my-profile-card-group-1-specialize">
                      {"Specialize in " + academyInfo?.specialization || "N/A"}
                    </div>
                  </div>
                  <div className="my-profile-card-description">
                    {academyInfo?.bio || "N/A"}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 mb-5">
                <div className="my-profile-card">
                  <div className="my-profile-card-group-2">
                    <div className="my-profile-card-group-2-title">
                      About You
                    </div>
                    <svg
                      onClick={() => setAboutYou(true)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="44"
                      height="44"
                      viewBox="0 0 44 44"
                    >
                      <defs>
                        <filter
                          id="Ellipse_7"
                          x="0"
                          y="0"
                          width="44"
                          height="44"
                          filterUnits="userSpaceOnUse"
                        >
                          <feOffset dy="3" input="SourceAlpha" />
                          <feGaussianBlur stdDeviation="3" result="blur" />
                          <feFlood floodOpacity="0.161" />
                          <feComposite operator="in" in2="blur" />
                          <feComposite in="SourceGraphic" />
                        </filter>
                      </defs>
                      <g id="edit" transform="translate(9 6)">
                        <g
                          transform="matrix(1, 0, 0, 1, -9, -6)"
                          filter="url(#Ellipse_7)"
                        >
                          <circle
                            id="Ellipse_7-2"
                            data-name="Ellipse 7"
                            cx="13"
                            cy="13"
                            r="13"
                            transform="translate(9 6)"
                            fill="#fff"
                          />
                        </g>
                        <path
                          id="Icon_material-edit"
                          data-name="Icon material-edit"
                          d="M4.5,15.3v2.842H7.342l8.381-8.381L12.881,6.916ZM17.921,7.56a.755.755,0,0,0,0-1.068L16.147,4.718a.755.755,0,0,0-1.068,0L13.692,6.1l2.842,2.842L17.921,7.56Z"
                          transform="translate(1.679 1.683)"
                          fill="#fc563e"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="my-profile-card-group-2-of-2">
                    <div>
                      <div className="my-profile-card-group-2-of-2-label">
                        State
                      </div>
                      <div className="my-profile-card-group-2-of-2-value">
                        {academyInfo?.location?.state || "N/A"}
                      </div>
                    </div>
                    <div>
                      <div className="my-profile-card-group-2-of-2-label">
                        City
                      </div>
                      <div className="my-profile-card-group-2-of-2-value">
                        {academyInfo?.location?.city || "N/A"}
                      </div>
                    </div>

                    <div>
                      <div className="my-profile-card-group-2-of-2-label">
                        Contact
                      </div>
                      <div className="my-profile-card-group-2-of-2-value">
                        {academyInfo?.contact_number || "N/A"}
                      </div>
                    </div>
                  </div>
                  <div className="my-profile-card-group-2-of-2">
                    <div>
                      <div className="my-profile-card-group-2-of-2-label">
                        Address
                      </div>
                      <div className="my-profile-card-group-2-of-2-value">
                        {academyInfo?.location?.street || "N/A"}
                      </div>
                    </div>
                  </div>
                  <div className="my-profile-card-group-2-of-2">
                    <div>
                      <div className="my-profile-card-group-2-of-2-label">
                        Website
                      </div>
                      <div className="my-profile-card-group-2-of-2-value">
                        {academyInfo?.website || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 mb-5">
                <div className="my-profile-card">
                  <div className="my-profile-card-group-3">
                    <div className="my-profile-card-group-3-title">
                      Documents
                    </div>
                    <svg
                      onClick={() => setDocument(true)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="44"
                      height="44"
                      viewBox="0 0 44 44"
                    >
                      <defs>
                        <filter
                          id="Ellipse_7"
                          x="0"
                          y="0"
                          width="44"
                          height="44"
                          filterUnits="userSpaceOnUse"
                        >
                          <feOffset dy="3" input="SourceAlpha" />
                          <feGaussianBlur stdDeviation="3" result="blur" />
                          <feFlood floodOpacity="0.161" />
                          <feComposite operator="in" in2="blur" />
                          <feComposite in="SourceGraphic" />
                        </filter>
                      </defs>
                      <g id="edit" transform="translate(9 6)">
                        <g
                          transform="matrix(1, 0, 0, 1, -9, -6)"
                          filter="url(#Ellipse_7)"
                        >
                          <circle
                            id="Ellipse_7-2"
                            data-name="Ellipse 7"
                            cx="13"
                            cy="13"
                            r="13"
                            transform="translate(9 6)"
                            fill="#fff"
                          />
                        </g>
                        <g
                          id="Icon_feather-plus"
                          data-name="Icon feather-plus"
                          transform="translate(-1.321 -1.321)"
                        >
                          <path
                            id="Path_972"
                            data-name="Path 972"
                            d="M18,7.5V21.142"
                            transform="translate(-3.679)"
                            fill="none"
                            stroke="#fc563e"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                          />
                          <path
                            id="Path_973"
                            data-name="Path 973"
                            d="M7.5,18H21.142"
                            transform="translate(0 -3.679)"
                            fill="none"
                            stroke="#fc563e"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  {academyInfo?.academy_files?.length === 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "200px",
                      }}
                    >
                      <h5>You Don't Have Any Document</h5>
                    </div>
                  ) : (
                    academyInfo?.academy_files?.map((item, index) => (
                      <div className="my-profile-card-group-3-of-2">
                        <div className="my-profile-card-group-3-of-2-label">
                          Document {index + 1}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            justifyContent: "space-between",
                          }}
                        >
                          <svg
                            onClick={() => handleViewDocument(item)}
                            xmlns="http://www.w3.org/2000/svg"
                            width="25.709"
                            height="17.139"
                            viewBox="0 0 25.709 17.139"
                          >
                            <path
                              id="Icon_awesome-eye"
                              data-name="Icon awesome-eye"
                              d="M25.554,12.418A14.316,14.316,0,0,0,12.855,4.5a14.318,14.318,0,0,0-12.7,7.918,1.444,1.444,0,0,0,0,1.3,14.316,14.316,0,0,0,12.7,7.918,14.318,14.318,0,0,0,12.7-7.918A1.444,1.444,0,0,0,25.554,12.418ZM12.855,19.5a6.427,6.427,0,1,1,6.427-6.427A6.427,6.427,0,0,1,12.855,19.5Zm0-10.712a4.254,4.254,0,0,0-1.13.169A2.136,2.136,0,0,1,8.739,11.94a4.275,4.275,0,1,0,4.116-3.155Z"
                              transform="translate(0 -4.5)"
                              fill="#008c77"
                            />
                          </svg>
                          <svg
                            onClick={() => handleDeleteDocument(item?.id)}
                            xmlns="http://www.w3.org/2000/svg"
                            width="23.924"
                            height="19.139"
                            viewBox="0 0 23.924 19.139"
                          >
                            <path
                              id="Icon_material-delete-sweep"
                              data-name="Icon material-delete-sweep"
                              d="M18.551,20.355h4.785v2.392H18.551Zm0-9.57h8.373v2.392H18.551Zm0,4.785h7.177v2.392H18.551ZM4.2,22.747a2.4,2.4,0,0,0,2.392,2.392h7.177a2.4,2.4,0,0,0,2.392-2.392V10.785H4.2ZM17.355,7.2H13.766L12.57,6H7.785l-1.2,1.2H3V9.589H17.355Z"
                              transform="translate(-3 -6)"
                              fill="#fc3e3e"
                            />
                          </svg>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>

          <EditPersonalInfo
            show={personalInfo}
            onHide={() => setPersonalInfo(false)}
            data={academyData}
            handlePersonalInfoChange={handlePersonalInfoChange}
            submitForm={submitForm}
            handleRemove={handleRemove}
            handleFile={handleFile}
          />

          <EditAboutYouInfo
            show={aboutYou}
            onHide={() => setAboutYou(false)}
            data={academyData}
            handleAboutYouChange={handleAboutYouChange}
            submitForm={submitForm}
            handleRemoveBanner={handleRemoveBanner}
            handleFile={handleFile}
            handleState={handleState}
            academyType={academyType}
          />

          {/* {viewDocument ?
            <FileViewer fileType={"pdf"} filePath={"Resume.pdf"} />
            : null
          } */}

          <ViewDocument
            show={viewDocument}
            onHide={() => setViewDocument(false)}
            data={viewDocumentData}
          />

          <AddDocument
            show={document}
            onHide={() => setDocument(false)}
            submitDocuments={submitDocuments}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default NewMyProfile;

const EditPersonalInfo = ({
  show,
  onHide,
  data,
  handlePersonalInfoChange,
  submitForm,
  handleRemove,
  handleFile,
}) => {
  const [avatarFile, setAvatarFile] = useState();
  let internationalAccreditation = [
    {
      id: "1",
      name: lng === "arab" ? "نحن مدرسة معتمدة" : "We are an accredited school",
    },
    {
      id: "2",
      name:
        lng === "arab"
          ? "نحن في طور الاعتماد"
          : "We are in the process of being accredited",
    },
    {
      id: "3",
      name:
        lng === "arab"
          ? "نحن لسنا مدرسة معتمدة"
          : "We are not an accredited school",
    },
  ];

  const handleSubmit = () => {
    submitForm();
    onHide();
  };

  const handleRemoveAvatar = () => {
    handleRemove();
    onHide();
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        toastAlert(t("File uploaded successfuly"), undefined, "success");
        handleFile(acceptedFiles[0], "LOGO");
        if (setAvatarFile) {
          setAvatarFile(reader.result);
        }
      };
      reader.onerror = function (error) {
        toastAlert(t("Error while uploading files"), undefined, "warning");
      };
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  console.log("data", data);
  return (
    <Fragment>
      <Modal show={show} onHide={onHide} centered size="xl">
        <div
          className="edit-personal-info"
          style={{
            boxShadow: "0px 3px 6px #00000029",
            backgroundColor: "rgba(255, 255, 255, 1)",
            borderRadius: "1.25rem",
          }}
        >
          <div className="edit-personal-info-group-1">
            <div className="edit-personal-info-group-1-title">
              Edit your introduction
            </div>
            <div>
              <svg
                onClick={() => onHide()}
                xmlns="http://www.w3.org/2000/svg"
                width="20.027"
                height="20.027"
                viewBox="0 0 20.027 20.027"
              >
                <g
                  id="Icon_feather-plus"
                  data-name="Icon feather-plus"
                  transform="translate(-17.792 10.014) rotate(-45)"
                >
                  <path
                    id="Path_972"
                    data-name="Path 972"
                    d="M18,7.5V31.823"
                    transform="translate(1.661)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_973"
                    data-name="Path 973"
                    d="M7.5,18H31.823"
                    transform="translate(0 1.661)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </g>
              </svg>
            </div>
          </div>

          <div className="edit-personal-info-group-1-of-1">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="edit-personal-info-group-1-of-1">
                {data?.avatar || avatarFile ? (
                  <img
                    src={avatarFile || data?.avatar}
                    alt="logo"
                    style={{
                      width: "76px",
                      height: "76px",
                      borderRadius: "38px",
                    }}
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="76"
                    height="76"
                    viewBox="0 0 76 76"
                  >
                    <defs>
                      <linearGradient
                        id="linear-gradient"
                        x1="0.5"
                        y1="1"
                        x2="0.5"
                        gradientUnits="objectBoundingBox"
                      >
                        <stop offset="0" stop-color="#008c77" />
                        <stop offset="1" stop-color="#04c4a7" />
                      </linearGradient>
                      <linearGradient
                        id="linear-gradient-2"
                        x1="0.5"
                        y1="1"
                        x2="0.5"
                        gradientUnits="objectBoundingBox"
                      >
                        <stop offset="0" stop-color="#addcff" />
                        <stop offset="0.503" stop-color="#eaf6ff" />
                        <stop offset="1" stop-color="#eaf6ff" />
                      </linearGradient>
                    </defs>
                    <g
                      id="user_5_"
                      data-name="user (5)"
                      transform="translate(0.55 0.719)"
                    >
                      <g
                        id="Group_1219"
                        data-name="Group 1219"
                        transform="translate(-0.579 -0.747)"
                      >
                        <g
                          id="Group_1218"
                          data-name="Group 1218"
                          transform="translate(0 0)"
                        >
                          <circle
                            id="Ellipse_6"
                            data-name="Ellipse 6"
                            cx="38"
                            cy="38"
                            r="38"
                            transform="translate(0.028 0.028)"
                            fill="url(#linear-gradient)"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_1221"
                        data-name="Group 1221"
                        transform="translate(17.635 13.243)"
                      >
                        <g id="Group_1220" data-name="Group 1220">
                          <path
                            id="Path_930"
                            data-name="Path 930"
                            d="M151.7,101.963a10.963,10.963,0,1,0-10.963,10.963A10.976,10.976,0,0,0,151.7,101.963Zm-10.963,10.963A19.756,19.756,0,0,0,121,132.66v2.055a2.192,2.192,0,0,0,.729,1.633,28.043,28.043,0,0,0,38.009,0,2.192,2.192,0,0,0,.729-1.633V132.66A19.756,19.756,0,0,0,140.734,112.926Z"
                            transform="translate(-121 -91)"
                            fill="url(#linear-gradient-2)"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                )}
                <svg
                  style={{
                    position: "absolute",
                    top: 0,
                    marginRight: "60px",
                    cursor: "pointer",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="44"
                  height="44"
                  viewBox="0 0 44 44"
                >
                  <defs>
                    <filter
                      id="Ellipse_7"
                      x="0"
                      y="0"
                      width="44"
                      height="44"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset dy="3" input="SourceAlpha" />
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood floodOpacity="0.161" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g id="edit" transform="translate(9 6)">
                    <g
                      transform="matrix(1, 0, 0, 1, -9, -6)"
                      filter="url(#Ellipse_7)"
                    >
                      <circle
                        id="Ellipse_7-2"
                        data-name="Ellipse 7"
                        cx="13"
                        cy="13"
                        r="13"
                        transform="translate(9 6)"
                        fill="#fff"
                      />
                    </g>
                    <g
                      id="Icon_feather-upload"
                      data-name="Icon feather-upload"
                      transform="translate(2.5 2.5)"
                    >
                      <path
                        id="Path_969"
                        data-name="Path 969"
                        d="M15.591,22.5v2.465A1.232,1.232,0,0,1,14.358,26.2H5.732A1.232,1.232,0,0,1,4.5,24.965V22.5"
                        transform="translate(0 -10.606)"
                        fill="none"
                        stroke="#fc563e"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_970"
                        data-name="Path 970"
                        d="M16.661,7.581,13.581,4.5,10.5,7.581"
                        transform="translate(-3.535)"
                        fill="none"
                        stroke="#fc563e"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_971"
                        data-name="Path 971"
                        d="M18,4.5v7.394"
                        transform="translate(-7.955)"
                        fill="none"
                        stroke="#fc563e"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div
              className="edit-personal-info-group-1-of-2"
              onClick={() => handleRemoveAvatar()}
            >
              <div className="edit-personal-info-group-1-of-2-label">
                Remove
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.978"
                height="10.383"
                viewBox="0 0 12.978 10.383"
              >
                <path
                  id="Icon_material-delete-sweep"
                  data-name="Icon material-delete-sweep"
                  d="M11.436,13.787h2.6v1.3h-2.6Zm0-5.191h4.542v1.3H11.436Zm0,2.6h3.893v1.3H11.436ZM3.649,15.085a1.3,1.3,0,0,0,1.3,1.3H8.84a1.3,1.3,0,0,0,1.3-1.3V8.6H3.649Zm7.138-8.436H8.84L8.191,6H5.6l-.649.649H3v1.3h7.787Z"
                  transform="translate(-3 -6)"
                  fill="#fc3e3e"
                />
              </svg>
            </div>
          </div>

          <div className="edit-personal-info-group-2 row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="edit-personal-info-group-2-1">
                <div>
                  <div className="edit-personal-info-group-2-label">
                    Specialize in
                  </div>
                  <input
                    className="edit-personal-info-group-2-input-field"
                    type="text"
                    placeholder={t("English")}
                    value={data?.specialization}
                    onChange={(e) =>
                      handlePersonalInfoChange({
                        target: {
                          value: e.target.value,
                          name: "specialization",
                        },
                      })
                    }
                  />
                </div>
                <div>
                  <div className="edit-personal-info-group-2-label">
                    International Accreditation
                  </div>
                  <select
                    className="edit-personal-info-group-2-input-field-2"
                    value={data?.international_accreditation_id}
                    onChange={(e) =>
                      handlePersonalInfoChange({
                        target: {
                          value: e.target.value,
                          name: "international_accreditation_id",
                        },
                      })
                    }
                  >
                    {internationalAccreditation.map((item, i) => (
                      <option key={i} value={item?.id}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {data?.international_accreditation_id == "1" ? (
                <div className="edit-personal-info-group-2-2">
                  <div className="edit-personal-info-group-2-label">
                    Please list international accrediting bodies you are
                    accredited by
                  </div>
                  <input
                    className="edit-personal-info-group-2-2-input-field"
                    value={data?.international_accrediting_bodies}
                    onChange={(e) =>
                      handlePersonalInfoChange({
                        target: {
                          value: e.target.value,
                          name: "international_accrediting_bodies",
                        },
                      })
                    }
                  />
                </div>
              ) : null}
              <div className="edit-personal-info-group-2-3">
                <input
                  className="edit-personal-info-group-2-3-check-box"
                  type="checkbox"
                  checked={data?.is_recognised == "1"}
                  value={data?.is_recognised}
                  onChange={(e) =>
                    handlePersonalInfoChange({
                      target: {
                        value: data?.is_recognised == "0" ? "1" : "0",
                        name: "is_recognised",
                      },
                    })
                  }
                />
                <div>
                  We are a recognised educational institution by our Federal or
                  State/Provincial Government
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div className="edit-personal-info-group-2-responsive">
                  <div className="edit-personal-info-group-2-label">
                    Description
                  </div>
                  <textarea
                    rows={6}
                    className="edit-personal-info-group-2-text-area-field"
                    name="bio"
                    value={data?.bio}
                    onChange={handlePersonalInfoChange}
                  />
                </div>
                <div className="edit-personal-info-save-cancel-btn">
                  <button
                    className="edit-personal-info-cancel-btn"
                    onClick={() => onHide()}
                  >
                    Cancel
                  </button>
                  <button
                    className="edit-personal-info-save-btn"
                    onClick={() => handleSubmit()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

const EditAboutYouInfo = ({
  show,
  onHide,
  data,
  handleAboutYouChange,
  submitForm,
  handleRemoveBanner,
  handleFile,
  handleState,
  academyType,
}) => {
  let schoolTypes = [
    { id: "Private", name: lng === "arab" ? "خاصة" : "Private" },
    { id: "Public", name: lng === "arab" ? "عامة" : "Public" },
  ];

  const customStyles = {
    control: () => ({
      display: "flex",
      alignItems: "center",
      width: "100%",
      minWidth: "300px",
      minHeight: "43px",
      backgroundColor: "rgba(255, 255, 255, 1)",
      border: "0.6px solid",
      borderRadius: "10px",
      borderColor: "rgba(221, 221, 221, 1)",
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
  };

  const [bannerFile, setBannerFile] = useState();

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        toastAlert(t("File uploaded successfuly"), undefined, "success");
        handleFile(acceptedFiles[0], "BANNER");
        setBannerFile(reader.result);
      };
      reader.onerror = function (error) {
        toastAlert(t("Error while uploading files"), undefined, "warning");
      };
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const removeBanner = () => {
    handleRemoveBanner();
    onHide();
  };

  const handleSubmit = () => {
    submitForm();
    onHide();
  };
  return (
    <Fragment>
      <Modal show={show} onHide={onHide} centered size="xl">
        <div
          className="edit-about-you-info"
          style={{
            boxShadow: "0px 3px 6px #00000029",
            backgroundColor: "rgba(255, 255, 255, 1)",
            borderRadius: "1.25rem",
          }}
        >
          <div className="edit-about-you-info-group-1">
            <div className="edit-about-you-info-group-1-title">
              Edit About You
            </div>
            <div>
              <svg
                onClick={() => onHide()}
                xmlns="http://www.w3.org/2000/svg"
                width="20.027"
                height="20.027"
                viewBox="0 0 20.027 20.027"
              >
                <g
                  id="Icon_feather-plus"
                  data-name="Icon feather-plus"
                  transform="translate(-17.792 10.014) rotate(-45)"
                >
                  <path
                    id="Path_972"
                    data-name="Path 972"
                    d="M18,7.5V31.823"
                    transform="translate(1.661)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_973"
                    data-name="Path 973"
                    d="M7.5,18H31.823"
                    transform="translate(0 1.661)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </g>
              </svg>
            </div>
          </div>

          {/* <div className="edit-about-you-info-group-1-of-1">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="edit-about-you-info-group-2-label">
                Upload banner
              </div>
              <div className="edit-about-you-info-group-1-of-2">
                {data?.banner || bannerFile ? (
                  <img
                    src={bannerFile || data?.banner}
                    alt="banner"
                    style={{
                      width: "76px",
                      height: "76px",
                      borderRadius: "38px",
                    }}
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="76"
                    height="76"
                    viewBox="0 0 76 76"
                  >
                    <defs>
                      <linearGradient
                        id="linear-gradient"
                        x1="0.5"
                        y1="1"
                        x2="0.5"
                        gradientUnits="objectBoundingBox"
                      >
                        <stop offset="0" stop-color="#008c77" />
                        <stop offset="1" stop-color="#04c4a7" />
                      </linearGradient>
                      <linearGradient
                        id="linear-gradient-2"
                        x1="0.5"
                        y1="1"
                        x2="0.5"
                        gradientUnits="objectBoundingBox"
                      >
                        <stop offset="0" stop-color="#addcff" />
                        <stop offset="0.503" stop-color="#eaf6ff" />
                        <stop offset="1" stop-color="#eaf6ff" />
                      </linearGradient>
                    </defs>
                    <g
                      id="user_5_"
                      data-name="user (5)"
                      transform="translate(0.55 0.719)"
                    >
                      <g
                        id="Group_1219"
                        data-name="Group 1219"
                        transform="translate(-0.579 -0.747)"
                      >
                        <g
                          id="Group_1218"
                          data-name="Group 1218"
                          transform="translate(0 0)"
                        >
                          <circle
                            id="Ellipse_6"
                            data-name="Ellipse 6"
                            cx="38"
                            cy="38"
                            r="38"
                            transform="translate(0.028 0.028)"
                            fill="url(#linear-gradient)"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_1221"
                        data-name="Group 1221"
                        transform="translate(17.635 13.243)"
                      >
                        <g id="Group_1220" data-name="Group 1220">
                          <path
                            id="Path_930"
                            data-name="Path 930"
                            d="M151.7,101.963a10.963,10.963,0,1,0-10.963,10.963A10.976,10.976,0,0,0,151.7,101.963Zm-10.963,10.963A19.756,19.756,0,0,0,121,132.66v2.055a2.192,2.192,0,0,0,.729,1.633,28.043,28.043,0,0,0,38.009,0,2.192,2.192,0,0,0,.729-1.633V132.66A19.756,19.756,0,0,0,140.734,112.926Z"
                            transform="translate(-121 -91)"
                            fill="url(#linear-gradient-2)"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                )}
                <svg
                  style={{
                    position: "absolute",
                    top: 0,
                    marginRight: "60px",
                    cursor: "pointer",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="44"
                  height="44"
                  viewBox="0 0 44 44"
                >
                  <defs>
                    <filter
                      id="Ellipse_7"
                      x="0"
                      y="0"
                      width="44"
                      height="44"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset dy="3" input="SourceAlpha" />
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood floodOpacity="0.161" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g id="edit" transform="translate(9 6)">
                    <g
                      transform="matrix(1, 0, 0, 1, -9, -6)"
                      filter="url(#Ellipse_7)"
                    >
                      <circle
                        id="Ellipse_7-2"
                        data-name="Ellipse 7"
                        cx="13"
                        cy="13"
                        r="13"
                        transform="translate(9 6)"
                        fill="#fff"
                      />
                    </g>
                    <g
                      id="Icon_feather-upload"
                      data-name="Icon feather-upload"
                      transform="translate(2.5 2.5)"
                    >
                      <path
                        id="Path_969"
                        data-name="Path 969"
                        d="M15.591,22.5v2.465A1.232,1.232,0,0,1,14.358,26.2H5.732A1.232,1.232,0,0,1,4.5,24.965V22.5"
                        transform="translate(0 -10.606)"
                        fill="none"
                        stroke="#fc563e"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_970"
                        data-name="Path 970"
                        d="M16.661,7.581,13.581,4.5,10.5,7.581"
                        transform="translate(-3.535)"
                        fill="none"
                        stroke="#fc563e"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_971"
                        data-name="Path 971"
                        d="M18,4.5v7.394"
                        transform="translate(-7.955)"
                        fill="none"
                        stroke="#fc563e"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>

            <div className="edit-about-you-info-group-1-of-3" onClick={() => removeBanner()}>
            <div className="edit-about-you-info-group-1-of-3-label">
                Remove
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.978"
                height="10.383"
                viewBox="0 0 12.978 10.383"
              >
                <path
                  id="Icon_material-delete-sweep"
                  data-name="Icon material-delete-sweep"
                  d="M11.436,13.787h2.6v1.3h-2.6Zm0-5.191h4.542v1.3H11.436Zm0,2.6h3.893v1.3H11.436ZM3.649,15.085a1.3,1.3,0,0,0,1.3,1.3H8.84a1.3,1.3,0,0,0,1.3-1.3V8.6H3.649Zm7.138-8.436H8.84L8.191,6H5.6l-.649.649H3v1.3h7.787Z"
                  transform="translate(-3 -6)"
                  fill="#fc3e3e"
                />
              </svg>
            </div>
          </div> */}

          <div
            className="edit-about-you-info-group-1-of-1"
            style={{ paddingTop: "15px" }}
          >
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="edit-about-you-info-group-2-label">
                Upload banner
              </div>
              <div style={{ cursor: "pointer" }} className="userProfile">
                {bannerFile || data?.banner ? (
                  <>
                  <img src={bannerFile || data?.banner} alt="upload_image" />
                  </>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="150"
                    height="150"
                    viewBox="0 0 44 44"
                  >
                    <defs>
                      <filter
                        id="Ellipse_7"
                        x="0"
                        y="0"
                        width="44"
                        height="44"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur" />
                        <feFlood floodOpacity="0.161" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                    </defs>
                    <g id="edit" transform="translate(9 6)">
                      <g
                        transform="matrix(1, 0, 0, 1, -9, -6)"
                        filter="url(#Ellipse_7)"
                      >
                        <circle
                          id="Ellipse_7-2"
                          data-name="Ellipse 7"
                          cx="13"
                          cy="13"
                          r="13"
                          transform="translate(9 6)"
                          fill="#fff"
                        />
                      </g>
                      <g
                        id="Icon_feather-upload"
                        data-name="Icon feather-upload"
                        transform="translate(2.5 2.5)"
                      >
                        <path
                          id="Path_969"
                          data-name="Path 969"
                          d="M15.591,22.5v2.465A1.232,1.232,0,0,1,14.358,26.2H5.732A1.232,1.232,0,0,1,4.5,24.965V22.5"
                          transform="translate(0 -10.606)"
                          fill="none"
                          stroke="#fc563e"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Path_970"
                          data-name="Path 970"
                          d="M16.661,7.581,13.581,4.5,10.5,7.581"
                          transform="translate(-3.535)"
                          fill="none"
                          stroke="#fc563e"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Path_971"
                          data-name="Path 971"
                          d="M18,4.5v7.394"
                          transform="translate(-7.955)"
                          fill="none"
                          stroke="#fc563e"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                      </g>
                    </g>
                  </svg>
                )}
                
              </div>
            </div>
            <div className="edit-about-you-info-group-1-of-3" onClick={() => removeBanner()}>
                <div className="edit-about-you-info-group-1-of-3-label">
                  Remove
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.978"
                  height="10.383"
                  viewBox="0 0 12.978 10.383"
                >
                  <path
                    id="Icon_material-delete-sweep"
                    data-name="Icon material-delete-sweep"
                    d="M11.436,13.787h2.6v1.3h-2.6Zm0-5.191h4.542v1.3H11.436Zm0,2.6h3.893v1.3H11.436ZM3.649,15.085a1.3,1.3,0,0,0,1.3,1.3H8.84a1.3,1.3,0,0,0,1.3-1.3V8.6H3.649Zm7.138-8.436H8.84L8.191,6H5.6l-.649.649H3v1.3h7.787Z"
                    transform="translate(-3 -6)"
                    fill="#fc3e3e"
                  />
                </svg>
              </div>
          </div>

          <div className="edit-about-you-info-group-2">
            <div>
              <div className="edit-about-you-info-group-2-label">State</div>
              <select
                className="edit-about-you-info-group-2-input-field"
                value={data?.state}
                onChange={(e) => handleState(e.target.value)}
              >
                {State.getStatesOfCountry("SA")?.map((item, i) => (
                  <option key={i} value={item?.name}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <div className="edit-about-you-info-group-2-label">City</div>
              <select
                className="edit-about-you-info-group-2-input-field"
                value={data?.city}
                onChange={(e) =>
                  handleAboutYouChange({
                    target: { value: e.target.value, name: "city" },
                  })
                }
              >
                {city?.map((item, i) => (
                  <option key={i} value={item?.name}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <div className="edit-about-you-info-group-2-label">Address</div>
              <input
                type="text"
                className="edit-about-you-info-group-2-input-field"
                value={data?.street}
                onChange={(e) =>
                  handleAboutYouChange({
                    target: { value: e.target.value, name: "street" },
                  })
                }
              />
            </div>
          </div>

          <div className="edit-about-you-info-group-2">
            <div>
              <div className="edit-about-you-info-group-2-label">Website</div>
              <input
                type="text"
                className="edit-about-you-info-group-2-input-field"
                value={data?.website}
                onChange={(e) =>
                  handleAboutYouChange({
                    target: { value: e.target.value, name: "website" },
                  })
                }
              />
            </div>
            <div>
              <div className="edit-about-you-info-group-2-label">
                Contact Number
              </div>
              <PhoneInput
                id="phone"
                className="edit-about-you-info-group-2-input-field"
                placeholder={t("Enter phone number")}
                value={data?.contact_number}
                onChange={(e) =>
                  handleAboutYouChange({
                    target: { value: e, name: "contact_number" },
                  })
                }
              />
            </div>
            <div>
              <div className="edit-about-you-info-group-2-label">
                Organization type
              </div>
              <select
                className="edit-about-you-info-group-2-input-field"
                value={data?.school_type}
                onChange={(e) =>
                  handleAboutYouChange({
                    target: { value: e.target.value, name: "school_type" },
                  })
                }
              >
                {schoolTypes.map((item, i) => (
                  <option key={i} value={item?.id}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="edit-about-you-info-group-2">
            <div>
              <div className="edit-about-you-info-group-2-label">Grades</div>
              <Select
                styles={customStyles}
                value={data?.types}
                onChange={(e) =>
                  handleAboutYouChange({
                    target: { value: e, name: "types" },
                  })
                }
                options={academyType?.map((item) => ({
                  label: item?.name,
                  value: item?.id,
                }))}
                isMulti
              />
            </div>
          </div>

          <div className="edit-about-you-info-group-2-cancel-save-btn">
            <button
              className="edit-about-you-info-group-2-cancel-btn"
              onClick={() => onHide()}
            >
              Cancel
            </button>
            <button
              className="edit-about-you-info-group-2-save-btn"
              onClick={() => handleSubmit()}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

const ViewDocument = ({ show, onHide, data }) => {
  console.log("modalData", data);
  
  return (
    <Fragment>
      <Dialog open={show} maxWidth="md" fullWidth onClose={() => onHide()}>
        <DialogContent>
          {/* <FileViewer fileType={"docx"} filePath={"Resume.docx"} /> */}
          <FileViewer 
              fileType={data?.type}
              filePath={data?.url}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

const AddDocument = ({ show, onHide, submitDocuments }) => {
  const [academyFiles, setAcademyFiles] = useState([]);
  const [academyFilesInfo, setAcademyFilesInfo] = useState([]);
  const handleSubmit = () => {
    submitDocuments(academyFiles);
    onHide();
  };
  return (
    <Fragment>
      <Modal show={show} onHide={onHide} centered size="xl">
        <div
          className="add-document"
          style={{
            boxShadow: "0px 3px 6px #00000029",
            backgroundColor: "rgba(255, 255, 255, 1)",
            borderRadius: "1.25rem",
          }}
        >
          <div className="add-document-group-1">
            <div className="add-document-group-1-title">Add Documents</div>
            <div>
              <svg
                onClick={() => onHide()}
                xmlns="http://www.w3.org/2000/svg"
                width="20.027"
                height="20.027"
                viewBox="0 0 20.027 20.027"
              >
                <g
                  id="Icon_feather-plus"
                  data-name="Icon feather-plus"
                  transform="translate(-17.792 10.014) rotate(-45)"
                >
                  <path
                    id="Path_972"
                    data-name="Path 972"
                    d="M18,7.5V31.823"
                    transform="translate(1.661)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_973"
                    data-name="Path 973"
                    d="M7.5,18H31.823"
                    transform="translate(0 1.661)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </g>
              </svg>
            </div>
          </div>

          <div className="add-document-group-2">
            <div className="add-document-group-2-label">
              <ul>
                <li>{t("Maximum Size 10 MB")}</li>
                <li>
                  {t("Recommended Files:- doc, docx, pdf, jpg, png, jpeg")}
                </li>
              </ul>
            </div>
            <ManyFilesPicker
              setAcademyFiles={setAcademyFiles}
              academyFiles={academyFiles}
              academyFilesInfo={academyFilesInfo}
              setAcademyFilesInfo={setAcademyFilesInfo}
            >
              <div style={{ cursor: "pointer" }} className="userProfile">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="150"
                  height="150"
                  viewBox="0 0 44 44"
                >
                  <defs>
                    <filter
                      id="Ellipse_7"
                      x="0"
                      y="0"
                      width="44"
                      height="44"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset dy="3" input="SourceAlpha" />
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood floodOpacity="0.161" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g id="edit" transform="translate(9 6)">
                    <g
                      transform="matrix(1, 0, 0, 1, -9, -6)"
                      filter="url(#Ellipse_7)"
                    >
                      <circle
                        id="Ellipse_7-2"
                        data-name="Ellipse 7"
                        cx="13"
                        cy="13"
                        r="13"
                        transform="translate(9 6)"
                        fill="#fff"
                      />
                    </g>
                    <g
                      id="Icon_feather-upload"
                      data-name="Icon feather-upload"
                      transform="translate(2.5 2.5)"
                    >
                      <path
                        id="Path_969"
                        data-name="Path 969"
                        d="M15.591,22.5v2.465A1.232,1.232,0,0,1,14.358,26.2H5.732A1.232,1.232,0,0,1,4.5,24.965V22.5"
                        transform="translate(0 -10.606)"
                        fill="none"
                        stroke="#fc563e"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_970"
                        data-name="Path 970"
                        d="M16.661,7.581,13.581,4.5,10.5,7.581"
                        transform="translate(-3.535)"
                        fill="none"
                        stroke="#fc563e"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_971"
                        data-name="Path 971"
                        d="M18,4.5v7.394"
                        transform="translate(-7.955)"
                        fill="none"
                        stroke="#fc563e"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </ManyFilesPicker>
          </div>

          <div className="add-document-save-cancel-btn">
            <button
              className="add-document-cancel-btn"
              onClick={() => onHide()}
            >
              Cancel
            </button>

            <button
              className="add-document-save-btn"
              onClick={() => handleSubmit()}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};
