import { AUTH_SUBTYPE, LOADING_USER_INFO, LOADED_USER_INFO, LOGOUT } from "../actions/Constants";
import * as api from "../../services/AuthService";
import { toastAlert } from "../../jsx/layouts/toastAlert/toastAlert";
import checkErr from "./api_error_func";
import { resendEmail, updateEmail } from "../../services/API";
import { t } from "i18next";

export const getUserInfo = (history) => async (dispatch) => {
  await dispatch({ type: LOADING_USER_INFO});
  try {
      var { data } = await api.getUserInfo();
      await dispatch({ type: AUTH_SUBTYPE, data: data.data});
  } catch (error) {
    dispatch({ type: LOADED_USER_INFO});
    checkErr(error, false, toastAlert, false, dispatch, false);
  }
};

export const logoutActoin = (setLoadingModalShow, history) => async (dispatch) => {
  if(setLoadingModalShow) {
    setLoadingModalShow(true)
  }
  try {
      var lougoutData = await api.logoutAPI();
      if (setLoadingModalShow) {
        setLoadingModalShow(false)
      }
        dispatch({type: LOGOUT})
  } catch (error) {
    if (setLoadingModalShow) {
      setLoadingModalShow(false)
    }
  }
};

export const changeEmail = (data, setLoadingModalShow, setEmailVerf) => async(dispatch) => {
  try {
    const result = await updateEmail(data);
    setLoadingModalShow(false)
    toastAlert(t("Email Changed Successfully"), undefined, "success");
    setEmailVerf(true)
  } catch(error) {
    checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
  }
}

export const reSendVerifyChangeEmail = (setLoadingModalShow, history) => async(dispatch) => {
  try {
    const result = await resendEmail(setLoadingModalShow);
    setLoadingModalShow(false)
    toastAlert(t("Email Send Successfully"), undefined, "success");
  } catch(error) {
    checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
  }
}