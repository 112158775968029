import { t } from "i18next";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  createNewJobStep1,
  createNewJobStep2,
  createNewJobStep3,
  createNewJobStep4,
  updateAcademyJob,
} from "../../../../../store/actions/Jobs";
import { toastAlert } from "../../../../layouts/toastAlert/toastAlert";
import "./PostJob.css";
import Benefits from "./postJobSteps/Benefits";
import Details from "./postJobSteps/Details";
import OtherInfo from "./postJobSteps/OtherInfo";
import Requirements from "./postJobSteps/Requirements";

let lng = JSON.parse(localStorage.getItem("language"))?.lang;

const PostJob = ({ editPost, jobModalLoading, onHide, draft }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [view, setView] = useState(0);
  const postTempo = useSelector((state) => state?.tempoJob);
  const [loadingModalShow, setLoadingModalShow] = useState(
    editPost ? jobModalLoading : false
  );
    console.log("postTempo", postTempo)
  useEffect(() => {
    setLoadingModalShow(editPost ? jobModalLoading : false);
  }, [editPost, jobModalLoading]);

  const [generalInfo, setGeneralInfo] = useState({
    job_id: postTempo?.details?.id || "",
    title: postTempo?.details?.title || "",
    subjects:
      postTempo?.details?.subjects?.map((item) => {
        return { value: item?.id, label: item?.name };
      }) || [],
    grades:
      postTempo?.details?.grades?.map((item) => {
        return { value: item?.id, label: item?.name };
      }) || [],
    genders: postTempo?.details?.genders?.map((item) => {
      return { value: item?.id, label: item?.name };
    }) || [],
    country: postTempo?.details?.location?.country || "Saudi Arabia",
    city: postTempo?.details?.location?.city || "",
    state: postTempo?.details?.location?.state || "",

    minimum_salary: postTempo?.details?.minimum_salary || "",
    maximum_salary: postTempo?.details?.maximum_salary || "",
    publish_salary_information:
      postTempo?.details?.publish_salary_information || "0",
    contract_length_years: postTempo?.details?.contract_length_years || "",
    contract_length_months: postTempo?.details?.contract_length_months || "",
    start_date: postTempo?.details?.start_date
      ? new Date(postTempo?.details?.start_date)
      : new Date(),
    close_date: postTempo?.details?.close_date
      ? new Date(postTempo?.details?.close_date)
      : new Date(),
    description: postTempo?.details?.description || "",
  });

  useEffect(() => {
    setGeneralInfo({
      job_id: postTempo?.details?.id || "",
      title: postTempo?.details?.title || "",
      subjects:
        postTempo?.details?.subjects?.map((item) => {
          return { value: item?.id, label: item?.name };
        }) || [],
      grades:
        postTempo?.details?.grades?.map((item) => {
          return { value: item?.id, label: item?.name };
        }) || [],
        genders: postTempo?.details?.genders?.map((item) => {
          return { value: item?.id, label: item?.name };
        }) || [],
      country: postTempo?.details?.location?.country || "Saudi Arabia",
      city: postTempo?.details?.location?.city || "",
      state: postTempo?.details?.location?.state || "",
      minimum_salary: postTempo?.details?.minimum_salary || "",
      maximum_salary: postTempo?.details?.maximum_salary || "",
      publish_salary_information:
        postTempo?.details?.publish_salary_information || "0",
      contract_length_years: postTempo?.details?.contract_length_years || "",
      contract_length_months: postTempo?.details?.contract_length_months || "",
      start_date: postTempo?.details?.start_date
        ? new Date(postTempo?.details?.start_date)
        : new Date(),
      close_date: postTempo?.details?.close_date
        ? new Date(postTempo?.details?.close_date)
        : new Date(),
      description: postTempo?.details?.description || "",
    });
  }, [postTempo?.details]);

  const handleGeneralInfoChange = (e) => {
    setGeneralInfo({ ...generalInfo, [e.target.name]: e.target.value });
  };

  const submitNewJobStep1 = () => {
    setLoadingModalShow(true);
    let errorArr = [];
    if (!generalInfo.title) {
      errorArr.push(t("Job title is required"));
    }
    if (!generalInfo.country) {
      errorArr.push(t("Country is required"));
    }
    if (!generalInfo.city) {
      errorArr.push(t("City is required"));
    }
    if (!generalInfo.state) {
      errorArr.push(t("State is required"));
    }
    if (!generalInfo.description) {
      errorArr.push(t("Job description is required"));
    }
    if (generalInfo.subjects.length === 0) {
      errorArr.push(t("Please add subjects"));
    }
    if (generalInfo.grades.length === 0) {
      errorArr.push(t("Please add education levels"));
    }
    if (!generalInfo.minimum_salary) {
      errorArr.push(t("Minimum salary is required"));
    }
    if (
      new Date(generalInfo.start_date).getTime() ===
      new Date(generalInfo.close_date).getTime()
    ) {
      errorArr.push(t(`Job start date and close date can't be the same`));
    } else if (
      new Date(generalInfo.start_date).getTime() >
      new Date(generalInfo.close_date).getTime()
    ) {
      errorArr.push(t(`Job start date can't come after the end date`));
    }
    if (formatDate(generalInfo.close_date) === formatDate(new Date())) {
      errorArr.push(t(`Job close date can't be today`));
    }
    if (errorArr.length > 0) {
      toastAlert(errorArr, undefined, "warning");
      setLoadingModalShow(false);
      return false;
    }
    let dataObj = {
      ...generalInfo,
      start_date: formatDate(generalInfo.start_date),
      close_date: formatDate(generalInfo.close_date),
    };
    dispatch(createNewJobStep1(dataObj, setLoadingModalShow, history, setView));
  };

  const editPostHandler = () => {
    setLoadingModalShow(true);
    let errorArr = [];
    if (view === 0) {
      if (!generalInfo.title) {
        errorArr.push(t("Job title is required"));
      }
      if (!generalInfo.country) {
        errorArr.push(t("Country is required"));
      }
      if (!generalInfo.city) {
        errorArr.push(t("City is required"));
      }
      if (!generalInfo.state) {
        errorArr.push(t("State is required"));
      }
      if (!generalInfo.description) {
        errorArr.push(t("Job description is required"));
      }
      if (!generalInfo?.subjects?.length) {
        errorArr.push(t("Please add subjects"));
      }
      if (!generalInfo?.grades?.length) {
        errorArr.push(t("Please add teaching levels"));
      }
      if (!generalInfo.minimum_salary) {
        errorArr.push(t("Minimum salary is required"));
      }
      if (!generalInfo.maximum_salary) {
        errorArr.push(t("Maximum salary is required"));
      }
      if (
        new Date(generalInfo.start_date).getTime() ===
        new Date(generalInfo.close_date).getTime()
      ) {
        errorArr.push(t(`Job start date and close date can't be the same`));
      } else if (
        new Date(generalInfo.start_date).getTime() >
        new Date(generalInfo.close_date).getTime()
      ) {
        errorArr.push(t(`Job start date can't come after the end date`));
      }
      if (formatDate(generalInfo.close_date) === formatDate(new Date())) {
        errorArr.push(t(`Job close date can't be today`));
      }
      if (formatDate(generalInfo.start_date) < formatDate(new Date())) {
        errorArr.push(
          t(`Start Date should be greater than or equal to today date`)
        );
      }
      if (errorArr.length > 0) {
        toastAlert(errorArr, undefined, "warning");
        setLoadingModalShow(false);
        return false;
      }
    }
    setLoadingModalShow(false);
    setView(view + 1);
  };

  const editPostSubmitHandler = () => {
    setLoadingModalShow(true);
    let errorArr = [];
    if (otherInfo.agree_terms == "0") {
      errorArr.push(
        t(
          "You must accept Madares job posting terms and conditions before posting a job"
        )
      );
    }
    if (errorArr.length > 0) {
      toastAlert(errorArr, undefined, "warning");
      setLoadingModalShow(false);
      return false;
    }
    let dataObj = {
      ...generalInfo,
      start_date: formatDate(generalInfo.start_date),
      close_date: formatDate(generalInfo.close_date),
      ...requirementsInfo,
      ...benefitsInfo,
      ...otherInfo,
    };
    console.log("********************dataObj", dataObj);
    dispatch(updateAcademyJob(dataObj, setLoadingModalShow, history, onHide));
  };

  const [requirementsInfo, setRequirementsInfo] = useState({
    job_id: postTempo?.details?.id || "1",
    types_of_educators:
      postTempo?.details?.types_of_educators?.map((item) => {
        return { value: item?.id, label: item?.name };
      }) || [],
    minimum_level_of_education_id:
      postTempo?.details?.minimum_level_of_education_id || "",
    specific_major: postTempo?.details?.specific_major || "",
    minimum_teaching_experience:
      postTempo?.details?.minimum_teaching_experience || "",
    required_certifications:
      postTempo?.details?.required_certifications?.map((item) => {
        return { value: item?.id, label: item?.name };
      }) || [],
    citizenship_match_required:
      postTempo?.details?.citizenship_match_required || "0",
    citizenships:
      postTempo?.details?.citizenships?.map((item) => {
        return { value: item?.id, label: item?.name };
      }) || [],
    other_requirements: postTempo?.details?.other_requirements || "",
    other_required_certifications: postTempo?.details?.other_required_certifications || ""
  });

  useEffect(() => {
    setRequirementsInfo({
      job_id: postTempo?.details?.id || "1",
      types_of_educators:
        postTempo?.details?.types_of_educators?.map((item) => {
          return { value: item?.id, label: item?.name };
        }) || [],
      minimum_level_of_education_id:
        postTempo?.details?.minimum_level_of_education_id || "",
      specific_major: postTempo?.details?.specific_major || "",
      minimum_teaching_experience:
        postTempo?.details?.minimum_teaching_experience || "",
      required_certifications:
        postTempo?.details?.required_certifications?.map((item) => {
          return { value: item?.id, label: item?.name };
        }) || [],
      citizenship_match_required:
        postTempo?.details?.citizenship_match_required || "0",
      citizenships:
        postTempo?.details?.citizenships?.map((item) => {
          return { value: item?.id, label: item?.name };
        }) || [],
      other_requirements: postTempo?.details?.other_requirements || "",
    other_required_certifications: postTempo?.details?.other_required_certifications || ""

    });
  }, [postTempo?.details]);

  const handleRequirementsInfoChange = (e) => {
    console.log("requirementsInfo", requirementsInfo);
    console.log("requirementsInfo e", e);
    setRequirementsInfo({
      ...requirementsInfo,
      [e.target.name]: e.target.value,
    });
  };

  const submitNewJobStep2 = () => {
    console.log("requirementsInfo", requirementsInfo);
    setLoadingModalShow(true);
    dispatch(
      createNewJobStep2(requirementsInfo, setLoadingModalShow, history, setView)
    );
  };

  const [benefitsInfo, setBenefitsInfo] = useState({
    job_id: postTempo?.details?.id || "",
    airfare: postTempo?.details?.airfare || "",
    accommodation: postTempo?.details?.accommodation || "",
    other_benefits: postTempo?.details?.other_benefits || "",
  });

  useEffect(() => {
    setBenefitsInfo({
      job_id: postTempo?.details?.id || "",
      airfare: postTempo?.details?.airfare || "",
      accommodation: postTempo?.details?.accommodation || "",
      other_benefits: postTempo?.details?.other_benefits || "",
    });
  }, [postTempo?.details]);

  const handleBenefitsInfoChange = (e) => {
    setBenefitsInfo({ ...benefitsInfo, [e.target.name]: e.target.value });
  };

  const submitNewJobStep3 = () => {
    setLoadingModalShow(true);
    dispatch(
      createNewJobStep3(benefitsInfo, setLoadingModalShow, history, setView)
    );
  };

  const [otherInfo, setOtherInfo] = useState({
    job_id: postTempo?.details?.id || "1",
    number_of_vacancies: postTempo?.details?.number_of_vacancies || "",
    // apply_by_website: postTempo?.details?.apply_by_website || "0",
    // job_application_link: postTempo?.details?.job_application_link || "",
    notes_to_madares_team: postTempo?.details?.notes_to_madares_team || "",
    agree_terms: postTempo?.details?.agree_terms || "0",
    video_link: postTempo?.details?.video_link || "",
  });

  useEffect(() => {
    setOtherInfo({
      job_id: postTempo?.details?.id || "1",
      number_of_vacancies: postTempo?.details?.number_of_vacancies || "",
      // apply_by_website: postTempo?.details?.apply_by_website || "0",
      // job_application_link: postTempo?.details?.job_application_link || "",
      notes_to_madares_team: postTempo?.details?.notes_to_madares_team || "",
      agree_terms: postTempo?.details?.agree_terms || "0",
      video_link: postTempo?.details?.video_link || "",
    });
  }, [postTempo?.details]);

  const handleOtherInfoChange = (e) => {
    setOtherInfo({ ...otherInfo, [e.target.name]: e.target.value });
  };

  const submitNewJobStep4 = () => {
    setLoadingModalShow(true);
    let errorArr = [];
    if (otherInfo.agree_terms == "0") {
      errorArr.push(
        t(
          "You must accept Madares job posting terms and conditions before posting a job"
        )
      );
    }
    if (errorArr.length > 0) {
      toastAlert(errorArr, undefined, "warning");
      setLoadingModalShow(false);
      return false;
    }
    dispatch(
      createNewJobStep4(otherInfo, setLoadingModalShow, history, setView)
    );
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const back = (toView) => {
    setView(toView);
  };

  return (
    <Fragment>
      {view === 0 ? (
        <Details
          editPost={editPost}
          setGeneralInfo={setGeneralInfo}
          handleGeneralInfoChange={handleGeneralInfoChange}
          generalInfo={generalInfo}
          submitNewJobStep1={submitNewJobStep1}
          editPostHandler={editPostHandler}
          draft={draft}
        />
      ) : view === 1 ? (
        <Requirements
          editPost={editPost}
          back={back}
          setRequirementsInfo={setRequirementsInfo}
          handleRequirementsInfoChange={handleRequirementsInfoChange}
          requirementsInfo={requirementsInfo}
          submitNewJobStep2={submitNewJobStep2}
          setLoadingModalShow={setLoadingModalShow}
          editPostHandler={editPostHandler}
          draft={draft}
        />
      ) : view === 2 ? (
        <Benefits
          editPost={editPost}
          back={back}
          setBenefitsInfo={setBenefitsInfo}
          handleBenefitsInfoChange={handleBenefitsInfoChange}
          benefitsInfo={benefitsInfo}
          submitNewJobStep3={submitNewJobStep3}
          setLoadingModalShow={setLoadingModalShow}
          editPostHandler={editPostHandler}
          draft={draft}
        />
      ) : view === 3 ? (
        <OtherInfo
          editPost={editPost}
          back={back}
          setOtherInfo={setOtherInfo}
          handleOtherInfoChange={handleOtherInfoChange}
          otherInfo={otherInfo}
          submitNewJobStep4={submitNewJobStep4}
          setLoadingModalShow={setLoadingModalShow}
          editPostSubmitHandler={editPostSubmitHandler}
          draft={draft}
        />
      ) : null}
    </Fragment>
  );
};

export default PostJob;
