import { FETCH_BEST_CANDIDATES, BEST_CANDIDATES_LOAD, BEST_CANDIDATES_LOADED } from '../actions/Constants'

export default (bestCandidates = { bestCandidates: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_BEST_CANDIDATES:
            return { ...bestCandidates, bestCandidates: action.data, loading: false };
        case BEST_CANDIDATES_LOAD:
            return { ...bestCandidates, loading: true };
        case BEST_CANDIDATES_LOADED:
            return { ...bestCandidates, loading: false };
        default:
            return bestCandidates;
    }
}