import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAcademyApplicants } from "../../../../store/actions/applicants";
import { getAcademyJobs } from "../../../../store/actions/Jobs";
import Loading from "../../../layouts/loadingComponents/Loading";
import UserProfile from "../academyJobFlow/Applicants/UserProfile";
import NewJobDataTable from "../academyJobFlow/ManageJobs/NewJobDataTable";
import "./HomePage.css";
import saveAs from "save-as";
import { useHistory } from "react-router-dom";
import NewApplicantionDataTable from "../academyJobFlow/Applicants/NewApplicantsDataTable";

let lng = JSON.parse(localStorage.getItem("language"))?.lang;

const HomePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const lastPage = useSelector((state) => state?.academyJobs?.lastPage);
  const academyJobs = useSelector((state) => state?.academyJobs?.academyJobs);
  const academyJobsLoading = useSelector(
    (state) => state?.academyJobs?.loading
  );
  const [loadingModalShow, setLoadingModalShow] = useState(academyJobsLoading);

  const [forceLoad, setForceLoad] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [numbersOfPages, setNumbersOfPages] = useState([]);
  const applicants = useSelector(
    (state) => state?.academyApplicants?.academyApplicants
  );
  const applicantsLoading = useSelector(
    (state) => state?.academyApplicants?.loading
  );
  const lastPageApp = useSelector(
    (state) => state?.academyApplicants?.lastPage
  );

  const [loadingModalShowApp, setLoadingModalShowApp] =
    useState(applicantsLoading);
  const [numbersOfPagesApp, setNumbersOfPagesApp] = useState([]);
  const [applicantPopUp, setApplicantPopUp] = useState(false);
  const [applicantsData, setApplicantsData] = useState();
  const [pageNumberApp, setPageNumberApp] = useState(1);

  useEffect(() => {
    dispatch(getAcademyJobs("", lng === "arab" ? 1 : 2, "1"));
  }, [forceLoad]);

  useEffect(() => {
    dispatch(
      getAcademyApplicants({ job_status: 1 }, lng === "arab" ? 1 : 2, pageNumberApp)
    );
  }, [pageNumberApp]);

  useEffect(() => {
    setLoadingModalShow(academyJobsLoading);
  }, [academyJobsLoading]);

  useEffect(() => {
    setLoadingModalShowApp(applicantsLoading);
  }, [applicantsLoading]);

  useEffect(() => {
    let array = [];
    let pages = lastPage;
    while (pages > 0) {
      array.push(pages);
      pages--;
    }
    setNumbersOfPages(array.reverse());
  }, [lastPage]);

  useEffect(() => {
    let array = [];
    let pages = lastPageApp;
    while (pages > 0) {
      array.push(pages);
      pages--;
    }
    setNumbersOfPagesApp(array.reverse());
  }, [lastPageApp]);

  console.log("academyJobs", academyJobs)

  return (
    <>
      <div className="home-page container">
        <NewJobDataTable
          forceLoad={forceLoad}
          setForceLoad={setForceLoad}
          firstFive={true}
          jobs={academyJobs.slice(0, 5)}
          setPageNumber={setPageNumber}
          numbersOfPages={numbersOfPages}
          loadingModalShow={loadingModalShow}
          liveJob={true}
        />
        <NewApplicantionDataTable
          firstFive={true}
          applicants={applicants?.slice(0, 5)}
          setPageNumber={setPageNumberApp}
          numbersOfPages={numbersOfPagesApp}
          setApplicantPopUp={setApplicantPopUp}
          setApplicantsData={setApplicantsData}
          loadingModalShow={loadingModalShowApp}
        />

        <ApplicantsModal
          show={applicantPopUp}
          onHide={() => setApplicantPopUp(false)}
          data={applicantsData}
        />
      </div>
    </>
  );
};

export default HomePage;

const ApplicantsModal = (props) => {
  return (
    <Modal {...props} centered className={lng === 'arab'? "fade applicantModal forceArabicFont":"fade applicantModal forceEnglishFont"}    >
      <Modal.Body>
        <UserProfile data={props.data} onHide={props.onHide} />
      </Modal.Body>
    </Modal>
  );
};
