import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Applicantion from "./Applicantion";
import { getTeacherApplicantions } from "../../../../store/actions/teachers";
let lng = JSON.parse(localStorage.getItem("language"))?.lang;

const HomeTeacher = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [inputSearch, setInputSearch] = useState("");
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const { teacherApplicantions, loading } = useSelector(
    (state) => state?.teacherApplicantions
  );
  useEffect(() => {
    dispatch(getTeacherApplicantions(lng === "arab" ? 1 : 2, search));
  }, [search]);
  useEffect(() => {
    dispatch(getTeacherApplicantions(lng === "arab" ? 1 : 2, inputSearch));
  }, [inputSearch]);
  useEffect(() => {
    setLoadingModalShow(loading);
  }, [loading]);

  return (
    <>
      <div className="row">
        <div className="col-xl-12" style={{ width: "1200px" }}>
          <div className="d-flex align-items-center flex-wrap search-job bg-white px-0 mb-4">
            <div className="col-lg-3 col-sm-3 col-12 search-dropdown d-flex align-items-center">
              <Dropdown className="form-control border-0  style-1 h-auto">
                <Dropdown.Toggle
                  as="div"
                  className="fs-16 font-w500 text-primary justify-content-between d-flex align-items-center i-false"
                >
                  { search || "All"}
                  <i className="fas fa-angle-down text-primary scale5 ms-3"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
                  <Dropdown.Item
                    className=" text-primary"
                    onClick={() => setSearch("")}
                  >
                    {t("All")}
                  </Dropdown.Item>
                  {teacherApplicantions?.data?.map((item, i) => (
                    <Dropdown.Item
                      key={i}
                      className=" text-primary"
                      onClick={() => setSearch(item?.job?.title)}
                    >
                      {item?.job?.title}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-lg-9 col-sm-9 col-12 d-md-flex job-title-search pe-0">
              <div className="input-group search-area">
                <input
                  value={inputSearch}
                  onChange={(e) => setInputSearch(e.target.value)}
                  type="text"
                  className="form-control h-auto"
                  placeholder={t("search job title here...")}
                />
                <span
                  style={{ gap: "5px", padding: "6px" }}
                  className="input-group-text"
                >
                  {inputSearch && inputSearch?.trim() ? (
                    <Link
                      onClick={() => setInputSearch("")}
                      style={{
                        padding: "10px",
                        borderRadius: "50%",
                        display: "flex",
                        gap: "5px",
                      }}
                      to={"#"}
                      className="btn btn-dark"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Layer_1"
                        dataName="Layer 1"
                        fill="#fff"
                        viewBox="0 0 24 24"
                        width="15"
                        height="15"
                      >
                        <path d="M13.93,12L21.666,2.443c.521-.644,.422-1.588-.223-2.109-.645-.522-1.588-.421-2.109,.223l-7.334,9.06L4.666,.557c-1.241-1.519-3.56,.357-2.332,1.887l7.736,9.557L2.334,21.557c-.521,.644-.422,1.588,.223,2.109,.64,.519,1.586,.424,2.109-.223l7.334-9.06,7.334,9.06c.524,.647,1.47,.742,2.109,.223,.645-.521,.744-1.466,.223-2.109l-7.736-9.557Z" />
                      </svg>
                    </Link>
                  ) : null}
                  <Link
                    style={{
                      padding: "8px 25px 8px 25px",
                      borderRadius: "18px",
                      display: "flex",
                      gap: "5px",
                    }}
                    to={"#"}
                    className="btn btn-primary"
                  >
                    <i
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      className="flaticon-381-search-2 ms-2"
                    ></i>
                    {t("Search")}
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className="d-sm-flex d-block align-items-center justify-content-between mb-4">
            <div className="sm-mb-0 mb-3">
              <h5>
                {t("Showing") +
                  " " +
                  (teacherApplicantions?.data?.length || 0) +
                  " " +
                  t("Jobs Results")}
              </h5>
              <span>{t("Based your preferences")}</span>
            </div>
          </div>
          <Applicantion
            data={teacherApplicantions}
            loadingModalShow={loadingModalShow}
          />
        </div>
      </div>
    </>
  );
};
export default HomeTeacher;
