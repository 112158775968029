import { FETCH_ALL_JOB_TITLES, LOAD_ALL_JOB_TITLE, ALL_JOB_TITLES_LOADED } from "../../actions/Constants";

export default (allJobTitles = { allJobTitles: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_ALL_JOB_TITLES:
            return { ...allJobTitles, allJobTitles: action.data, loading: false };
        case LOAD_ALL_JOB_TITLE:
            return { ...allJobTitles, loading: true };
        case ALL_JOB_TITLES_LOADED:
            return { ...allJobTitles, loading: false };
        default:
            return allJobTitles;
    }
}