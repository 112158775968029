import { FETCH_STATISTICS, STATISTICS_LOAD, STATISTICS_LOADED } from '../actions/Constants'

export default (statistics = { statistics: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_STATISTICS:
            return { ...statistics, statistics: action.data, loading: false };
        case STATISTICS_LOAD:
            return { ...statistics, loading: true };
        case STATISTICS_LOADED:
            return { ...statistics, loading: false };
        default:
            return statistics;
    }
}