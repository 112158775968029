import { applyMiddleware, compose,createStore,} from 'redux';
// import PostsReducer from './reducers/PostsReducer';
import thunk from 'redux-thunk';
import rootReducers from './reducers/index.js'
// import { AuthReducer } from './reducers/AuthReducer';
// import todoReducers from './reducers/Reducers';
// import { reducer as reduxFormReducer } from 'redux-form';
const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//const store = createStore(rootReducers);

export const store = createStore(rootReducers,  composeEnhancers(middleware));
