import { t } from 'i18next';
import { LOGOUT } from '../../store/actions/Constants'

export default function checkErr(error, setLoadingModalShow, toastAlert, option, dispatch, failedOption) {
    if (error?.response?.status === 400) {
      if (setLoadingModalShow) {
        setLoadingModalShow(false)
      }
      if (toastAlert) {
        toastAlert(t(error?.response?.data?.message), undefined, "warning");
      }
      if (option) {
        option()
      }
    } else if (error?.response?.status === 401) {
      if (setLoadingModalShow) {
        setLoadingModalShow(false)
      }
      if (toastAlert) {
        toastAlert("Please login again to continue", undefined, "warning")
      }
      dispatch({ type: LOGOUT })
    } else {
      if (toastAlert) {
        toastAlert("Something Went Wrong!", undefined, "warning");
      }
      if (failedOption) {
        failedOption()
      }
      if (setLoadingModalShow) {
        setLoadingModalShow(false)
      }
    }
}