import { FETCH_SUGGESTED, SUGGESTED_LOAD, SUGGESTED_LOADED } from '../actions/Constants'

export default (suggested = { suggested: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_SUGGESTED:
            return { ...suggested, suggested: action.data, loading: false };
        case SUGGESTED_LOAD:
            return { ...suggested, loading: true };
        case SUGGESTED_LOADED:
            return { ...suggested, loading: false };
        default:
            return suggested;
    }
}