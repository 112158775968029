export const ProfileIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="76"
    height="76"
    viewBox="0 0 76 76"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        y1="1"
        x2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#008c77" />
        <stop offset="1" stopColor="#04c4a7" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="0.5"
        y1="1"
        x2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#addcff" />
        <stop offset="0.503" stopColor="#eaf6ff" />
        <stop offset="1" stopColor="#eaf6ff" />
      </linearGradient>
    </defs>
    <g id="user_5_" data-name="user (5)" transform="translate(0.55 0.719)">
      <g
        id="Group_1219"
        data-name="Group 1219"
        transform="translate(-0.579 -0.747)"
      >
        <g id="Group_1218" data-name="Group 1218" transform="translate(0 0)">
          <circle
            id="Ellipse_6"
            data-name="Ellipse 6"
            cx="38"
            cy="38"
            r="38"
            transform="translate(0.028 0.028)"
            fill="url(#linear-gradient)"
          />
        </g>
      </g>
      <g
        id="Group_1221"
        data-name="Group 1221"
        transform="translate(17.635 13.243)"
      >
        <g id="Group_1220" data-name="Group 1220">
          <path
            id="Path_930"
            data-name="Path 930"
            d="M151.7,101.963a10.963,10.963,0,1,0-10.963,10.963A10.976,10.976,0,0,0,151.7,101.963Zm-10.963,10.963A19.756,19.756,0,0,0,121,132.66v2.055a2.192,2.192,0,0,0,.729,1.633,28.043,28.043,0,0,0,38.009,0,2.192,2.192,0,0,0,.729-1.633V132.66A19.756,19.756,0,0,0,140.734,112.926Z"
            transform="translate(-121 -91)"
            fill="url(#linear-gradient-2)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const AddIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 44 44"
  >
    <defs>
      <filter
        id="Ellipse_7"
        x="0"
        y="0"
        width="44"
        height="44"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="edit" transform="translate(9 6)">
      <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Ellipse_7)">
        <circle
          id="Ellipse_7-2"
          data-name="Ellipse 7"
          cx="13"
          cy="13"
          r="13"
          transform="translate(9 6)"
          fill="#fff"
        />
      </g>
      <g
        id="Icon_feather-plus"
        data-name="Icon feather-plus"
        transform="translate(-1.321 -1.321)"
      >
        <path
          id="Path_972"
          data-name="Path 972"
          d="M18,7.5V21.142"
          transform="translate(-3.679)"
          fill="none"
          stroke="#fc563e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_973"
          data-name="Path 973"
          d="M7.5,18H21.142"
          transform="translate(0 -3.679)"
          fill="none"
          stroke="#fc563e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);

export const EditIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 44 44"
  >
    <defs>
      <filter
        id="Ellipse_7"
        x="0"
        y="0"
        width="44"
        height="44"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="edit" transform="translate(9 6)">
      <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Ellipse_7)">
        <circle
          id="Ellipse_7-2"
          data-name="Ellipse 7"
          cx="13"
          cy="13"
          r="13"
          transform="translate(9 6)"
          fill="#fff"
        />
      </g>
      <path
        id="Icon_material-edit"
        data-name="Icon material-edit"
        d="M4.5,15.3v2.842H7.342l8.381-8.381L12.881,6.916ZM17.921,7.56a.755.755,0,0,0,0-1.068L16.147,4.718a.755.755,0,0,0-1.068,0L13.692,6.1l2.842,2.842L17.921,7.56Z"
        transform="translate(1.679 1.683)"
        fill="#fc563e"
      />
    </g>
  </svg>
);

export const DeleteIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="23.924"
    height="19.139"
    viewBox="0 0 23.924 19.139"
  >
    <path
      id="Icon_material-delete-sweep"
      data-name="Icon material-delete-sweep"
      d="M18.551,20.355h4.785v2.392H18.551Zm0-9.57h8.373v2.392H18.551Zm0,4.785h7.177v2.392H18.551ZM4.2,22.747a2.4,2.4,0,0,0,2.392,2.392h7.177a2.4,2.4,0,0,0,2.392-2.392V10.785H4.2ZM17.355,7.2H13.766L12.57,6H7.785l-1.2,1.2H3V9.589H17.355Z"
      transform="translate(-3 -6)"
      fill="#fc3e3e"
    />
  </svg>
);

export const EyeIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="25.709"
    height="17.139"
    viewBox="0 0 25.709 17.139"
  >
    <path
      id="Icon_awesome-eye"
      data-name="Icon awesome-eye"
      d="M25.554,12.418A14.316,14.316,0,0,0,12.855,4.5a14.318,14.318,0,0,0-12.7,7.918,1.444,1.444,0,0,0,0,1.3,14.316,14.316,0,0,0,12.7,7.918,14.318,14.318,0,0,0,12.7-7.918A1.444,1.444,0,0,0,25.554,12.418ZM12.855,19.5a6.427,6.427,0,1,1,6.427-6.427A6.427,6.427,0,0,1,12.855,19.5Zm0-10.712a4.254,4.254,0,0,0-1.13.169A2.136,2.136,0,0,1,8.739,11.94a4.275,4.275,0,1,0,4.116-3.155Z"
      transform="translate(0 -4.5)"
      fill="#008c77"
    />
  </svg>
);

export const CloseIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="20.027"
    height="20.027"
    viewBox="0 0 20.027 20.027"
  >
    <g
      id="Icon_feather-plus"
      data-name="Icon feather-plus"
      transform="translate(-17.792 10.014) rotate(-45)"
    >
      <path
        id="Path_972"
        data-name="Path 972"
        d="M18,7.5V31.823"
        transform="translate(1.661)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_973"
        data-name="Path 973"
        d="M7.5,18H31.823"
        transform="translate(0 1.661)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export const UploadIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 23 23"
  >
    <g
      id="Icon_feather-upload"
      data-name="Icon feather-upload"
      transform="translate(-3.5 -3.5)"
    >
      <path
        id="Path_969"
        data-name="Path 969"
        d="M25.5,22.5v4.667A2.333,2.333,0,0,1,23.167,29.5H6.833A2.333,2.333,0,0,1,4.5,27.167V22.5"
        transform="translate(0 -4)"
        fill="none"
        stroke="#008c77"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_970"
        data-name="Path 970"
        d="M22.167,10.333,16.333,4.5,10.5,10.333"
        transform="translate(-1.333 0)"
        fill="none"
        stroke="#008c77"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_971"
        data-name="Path 971"
        d="M18,4.5v14"
        transform="translate(-3 0)"
        fill="none"
        stroke="#008c77"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
);

export const EditProfileIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 44 44"
  >
    <defs>
      <filter
        id="Ellipse_7"
        x="0"
        y="0"
        width="44"
        height="44"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="edit" transform="translate(9 6)">
      <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Ellipse_7)">
        <circle
          id="Ellipse_7-2"
          data-name="Ellipse 7"
          cx="13"
          cy="13"
          r="13"
          transform="translate(9 6)"
          fill="#fff"
        />
      </g>
      <g
        id="Icon_feather-upload"
        data-name="Icon feather-upload"
        transform="translate(2.5 2.5)"
      >
        <path
          id="Path_969"
          data-name="Path 969"
          d="M15.591,22.5v2.465A1.232,1.232,0,0,1,14.358,26.2H5.732A1.232,1.232,0,0,1,4.5,24.965V22.5"
          transform="translate(0 -10.606)"
          fill="none"
          stroke="#fc563e"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <path
          id="Path_970"
          data-name="Path 970"
          d="M16.661,7.581,13.581,4.5,10.5,7.581"
          transform="translate(-3.535)"
          fill="none"
          stroke="#fc563e"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <path
          id="Path_971"
          data-name="Path 971"
          d="M18,4.5v7.394"
          transform="translate(-7.955)"
          fill="none"
          stroke="#fc563e"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
      </g>
    </g>
  </svg>
);

export const UploadBannerIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    viewBox="0 0 44 44"
  >
    <defs>
      <filter
        id="Ellipse_7"
        x="0"
        y="0"
        width="44"
        height="44"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="edit" transform="translate(9 6)">
      <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Ellipse_7)">
        <circle
          id="Ellipse_7-2"
          data-name="Ellipse 7"
          cx="13"
          cy="13"
          r="13"
          transform="translate(9 6)"
          fill="#fff"
        />
      </g>
      <g
        id="Icon_feather-upload"
        data-name="Icon feather-upload"
        transform="translate(2.5 2.5)"
      >
        <path
          id="Path_969"
          data-name="Path 969"
          d="M15.591,22.5v2.465A1.232,1.232,0,0,1,14.358,26.2H5.732A1.232,1.232,0,0,1,4.5,24.965V22.5"
          transform="translate(0 -10.606)"
          fill="none"
          stroke="#fc563e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_970"
          data-name="Path 970"
          d="M16.661,7.581,13.581,4.5,10.5,7.581"
          transform="translate(-3.535)"
          fill="none"
          stroke="#fc563e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_971"
          data-name="Path 971"
          d="M18,4.5v7.394"
          transform="translate(-7.955)"
          fill="none"
          stroke="#fc563e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>
);

export const RemoveBannerIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="12.978"
    height="10.383"
    viewBox="0 0 12.978 10.383"
  >
    <path
      id="Icon_material-delete-sweep"
      data-name="Icon material-delete-sweep"
      d="M11.436,13.787h2.6v1.3h-2.6Zm0-5.191h4.542v1.3H11.436Zm0,2.6h3.893v1.3H11.436ZM3.649,15.085a1.3,1.3,0,0,0,1.3,1.3H8.84a1.3,1.3,0,0,0,1.3-1.3V8.6H3.649Zm7.138-8.436H8.84L8.191,6H5.6l-.649.649H3v1.3h7.787Z"
      transform="translate(-3 -6)"
      fill="#fc3e3e"
    />
  </svg>
);
