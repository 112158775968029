import { FETCH_ALL_ACADEMY_DRAFTS, ACADEMY_DRAFTS_LOAD, ACADEMY_DRAFTS_LOADED } from "../../actions/Constants";

export default (drafts = { drafts: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_ALL_ACADEMY_DRAFTS:
            return { ...drafts, drafts: action.data, loading: false };
        case ACADEMY_DRAFTS_LOAD:
            return { ...drafts, loading: true };
        case ACADEMY_DRAFTS_LOADED:
            return { ...drafts, loading: false };
        default:
            return drafts;
    }
}