import {
    FETCH_ACADEMY_APPLICANTS,
    ACADEMY_APPLICANTS_LOAD,
    ACADEMY_APPLICANTS_LOADED,
  } from "../actions/Constants";
  
  export default (academyApplicants = { academyApplicants: [], currentPage: 1, nextPage: 1, lastPage: 1, loading: false}, action) => {
    switch (action.type) {
      case FETCH_ACADEMY_APPLICANTS:
        return { 
          ...academyApplicants, 
          academyApplicants: action.data.data, 
          currentPage: action.data.current_page, 
          nextPage: 
              action.data.current_page === action.data.last_page? 
              action?.data?.current_page:
              action.data.last_page > action.data.current_page?
              action?.data?.current_page + 1:
              action.data.last_page, 
          lastPage: action.data.last_page, 
          loading: false 
        };
      case ACADEMY_APPLICANTS_LOAD:
        return { ...academyApplicants, loading: true };
      case ACADEMY_APPLICANTS_LOADED:
        return { ...academyApplicants, loading: false };
      default:
        return academyApplicants;
    }
  };