import React from "react";
import "../PostJob.css";
const Steps = ({ currentStep }) => {
  return (
    <div className="steps">
      {["Details", "Requirements", "Benefits", "Other Info"]?.map(
        (item, idx) => (
          <div className="step-items">
            <div
              className="step-item-number"
              style={{
                backgroundColor:
                  idx < currentStep
                    ? "rgba(25, 169, 144, 1)"
                    : "rgba(217, 217, 217, 1)",
              }}
            >
              {idx + 1}
            </div>
            <div
              className="step-item-title"
              style={{
                color:
                  idx < currentStep
                    ? "rgba(0, 0, 0, 1)"
                    : "rgba(217, 217, 217, 1)",
              }}
            >
              {item}
            </div>
            {idx < 3 && (
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="45"
                  height="1.5"
                  viewBox="0 0 45 1"
                >
                  <line
                    id="Line_7"
                    data-name="Line 7"
                    x2="44"
                    transform="translate(0.5 0.5)"
                    fill="none"
                    stroke="#707070"
                    stroke-linecap="round"
                    stroke-width="1"
                    stroke-dasharray="4"
                  />
                </svg>
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default Steps;
