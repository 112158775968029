import { FETCH_TEACHER_INFO_BY_ACADEMY, TEACHER_INFO_BY_ACADEMY_LOAD, TEACHER_INFO_BY_ACADEMY_LOADED } from '../actions/Constants'

export default (teacherInfoByAcademy = { teacherInfoByAcademy: {}, loading: false }, action) => {
    switch (action.type) {
        case FETCH_TEACHER_INFO_BY_ACADEMY:
            return { ...teacherInfoByAcademy, teacherInfoByAcademy: action.data, loading: false };
        case TEACHER_INFO_BY_ACADEMY_LOAD:
            return { ...teacherInfoByAcademy, loading: true };
        case TEACHER_INFO_BY_ACADEMY_LOADED:
            return { ...teacherInfoByAcademy, loading: false };
        default:
            return teacherInfoByAcademy;
    }
}