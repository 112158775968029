import axios from "axios";

export const baseURL = "https://api.madaresweb.mvp-apps.ae/api/website/";
export const base_API_URL = "https://api.madaresweb.mvp-apps.ae/api/";
let userTokin = localStorage.getItem("token");

export const Login = (email, pssd) =>
  axios.post(
    `https://api.madaresweb.mvp-apps.ae/api/login?email=${email}&password=${pssd}`
  );
export const socialLogin = (loginType, accessToken) =>
  axios.post(
    `https://api.madaresweb.mvp-apps.ae/api/socialLogin?loginType=${loginType}&accessToken=${accessToken}`
  );
export const checkValidation = (email) =>
  axios.post(
    `https://api.madaresweb.mvp-apps.ae/api/check_verification?email=${email}`
  );

export const signUp = (email, pssd) =>
  axios.post(
    `https://api.madaresweb.mvp-apps.ae/api/register?email=${email}&password=${pssd}`
  );
export const userType = async (userData) => {
  let finalFormData = new FormData();
  for (const item in userData) {
    console.log("here", item);
    if (
      item === "academy_levels" ||
      item === "academy_files" ||
      item === "experience" ||
      item === "certficates" ||
      item === "certficates" ||
      item === "education" ||
      item === "teacherLevels" ||
      item === "teacherSkills"
    ) {
      for (let i = 0; i < userData[item].length; i++) {
        if (item === "education" || item === "experience") {
          for (const subItem in userData[item][i]) {
            finalFormData.append(
              `${item}[${i}][${subItem}]`,
              userData[item][i][subItem]
            );
          }
        } else {
          finalFormData.append(`${item}[]`, userData[item][i]);
        }
      }
    } else {
      finalFormData.append(item, userData[item]);
    }
  }
  for (const item in finalFormData) {
    console.log("here", item);
  }

  return await axios({
    method: "post",
    url:
      userData.type === 255
        ? `https://api.madaresweb.mvp-apps.ae/api/academyRegistration`
        : `https://api.madaresweb.mvp-apps.ae/api/teacherRegistration`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

export const jobLevels = (lng) => axios.post(`${baseURL}joblevel?lang=${lng}`);

export const forgotPassword = (email) =>
  axios.post(
    `https://api.madaresweb.mvp-apps.ae/api/forget_password?email=${email}`
  );

export const resetPassword = (password, password_confirmation, token) => {
  let finalFormData = new FormData();
  finalFormData.append("password", password);
  finalFormData.append("password_confirmation", password_confirmation);
  finalFormData.append("token", token);
  for (var pair of finalFormData.entries()) {
    console.log(pair[0] + ", " + pair[1]);
  }
  axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/reset_password`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

export const reSendVerf = (email, pssd) =>
  axios.post(
    `https://api.madaresweb.mvp-apps.ae/api/re_send_verfiy?email=${email}`
  );

export const getSkills = (lng) => axios.post(`${baseURL}skills?lang=${lng}`);

export const getAvailable = (lng) =>
  axios.post(`${baseURL}available?lang=${lng}`);

export const getSizes = (lng) =>
  axios.post(`${base_API_URL}academy_size?lang=${lng}`);
export const getYears = (lng) =>
  axios.post(`${base_API_URL}years_of_teaching?lang=${lng}`);
export const updateProfile = async (data) => {
  let finalFormData = new FormData();
  for (const item in data) {
    finalFormData.append(`${item}`, data[item]);
  }
  await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/update_my_info`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

export const createHelpRequest = (data) =>
  axios.post(`${base_API_URL}send_help_request`, data);

export const getBlog = (lang) =>
  axios.post(`${baseURL}articlesInfo?lang=${lang}`);

export const getApplicants = (status, page, searchData) =>
  axios.get(
    `${base_API_URL}academyApplicants?status=${status}&page=${page}&search=${searchData}`
  );

export const getAnalytics = () => axios.get(`${base_API_URL}analytics`);

export const getJobMinimumExperience = (lang) =>
  axios.post(`${base_API_URL}jobMinimumExperience?lang=${lang}`);

export const getSalaryRate = (lang) =>
  axios.post(`${base_API_URL}salaryRate?lang=${lang}`);

export const getSalaryType = (lang) =>
  axios.post(`${base_API_URL}salaryType?lang=${lang}`);

export const sendMessage = (data) =>
  axios.post(`${base_API_URL}sendMessage`, data);

export const getMessage = () => axios.get(`${base_API_URL}getMessages`);

export const readMessage = (id) =>
  axios.post(`${base_API_URL}seenMessage?id=${id}`);

export const gender = (lang) =>
  axios.post(`${base_API_URL}gender?lang=${lang}`);

export const customerService = (data) =>
  axios.post(`${base_API_URL}customerService/askForHelp`, data);

export const updateEmail = async (data) => {
  return await axios.post(`${base_API_URL}auth/changeEmail`, data);
};

export const resendEmail = async () => {
  return await axios.post(`${base_API_URL}auth/reSendVerifyChangeEmail`);
};

export const notifications = async (lng) => {
  return await axios.get(`${base_API_URL}notifications?lang=${lng}`);
};

export const readNotifications = async (ids) => {
  let finalFormData = new FormData();
  for (const item in ids) {
    finalFormData.append(`ids[${item}]`, ids[item]);
  }
  return await axios({
    method: "post",
    url: `${base_API_URL}markNotificationsAsRead`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};
