import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
const charCount = (editor) => editor.getContent({ format: "text" }).length;

const Rte = (props) => {
  const sizeLimit = 300;
  const [count, setCount] = useState(0)
  const handleEditorChange = (content, editor) => {
    const cCount = charCount(editor);
    if (cCount <= sizeLimit) {
      props.name?
      props.changeFunc({...props.obj, other_requirements: content}):
      props.benefits?
      props.changeFunc({...props.obj, other_benefits: content}):
      props.changeFunc({...props.obj, description: content})
      setCount(cCount);
    }

  };
  const handleBeforeAddUndo = (evt, editor) => {
    if (charCount(editor) > sizeLimit) {
      evt.preventDefault();
    }
  };


  return (
    <>
      <Editor
      value={props.name? props.obj?.other_requirements: props.benefits? props.obj?.other_benefits : props.obj?.description}
      init={{
          branding:false,
          height: 500,
          menubar: false,
          plugins: [
            "advlist autolink lists link image code charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \n" +
            "bullist numlist outdent indent | removeformat | help ",
			content_style: 'body { color: #828282 }'
        }}
        onEditorChange={handleEditorChange}
        onBeforeAddUndo={handleBeforeAddUndo}
      />
      <p>Remaining: {sizeLimit - count}</p>
    </>
  );
}

export default Rte;
