import { FETCH_ALL_ACADEMY_JOBS, ACADEMY_JOBS_LOAD, ACADEMY_JOBS_LOADED } from "../../actions/Constants";

export default (academyJobs = {academyJobs: [], currentPage: 1, nextPage: 1, lastPage: 1, loading: false}, action) => {
    switch (action.type) {
        case FETCH_ALL_ACADEMY_JOBS:
            return { 
                ...academyJobs, 
                academyJobs: action.data.data, 
                currentPage: action.data.current_page, 
                nextPage: 
                    action.data.current_page === action.data.last_page? 
                    action?.data?.current_page:
                    action.data.last_page > action.data.current_page?
                    action?.data?.current_page + 1:
                    action.data.last_page, 
                lastPage: action.data.last_page, 
                loading: false 
            };
        case ACADEMY_JOBS_LOADED:
            return { ...academyJobs, loading: false };
        case ACADEMY_JOBS_LOAD:  
            return { ...academyJobs, loading: true };
        default:
            return academyJobs
    }
}