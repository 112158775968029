import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useHistory } from "react-router";
import logoPlaceholder from "../../../assets/images/logo.svg";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
// import NavHader from "./NavHader";
import SideMenu from "./sideMenu/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotifications,
  markNotificationsAsRead,
} from "../../../store/actions/notifications";
import moment from "moment";
import Loading from "../loadingComponents/Loading";
import './dashboardHeader.css'

const DashboardHeader = ({ logedInAs, setLoadingState }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [scrolled, setScrolled] = useState(0);
  const display = () => {
    if (window.scrollY >= 20) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  window.addEventListener("scroll", display);

  const [loadingModalShow, setLoadingModalShow] = useState(true);
  useEffect(() => {
    dispatch(getNotifications(setLoadingModalShow, lng === "arab" ? 1 : 2));
  }, [location]);
  const { notifications } = useSelector((state) => state?.notifications);
  const Inner = (str) => {
    if (str === "eng") {
      localStorage.setItem("language", JSON.stringify({ lang: "en" }));
      window.location.reload();
    } else {
      localStorage.setItem("language", JSON.stringify({ lang: "arab" }));
      window.location.reload();
    }
  };
  const readNotification = () => {
    setLoadingModalShow(true);
    const ids = notifications?.notifications?.reduce(function (acc, obj) {
      if (obj.isRead === false) {
        acc.push(obj?.id);
      }
      return acc;
    }, []);
    if(ids.length > 0) {
      dispatch(markNotificationsAsRead(ids, setLoadingModalShow));
    } else {
      setLoadingModalShow(false)
    }
  };
  const history = useHistory();
  return (
    <div className="dashboard_header">
      <div className="header-left">
        <SideMenu setLoadingState={setLoadingState} logedInAs={logedInAs} />
      </div>
      <div className='logo_wraper'>
        <img onClick={() => history.push('/')} src={logoPlaceholder} alt='logo' />
      </div>
      <ul className="right_header_wrapper">
          <Dropdown as="li" className="nav-item dropdown notification_dropdown" onClick={() => readNotification()}>
            <Dropdown.Toggle className="nav-link i-false c-pointer" variant="" as="a" data-toggle="dropdown" aria-expanded="false">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                <g data-name="Layer 2" transform="translate(-2 -2)">
                  <path id="Path_20" data-name="Path 20" fill="#008c77" d="M22.571,15.8V13.066a8.5,8.5,0,0,0-7.714-8.455V2.857a.857.857,0,0,0-1.714,0V4.611a8.5,8.5,0,0,0-7.714,8.455V15.8A4.293,4.293,0,0,0,2,20a2.574,2.574,0,0,0,2.571,2.571H9.8a4.286,4.286,0,0,0,8.4,0h5.23A2.574,2.574,0,0,0,26,20,4.293,4.293,0,0,0,22.571,15.8ZM7.143,13.066a6.789,6.789,0,0,1,6.78-6.78h.154a6.789,6.789,0,0,1,6.78,6.78v2.649H7.143ZM14,24.286a2.567,2.567,0,0,1-2.413-1.714h4.827A2.567,2.567,0,0,1,14,24.286Zm9.429-3.429H4.571A.858.858,0,0,1,3.714,20a2.574,2.574,0,0,1,2.571-2.571H21.714A2.574,2.574,0,0,1,24.286,20a.858.858,0,0,1-.857.857Z"/>
                </g>
              </svg>
              <span className="badge light text-white bg-primary rounded-circle">
                {notifications?.unReadCount}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu align="right" className="mt-2 dropdown-menu dropdown-menu-end">
              <PerfectScrollbar className="widget-media dlab-scroll p-3 height380">
                <ul className="timeline">
                  {loadingModalShow ? (
                    <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center", minHeight: "306px"}}>
                      <Loading />
                    </div>
                  ) : (
                    notifications?.notifications?.map((item, index) => (
                      <li key={index}>
                        <div className="timeline-panel">
                          <div className="media me-2">
                            <img alt="images" width={50} src={item?.image} />
                          </div>
                          <div className="media-body">
                            <h6 className="mb-1">{item?.notification}</h6>
                            <small className="d-block">
                              {moment(item?.created_date).format(
                                "DD MMMM YYYY hh:mm A"
                              )}
                            </small>
                          </div>
                        </div>
                      </li>
                    ))
                  )}
                </ul>
                <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
                  <div className="ps__thumb-x" tabIndex={0} style={{ left: 0, width: 0 }}/>
                </div>
                <div className="ps__rail-y" style={{ top: 0, right: 0 }}>
                  <div className="ps__thumb-y" tabIndex={0} style={{ top: 0, height: 0 }}/>
                </div>
              </PerfectScrollbar>
              <Link className="all-notification" to="#">
                {t("See all notifications")} 
                {lng === 'arab'? <i className="ti-arrow-left" />:<i className="ti-arrow-right" />}
              </Link>
            </Dropdown.Menu>
          </Dropdown>
      </ul>
    </div>
  );
};

export default DashboardHeader;
