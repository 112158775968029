/* eslint-disable */

import { slide as Menu } from 'react-burger-menu'
import './sideMenu.css'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import SideMenuIcon from './SideMenu_icon';
// import one from '../../../../assets/images/animation_effect/1410828541.svg'
// import two from '../../../../assets/images/animation_effect/1281229876.svg'
// import two from '../../../../assets/images/animation_effect/1281229876.svg'
// import three from '../../../../assets/images/animation_effect/book-stack.svg'
import four from '../../../../assets/images/animation_effect/book.svg'
import five from '../../../../assets/images/animation_effect/neweducation.svg'
import six from '../../../../assets/images/animation_effect/books2.svg'
import seven from '../../../../assets/images/animation_effect/newread.svg'
import eight from '../../../../assets/images/animation_effect/newebook.svg'
import dashboardImg from '../../../../assets/images/side menu icons/dashboard.png'
import applications from '../../../../assets/images/side menu icons/applications.png'
import help from '../../../../assets/images/side menu icons/help.png'
import language from '../../../../assets/images/side menu icons/language.png'
import saved_jobs from '../../../../assets/images/side menu icons/saved_jobs.png'
import settings from '../../../../assets/images/side menu icons/settings.png'
import manage_jobs from '../../../../assets/images/side menu icons/manage_jobs.png'
import new_job from '../../../../assets/images/side menu icons/signature.png'
import hiring from '../../../../assets/images/side menu icons/hiring.png'
import ai from '../../../../assets/images/side menu icons/ ai.png'
import resource from '../../../../assets/images/side menu icons/resource.png'
import analytics from '../../../../assets/images/side menu icons/analytics.png'
import lougoutIcon from '../../../../assets/images/side menu icons/logout.png'
import draft from '../../../../assets/images/side menu icons/draft.png'
import { useSelector } from 'react-redux';
import { logoutActoin } from '../../../../store/actions/Auth';
import { useDispatch } from 'react-redux';

const SideMenu = (props) => {
  const animatedright= [manage_jobs,new_job,saved_jobs,four,five,seven,eight];
  const animatedleft= [five,six,eight,seven,four,analytics];
  const [logedInAs, setLogedInAs] = useState(props.logedInAs || 0)
  const location = useLocation()
  const useAuth = useSelector((state) => state?.userDetails?.subTypeData);
  const loading = useSelector((state) => state?.userDetails?.authLoading);
  const [isMenuOpen, handleMenu] = useState(false);
  const dispatch = useDispatch()
  const logOut = () => {
    dispatch(logoutActoin(props.setLoadingState))
  }
  const handleCloseMenu = () => {
    handleMenu(false);
  };
  const handleStateChange = (state) => {
    handleMenu(state.isOpen);
  };
  useEffect(() => {
    setLogedInAs(props.logedInAs || 0)
  }, [props.logedInAs]);
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [languageState, setLanguageState] = useState(lng)
  const Inner = (str) => {
    handleCloseMenu();
    if (str === "eng") {
      localStorage.setItem("language", JSON.stringify({ lang: "en" }));
      window.location.reload();
    } else {
      localStorage.setItem("language", JSON.stringify({ lang: "arab" }));
      window.location.reload();
    }
  };
    return (
      <Menu isOpen={isMenuOpen} onStateChange={handleStateChange} right={languageState === 'arab' ? true:false}>
        <div className='side_menu_wrapper'>
          <div className='sideMenu_header'>
            <svg width='100%' height='100%'>
            {animatedright.map((item, i)=> <image key={i} className='App-logo' href={item} alt='animeicon' x={Math.random()*350} y={Math.random()*180} width='15px' height='40px'/>)}
            {animatedleft.map((item, i)=> <image key={i} className='App-logo2' href={item} alt='animeicon' x={Math.random()*350} y={Math.random()*180} width='15px' height='30px'/>)}
            </svg>
            {loading?
            null:
            <label>{useAuth?.name}</label>
            }
            <div className='fade_effect'/>
          </div>
          <div className='sideMenu_body'>
              {logedInAs === 0?
              <Link onClick={() => handleCloseMenu()} id="applications" className="menu-item" to="/applicants">
                <SideMenuIcon title="applications" src={applications}/>
                <span className="nav-text">{t("Applicants")}</span>
              </Link>:
              null}
              {logedInAs === 1?
              <Link onClick={() => handleCloseMenu()} id="applications" className="menu-item" to="/applications">
                <SideMenuIcon title="applications" src={applications}/>
                <span className="nav-text">{t('Applications')}</span>
              </Link>:
              null}
              {logedInAs === 0?
              <Link onClick={() => handleCloseMenu()} id="contact" className="menu-item" to="/manage-jobs">
                <SideMenuIcon title="manage_jobs" src={manage_jobs}/>
				        <span className="nav-text">{t('Manage Jobs')}</span>
              </Link>:
              null}
              
              {logedInAs === 0?
              <Link onClick={() => handleCloseMenu()} id="contact" className="menu-item" to="/drafts">
                <SideMenuIcon title="draft" src={draft}/>
				        <span className="nav-text">{t('Drafts')}</span>
              </Link>:
              null}
              {logedInAs === 0?
              <Link onClick={() => handleCloseMenu()} id="contact" className="menu-item" to="/database-access">
                <SideMenuIcon title="database_access" src={hiring}/>
				        <span className="nav-text">{t('Database Access')}</span>
              </Link>:
              null}
              {/* {logedInAs === 1?
              <Link onClick={() => handleCloseMenu()} id="search-jobs" className="menu-item" to="/search-jobs">
                <SideMenuIcon title="search_jobs" src={saved_jobs}/>
                <span className="nav-text">{t("Search Jobs")}</span>
              </Link>:
              null} */}
              
              {logedInAs === 1?
              <Link onClick={() => handleCloseMenu()} id="saved-jobs" className="menu-item" to="/saved-jobs">
                <SideMenuIcon title="saved_jobs" src={saved_jobs}/>
                <span className="nav-text">{t("Saved Jobs")}</span>
              </Link>:
              null}
              <hr></hr>
              {/* {logedInAs === 0?
              <Link onClick={() => handleCloseMenu()} id="contact" className="menu-item" to="/resources">
                <SideMenuIcon title="resource" src={resource}/>
                <span className="nav-text">{t("Resources and Tools")}</span>
              </Link>:
              null} */}
              {logedInAs === 0?
              <Link onClick={() => handleCloseMenu()} id="contact" className="menu-item" to="/analytics">
                <SideMenuIcon title="analytics" src={analytics}/>
                <span className="nav-text">{t('Analytics')}</span>
              </Link>:
              null}
              <Link onClick={() => handleCloseMenu()} id="contact" className="menu-item" to="/my-profile">
                <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary" width={25} height={25} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
					        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
					        <circle cx={12} cy={7} r={4} />
					      </svg>
                <span className="nav-text">{t("My Profile")}</span>
              </Link>
              <Link onClick={() => handleCloseMenu()} id="contact" className="menu-item" to="/settings">
                <SideMenuIcon title="settings" src={settings}/>
                <span className="nav-text">{t('Settings')}</span>
              </Link>
              <Link onClick={() => handleCloseMenu()} id="contact" className="menu-item" to="/help">
                <SideMenuIcon title="help" src={help}/>
                <span className="nav-text">{t('Help')}</span>
              </Link>
              <hr></hr>
              {logedInAs === 0?
              <Link onClick={() => handleCloseMenu()} to="/best-candidate" id="about" className="menu-item">
                <SideMenuIcon title="ai" src={ai}/>
                <span className="nav-text">{t("Get The Best Candidate")}</span>
              </Link>:
              null}
              <Link  onClick={() => Inner(languageState === 'arab' ? 'eng' : 'arab')} id="contact" className="menu-item" to="#">
                <SideMenuIcon title="g" src={language}/>
                <span className="nav-text">{languageState === 'arab' ? 'English' : 'العربية'}</span>
              </Link>
              <Link onClick={() => logOut()} id="contact" className="menu-item">
                <SideMenuIcon title="lougoutIcon" src={lougoutIcon}/>
                <span className="nav-text">{t("Logout")}</span>
              </Link>
          </div>
        </div>

      </Menu>
    );
}
export default SideMenu