import React, { Fragment, useState } from "react";
import Rte from "../../../../Common/CreateArticle/Summernote/Rte";
import Steps from "./Steps";

const OtherInfo = ({
  editPost,
  editPostSubmitHandler,
  back,
  setOtherInfo,
  handleOtherInfoChange,
  otherInfo,
  submitNewJobStep4,
  setLoadingModalShow,
  draft
}) => {
  // const [isCheck, setIsCheck] = useState(false)
  return (
    <Fragment>
      {/* <div className="add-job-step-4-top"> */}
      <div className={editPost? "add-job-step-4-top mt-5" : "add-job-step-4-top"}>
        {/* <div className={!editPost && "add-job-step-4"}> */}
        <div className={!editPost && "container"}>
          <Steps currentStep={4} />
          <div style={editPost? {boxShadow: 'unset', marginTop: '0px', marginBottom: '0px', paddingTop:'0px'}:null} className="add-job-step-4-main" >
            {/* Number of Vacancy */}
            <div>
              <div className="add-job-step-4-title">Number Of Vacancy</div>
              <input
                className="add-job-step-4-input-field-vacancy"
                placeholder="1"
                name="number_of_vacancies"
                value={otherInfo.number_of_vacancies}
                onChange={(e) =>
                  handleOtherInfoChange({
                    target: {
                      value: e.target.value.replace(/\D/, ""),
                      name: "number_of_vacancies",
                    },
                  })
                }
                type="text"
              />
            </div>

            {/* Candidates will apply  */}
            {/* <div className="add-job-step-4-label-switch-btn">
              <div className="add-job-step-4-label">
                Candidates will apply by visiting our website
              </div>
              <label className="switch" >
                <input type="checkbox" checked={isCheck} onClick={() => setIsCheck(!isCheck)} />
                <span className="slider round" style={{ backgroundColor: isCheck ? "lightgray" : "white" }}></span>
              </label>
            </div> */}

            {/* App Link */}
            {/* {isCheck && (
              <div className="add-job-step-4-app-link-label-input-field">
                <div className="add-job-step-4-label">Job Application Link</div>
                <input
                  className="add-job-step-4-input-field add-job-step-4-app-link-input-field"
                  placeholder="https://mvp-apps.com"
                  name="job_application_link"
                  value={otherInfo.job_application_link}
                  onChange={handleOtherInfoChange}
                  type="text"
                />
              </div>
            )
            } */}

            {/* Display a video  */}
            <div>
              <div className="add-job-step-4-title add-job-step-4-display-video-label">
                Display a Video On your job posting
              </div>
              <input
                className="add-job-step-4-input-field"
                placeholder="https://mvp-apps.com"
                name="video_link"
                value={otherInfo.video_link}
                onChange={handleOtherInfoChange}
                type="text"
              />
            </div>

            {/* Notes to team  */}
            <div>
              <div className="add-job-step-4-title add-job-step-4-display-video-label">
                Notes to the Madares team{" "}
              </div>
              <textarea
                className="add-job-step-4-text-area"
                rows="4"
                value={otherInfo.notes_to_madares_team}
                name='notes_to_madares_team'
                onChange={handleOtherInfoChange}
              />
            </div>

            {/* Terms And Conditions  */}
            <div className="add-job-step-4-terms-conditions">
              <input
                className="add-job-step-4-check-box-field"
                type="checkbox"
                checked={otherInfo.agree_terms == '1'}
                value={otherInfo.agree_terms}
                onChange={() => handleOtherInfoChange({ target: { value: otherInfo.agree_terms == '0' ? '1' : '0', name: 'agree_terms' } })}
              />
              <div className="add-job-step-4-terms-conditions-label">
                I agree to the Job{" "}
                <span className="add-job-step-4-terms-conditions-span">
                  posting Terms and Conditions
                </span>
              </div>
            </div>

            {/* Next And Cancel Button  */}
            <div className="add-job-step-4-next-back-btn">
              <button className="add-job-step-4-next-btn" onClick={draft? submitNewJobStep4 : editPost ? editPostSubmitHandler : submitNewJobStep4}>Submit</button>
              <div className="add-job-step-4-back-btn" onClick={() => back(2)}>Back</div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OtherInfo;
