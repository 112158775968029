import { ADD_TEMPO_JOB_POST_DETAILS, CLEAR_TEMP } from "../../actions/Constants";

export default (tempoJob = { details: {} }, action) => {
    switch (action.type) {
        case ADD_TEMPO_JOB_POST_DETAILS:
            return { ...tempoJob, details: action.data.slice(0,1).shift()};
        case CLEAR_TEMP:
            return { details: {} }
        default:
            return tempoJob;
    }
}