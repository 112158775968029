import { FETCH_NOTIFICATIONS, NOTIFICATIONS_LOAD, NOTIFICATIONS_LOADED } from '../actions/Constants'

export default (notifications = { notifications: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_NOTIFICATIONS:
            return { ...notifications, notifications: action.data, loading: false };
        case NOTIFICATIONS_LOAD:
            return { ...notifications, loading: true };
        case NOTIFICATIONS_LOADED:
            return { ...notifications, loading: false };
        default:
            return notifications;
    }
}