import { t } from "i18next";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { toastAlert } from "../../../layouts/toastAlert/toastAlert";

const FileUpload = ({ setImage, setFile, children }) => {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        toastAlert(t("File uploaded successfuly"), undefined, "success");
        setFile(acceptedFiles[0]);
        setImage(reader.result);
    };
      reader.onerror = function (error) {
        toastAlert(t("Error while uploading files"), undefined, "warning");
      };
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
        <input {...getInputProps()}/>
        {children}
    </div>
  );
};

export default FileUpload;
