import { FETCH_INDUSTRY, INDUSTRY_LOAD, INDUSTRY_LOADED } from '../actions/Constants'

export default (industry = { industry: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_INDUSTRY:
            return { ...industry, industry: action.data, loading: false };
        case INDUSTRY_LOAD:
            return { ...industry, loading: true };
        case INDUSTRY_LOADED:
            return { ...industry, loading: false };
        default:
            return industry;
    }
}