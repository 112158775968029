import { t } from "i18next";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ChooseProfile from "./ChooseProfile";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteTeacherDocument,
  getDocumentTypes,
  getLicenseTypes,
  getTeacherInfo,
} from "../../../../store/actions/teachers";
import {
  getCurriculums,
  getDegreeLevels,
  getGrades,
  getNewSubjects,
} from "../../../../store/actions/Jobs";
import Loading from "../../../layouts/loadingComponents/Loading";
import "react-phone-number-input/style.css";
import AddEducationModal from "./education/AddEducationModal";
import EditEducationModal from "./education/EditEducationModal";
import AddExperienceModal from "./experience/AddExperienceModal";
import EditExperienceModal from "./experience/EditExperienceModal";
import AddLicenseModal from "./license/AddLicenseModal";
import EditLicenseModal from "./license/EditLicenseModal";
import AddDocumentModal from "./document/AddDocumentModal";
import GeneralInformation from "./GeneralInformation";
import ViewDocumentModal from "./document/ViewDocumentModal";
let lng = JSON.parse(localStorage.getItem("language"))?.lang;

const MyProfile = () => {
  const [view, setView] = useState(0);
  const [forceLoad, setForceLoad] = useState(0);
  const dispatch = useDispatch();
  const { teacherInfo, loading } = useSelector((state) => state?.teacherInfo);
  const { degrees } = useSelector((state) => state?.degrees);
  const { curriculums } = useSelector((state) => state?.curriculums);
  const { grades } = useSelector((state) => state?.grades);
  const { subjects } = useSelector((state) => state?.newSubjects);
  const { licenseTypes } = useSelector((state) => state?.licenseTypes);
  const { documentTypes } = useSelector((state) => state?.documentTypes);
  const [loadingModalShow, setLoadingModalShow] = useState(true);
  const [educationAddModalPopUp, setEducationAddModalPopUp] = useState(false);
  const [educationEditModalPopUp, setEducationEditModalPopUp] = useState(false);
  const [educationEditData, setEducationEditData] = useState();
  const [experienceAddModalPopUp, setExperienceAddModalPopUp] = useState(false);
  const [experienceEditModalPopUp, setExperienceEditModalPopUp] =
    useState(false);
  const [experienceEditData, setExperienceEditData] = useState();
  const [licenseAddModalPopUp, setLicenseAddModalPopUp] = useState(false);
  const [licenseEditModalPopUp, setLicenseEditModalPopUp] = useState(false);
  const [licenseEditData, setLicenseEditData] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [documentAddModalPopUp, setDocumentAddModalPopUp] = useState(false);
  const [documentViewModalPopUp, setDocumentViewModalPopUp] = useState(false);
  const [documentViewData, setDocumentViewData] = useState();
  const [teacherEducation, setTeacherEducation] = useState([
    {
      education_id: "",
      degree_level_id: " 1",
      title: "",
      college: "",
      year_of_graduation: new Date(),
    },
  ]);

  const [teacherExperience, setTeacherExperience] = useState([
    {
      country: "",
      curriculum_id: "",
      end_date: "",
      experience_type: "",
      experience_id: "",
      school: "",
      start_date: "",
      still_work_there: "",
      title: "",
      years_of_experience: "",
      grades: [],
      subjects: [],
    },
  ]);

  const [teacherLicense, setTeacherLicense] = useState([
    {
      country: "",
      has_expiration_date: "1",
      license_id: "",
      grades: [],
      subjects: [],
      place_of_issuance: "",
      state: "",
      teaching_license_type_id: "1",
      valid_from: "",
      valid_to: "",
    },
  ]);

  useEffect(() => {
    dispatch(getTeacherInfo(lng === "arab" ? 1 : 2));
    dispatch(getDegreeLevels(lng === "arab" ? 1 : 2));
    dispatch(getCurriculums(lng === "arab" ? 1 : 2));
    dispatch(getGrades(lng === "arab" ? 1 : 2));
    dispatch(getNewSubjects(lng === "arab" ? 1 : 2));
    dispatch(getLicenseTypes(lng === "arab" ? 1 : 2));
    dispatch(getDocumentTypes(lng === "arab" ? 1 : 2));
  }, []);

  useEffect(() => {
    setLoadingModalShow(loading)
  }, [loading])
  

  useEffect(() => {
    dispatch(getTeacherInfo(lng === "arab" ? 1 : 2));
  }, [forceLoad])
  

  useEffect(() => {
    setTeacherEducation(
      teacherInfo?.educations?.map((item) => ({
        education_id: item?.id || "1",
        degree_level_id: item?.degree_level_id || "",
        title: item?.title || "",
        college: item?.college || "",
        year_of_graduation: item?.year_of_graduation || new Date(),
      }))
    );
  }, [teacherInfo?.educations]);

  useEffect(() => {
    setTeacherExperience(
      teacherInfo?.experiences?.map((item) => ({
        country: item?.country || "",
        curriculum_id: item?.curriculum_id || "",
        end_date: item?.end_date || "",
        experience_type: item?.experience_type || "",
        experience_id: item?.id || "",
        school: item?.school || "",
        start_date: item?.start_date || "",
        still_work_there: item?.still_work_there || "",
        title: item?.title || "",
        years_of_experience: item?.years_of_experience || "",
        grades:
          item?.grades?.map((item) => {
            return { value: item?.id, label: item?.name };
          }) || [],
        subjects:
          item?.subjects?.map((item) => {
            return { value: item?.id, label: item?.name };
          }) || [],
      }))
    );
  }, [teacherInfo?.experiences]);

  useEffect(() => {
    setTeacherLicense(
      teacherInfo?.teaching_licenses?.map((item) => ({
        country: item?.country || "",
        has_expiration_date: item?.has_expiration_date || "1",
        license_id: item?.id || "",
        grades:
          item?.grades?.map((item) => {
            return { value: item?.id, label: item?.name };
          }) || [],
        subjects:
          item?.subjects?.map((item) => {
            return { value: item?.id, label: item?.name };
          }) || [],
        place_of_issuance: item?.place_of_issuance || "",
        state: item?.state || "",
        teaching_license_type_id: item?.teaching_license_type_id || "1",
        valid_from: item?.valid_from || "",
        valid_to: item?.valid_to || "",
      }))
    );
  }, [teacherInfo?.teaching_licenses]);

  useEffect(() => {
    console.log("teacherinfo", teacherInfo);
  }, [teacherInfo])
  

  const addMoreEducationFeilds = () => {
    setEducationAddModalPopUp(true);
  };
  const editEducationFeilds = (item) => {
    setEducationEditData(item);
    setEducationEditModalPopUp(true);
  };
  
  const editExperienceFeilds = (item) => {
    setExperienceEditModalPopUp(true);
    setExperienceEditData(item);
  };
  const addExperienceFields = () => {
    setExperienceAddModalPopUp(true);
  };

  const addLicenseFields = () => {
    setLicenseAddModalPopUp(true);
  };
  const editLicenseFeilds = (item) => {
    setLicenseEditModalPopUp(true);
    setLicenseEditData(item);
  };
  
  const addDocumentFields = () => {
    setDocumentAddModalPopUp(true);
  };
  const removeDocument = (item) => {
    setLoadingModalShow(true);
    dispatch(deleteTeacherDocument(item?.id, setLoadingModalShow, setView, forceLoad, setForceLoad));
  };
  const viewDocument = (item) => {
    console.log("item", item)
    setDocumentViewModalPopUp(true)
    setDocumentViewData(item)
  }
  return (
    <>
      {loadingModalShow ? (
        <div
          style={{
            minHeight: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <>
          <div className="teacher_reg_steps pb-5" />
          <div className="d-flex align-items-center mb-4">
            <h4 className="fs-20 font-w600 mb-0 me-auto">
              {t("Edit Teacher Profile")}
            </h4>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div>
                    {view === 0 ? (
                      <ChooseProfile setView={setView} />
                    ) : (
                      <>
                        {view === 1 ? (
                          <>
                            <GeneralInformation data={teacherInfo} setView={setView} forceLoad={forceLoad} setForceLoad={setForceLoad} />
                          </>
                        ) : null}
                        {view === 2 ? (
                          <>
                            <div className="row">
                              <div
                                className="col-xl-12 col-md-12 col-sm-12 mb-4"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <label className="form-label font-w600">
                                  {t("Education")}
                                </label>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-plus-square-fill"
                                  viewBox="0 0 16 16"
                                  color="#104b08"
                                  title="add another"
                                  onClick={() => addMoreEducationFeilds()}
                                >
                                  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                                </svg>
                              </div>
                            </div>
                            {teacherEducation?.map((item, i) => (
                              <Fragment key={i}>
                                <div className="row">
                                  <div
                                    className="col-lg-12 col-md-12 mb-4"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="row">
                                      <label className="form-label font-w300">
                                        {item?.year_of_graduation +
                                          t(" year of graduation ")}
                                      </label>
                                      <label className="form-label font-w600">
                                        {item?.college}
                                      </label>
                                      <label className="form-label font-w500">
                                        {(degrees?.[degrees?.findIndex(x => x.id == item?.degree_level_id)]?.name || "null") +
                                          ", " +
                                          item?.title}
                                      </label>
                                    </div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="25"
                                      height="20"
                                      color="#104b08"
                                      fill="currentColor"
                                      className="bi bi-pencil"
                                      viewBox="0 0 16 16"
                                      onClick={() => editEducationFeilds(item)}
                                    >
                                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                    </svg>
                                  </div>
                                </div>
                              </Fragment>
                            ))}

                            <div className="row">
                              <div
                                style={{ display: "flex", gap: "7px" }}
                                className="col-xl-6  col-md-6 mb-4"
                              ></div>
                              <div className="card-footer text-end">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent:
                                      view !== 0 ? "space-between" : "flex-end",
                                  }}
                                >
                                  {view !== 0 ? (
                                    <Link
                                      to={"#"}
                                      onClick={() => setView(0)}
                                      className="btn btn-secondry"
                                    >
                                      {t("Back")}
                                    </Link>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                        {view === 3 ? (
                          <>
                            <div className="row">
                              <div
                                className="col-xl-12 col-md-12 col-sm-12 mb-4"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <label className="form-label font-w600">
                                  {t("Experience")}
                                </label>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-plus-square-fill"
                                  viewBox="0 0 16 16"
                                  color="#104b08"
                                  title="add another"
                                  onClick={() => addExperienceFields()}
                                >
                                  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                                </svg>
                              </div>
                            </div>
                            {teacherExperience?.map((item, i) => (
                              <Fragment key={i}>
                                <div className="row">
                                  <div
                                    className="col-lg-12 col-md-12 mb-4"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="row">
                                      <label className="form-label font-w300">
                                        {item?.start_date +
                                          " - " +
                                          (item?.end_date || t("Ongoing")) +
                                          " - " +
                                          item?.years_of_experience +
                                          " yrs"}
                                      </label>
                                      <label className="form-label font-w600">
                                        {item?.title}
                                      </label>
                                      <label className="form-label font-w500">
                                        {item?.school + " - " + item?.country}
                                      </label>
                                    </div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="25"
                                      height="20"
                                      color="#104b08"
                                      fill="currentColor"
                                      className="bi bi-pencil"
                                      viewBox="0 0 16 16"
                                      onClick={() => editExperienceFeilds(item)}
                                    >
                                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                    </svg>
                                  </div>
                                </div>
                              </Fragment>
                            ))}
                            <div className="row">
                              <div
                                style={{ display: "flex", gap: "7px" }}
                                className="col-xl-6  col-md-6 mb-4"
                              ></div>
                              <div className="card-footer text-end">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent:
                                      view !== 0 ? "space-between" : "flex-end",
                                  }}
                                >
                                  {view !== 0 ? (
                                    <Link
                                      to={"#"}
                                      onClick={() => setView(0)}
                                      className="btn btn-secondry"
                                    >
                                      {t("Back")}
                                    </Link>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                        {view === 4 ? (
                          <>
                            <div className="row">
                              <div
                                className="col-xl-12 col-md-12 col-sm-12 mb-4"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <label className="form-label font-w600">
                                  {t("Licenses")}
                                </label>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-plus-square-fill"
                                  viewBox="0 0 16 16"
                                  color="#104b08"
                                  title="add another"
                                  onClick={() => addLicenseFields()}
                                >
                                  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                                </svg>
                              </div>
                            </div>
                            {teacherLicense?.map((item, i) => (
                              <Fragment key={i}>
                                <div className="row">
                                  <div
                                    className="col-lg-12 col-md-12 mb-4"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="row">
                                      <label className="form-label font-w300">
                                        {"Valid from: " +
                                          item?.valid_from +
                                          " - " +
                                          (item?.valid_to || t("Ongoing"))}
                                      </label>
                                      <label className="form-label font-w600">
                                        {t("Teaching License")}
                                      </label>
                                      <label className="form-label font-w500">
                                        {item?.place_of_issuance +
                                          " - " +
                                          item?.country}
                                      </label>
                                    </div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="25"
                                      height="20"
                                      color="#104b08"
                                      fill="currentColor"
                                      className="bi bi-pencil"
                                      viewBox="0 0 16 16"
                                      onClick={() => editLicenseFeilds(item)}
                                    >
                                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                    </svg>
                                  </div>
                                </div>
                              </Fragment>
                            ))}
                            <div className="row">
                              <div
                                style={{ display: "flex", gap: "7px" }}
                                className="col-xl-6  col-md-6 mb-4"
                              ></div>
                              <div className="card-footer text-end">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent:
                                      view !== 0 ? "space-between" : "flex-end",
                                  }}
                                >
                                  {view !== 0 ? (
                                    <Link
                                      to={"#"}
                                      onClick={() => setView(0)}
                                      className="btn btn-secondry"
                                    >
                                      {t("Back")}
                                    </Link>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                        {view === 5 ? (
                          <>
                            <div className="row">
                              <div
                                className="col-xl-12 col-md-12 col-sm-12 mb-4"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: "10px"
                                }}
                              >
                                <label className="form-label font-w600">
                                  {t("Document")}
                                </label>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-plus-square-fill"
                                  viewBox="0 0 16 16"
                                  color="#104b08"
                                  title="add another"
                                  onClick={() => addDocumentFields()}
                                >
                                  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                                </svg>
                              </div>
                            </div>
                            {teacherInfo?.documents?.map((item, i) => (
                              <Fragment key={i}>
                                <div className="row">
                                  <div
                                    className="col-lg-12 col-md-12 mb-4"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      gap: "10px"

                                    }}
                                  >
                                    <div className="row">
                                      <label className="form-label font-w300">
                                        {/* {item?.document_type_id} */}
                                        {documentTypes?.[documentTypes?.findIndex(x => x.id == item?.document_type_id)]?.name || "null"}
                                      </label>
                                    </div>
                                    <div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="20"
                                        fill="currentColor"
                                        className="bi bi-eye"
                                        viewBox="0 0 16 16"
                                        color="#104b08"
                                        style={{ marginRight: "10px" }}
                                        onClick={() => viewDocument(item)}
                                      >
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                      </svg>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="20"
                                        fill="currentColor"
                                        className="bi bi-trash"
                                        viewBox="0 0 16 16"
                                        color="#104b08"
                                        onClick={() => removeDocument(item)}
                                      >
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                        <path
                                          fillRule="evenodd"
                                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </Fragment>
                            ))}
                            <div className="row">
                              <div
                                style={{ display: "flex", gap: "7px" }}
                                className="col-xl-6  col-md-6 mb-4"
                              ></div>
                              <div className="card-footer text-end">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent:
                                      view !== 0 ? "space-between" : "flex-end",
                                  }}
                                >
                                  {view !== 0 ? (
                                    <Link
                                      to={"#"}
                                      onClick={() => setView(0)}
                                      className="btn btn-secondry"
                                    >
                                      {t("Back")}
                                    </Link>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <AddEducationModal
        show={educationAddModalPopUp}
        onHide={() => setEducationAddModalPopUp(false)}
        degrees={degrees}
        setView={setView}
        forceLoad={forceLoad}
        setForceLoad={setForceLoad}
      />
      <EditEducationModal
        show={educationEditModalPopUp}
        onHide={() => setEducationEditModalPopUp(false)}
        data={educationEditData}
        degrees={degrees}
        setView={setView}
        forceLoad={forceLoad}
        setForceLoad={setForceLoad}
      />
      <AddExperienceModal
        show={experienceAddModalPopUp}
        onHide={() => setExperienceAddModalPopUp(false)}
        curriculums={curriculums}
        grades={grades}
        subjects={subjects}
        setView={setView}
        forceLoad={forceLoad}
        setForceLoad={setForceLoad}
      />
      <EditExperienceModal
        show={experienceEditModalPopUp}
        onHide={() => setExperienceEditModalPopUp(false)}
        data={experienceEditData}
        curriculums={curriculums}
        grades={grades}
        subjects={subjects}
        setView={setView}
        forceLoad={forceLoad}
        setForceLoad={setForceLoad}
      />
      <AddLicenseModal
        show={licenseAddModalPopUp}
        onHide={() => setLicenseAddModalPopUp(false)}
        grades={grades}
        subjects={subjects}
        licenseTypes={licenseTypes}
        setView={setView}
        forceLoad={forceLoad}
        setForceLoad={setForceLoad}
      />
      <EditLicenseModal
        show={licenseEditModalPopUp}
        onHide={() => setLicenseEditModalPopUp(false)}
        data={licenseEditData}
        grades={grades}
        subjects={subjects}
        licenseTypes={licenseTypes}
        setView={setView}
        forceLoad={forceLoad}
        setForceLoad={setForceLoad}
      />
      <AddDocumentModal
        show={documentAddModalPopUp}
        onHide={() => setDocumentAddModalPopUp(false)}
        documentTypes={documentTypes}
        setView={setView}
        forceLoad={forceLoad}
        setForceLoad={setForceLoad}
      />

      <ViewDocumentModal
        show={documentViewModalPopUp}
        onHide={() => setDocumentViewModalPopUp(false)}
        data={documentViewData}
      />
    </>
  );
};

export default MyProfile;
