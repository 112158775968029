import { FETCH_TEACHER_INFO, TEACHER_INFO_LOAD, TEACHER_INFO_LOADED } from '../actions/Constants'

export default (teacherInfo = { teacherInfo: {}, loading: false }, action) => {
    switch (action.type) {
        case FETCH_TEACHER_INFO:
            return { ...teacherInfo, teacherInfo: action.data, loading: false };
        case TEACHER_INFO_LOAD:
            return { ...teacherInfo, loading: true };
        case TEACHER_INFO_LOADED:
            return { ...teacherInfo, loading: false };
        default:
            return teacherInfo;
    }
}