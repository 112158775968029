import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDocument,
  addTeacherEducation,
  addTeacherLicense,
  deleteTeacherDocument,
  deleteTeacherEducation,
  deleteTeacherExperience,
  deleteTeacherLicense,
  deleteTeacherResume,
  editTeacherEducation,
  editTeacherExperience,
  editTeacherGeneralInformation,
  editTeacherLicense,
  getDocumentTypes,
  getIndustry,
  getLicenseTypes,
  getTeacherInfo,
} from "../../../../store/actions/teachers";
import "./MyProfile.css";
import {
  ProfileIcon,
  AddIcon,
  EyeIcon,
  DeleteIcon,
  EditIcon,
  CloseIcon,
  UploadIcon,
  EditProfileIcon,
  UploadBannerIcon,
  RemoveBannerIcon,
} from "./Svgs";
import Loading from "../../../layouts/loadingComponents/Loading";
import moment from "moment";
import { getGender } from "../../../../store/actions/gender";
import {
  getCitizenships,
  getCurriculums,
  getDegreeLevels,
  getGrades,
  getJobTitle,
  getLanguages,
  getNewSubjects,
} from "../../../../store/actions/Jobs";
import { Modal, ModalBody } from "react-bootstrap";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { CountryDropdown } from "react-country-region-selector";
import Select from "react-select";
import { toastAlert } from "../../../layouts/toastAlert/toastAlert";
import { useDropzone } from "react-dropzone";
import { Dialog, DialogContent } from "@mui/material";
import FileViewer from "react-file-viewer";
import { State, City } from "country-state-city";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  deleteAcademyAvatar,
  deleteAcademyBanner,
} from "../../../../store/actions/applicants";
import FileUpload from "../../Common/FileUpload";
import { useTranslation } from "react-i18next";

let city;

let lng = JSON.parse(localStorage.getItem("language"))?.lang;
function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

const NewMyProfile = () => {
  const { t } = useTranslation();
  const [forceLoad, setForceLoad] = useState(0);
  const [loadingModalShow, setLoadingModalShow] = useState(true);

  const [showAddEditEducationModal, setShowAddEditEducationModal] =
    useState(false);
  const [selectedEducation, setSelectedEducation] = useState(null);

  const [showAddEditExperienceModal, setShowAddEditExperienceModal] =
    useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);

  const [showAddEditLicenseModal, setShowAddEditLicenseModal] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState(null);

  const [showAddEditAboutYouModal, setShowAddEditAboutYouModal] =
    useState(false);
  const [selectedAboutYou, setSelectedAboutYou] = useState(null);

  const [showEditPersonalInfoModal, setShowEditPersonalInfoModal] =
    useState(false);
  const [selectedPersonalInfo, setSelectedPersonalInfo] = useState(null);

  const [showAddDocumentModal, setShowAddDocumentModal] = useState(false);
  const [showViewDocumentModal, setShowViewDocumentModal] = useState(false);
  const [documentData, setDocumentData] = useState();

  const dispatch = useDispatch();
  const { teacherInfo, loading } = useSelector((state) => state?.teacherInfo);
  const { gender } = useSelector((state) => state?.gender);
  const { degrees } = useSelector((state) => state?.degrees);
  const { licenseTypes } = useSelector((state) => state?.licenseTypes);
  const { documentTypes } = useSelector((state) => state?.documentTypes);
  const { curriculums } = useSelector((state) => state?.curriculums);
  const { grades } = useSelector((state) => state?.grades);
  const { subjects } = useSelector((state) => state?.newSubjects);
  const { industry } = useSelector((state) => state?.industry);
  const { jobTitles } = useSelector((state) => state?.jobTitles);
  const { citizenships } = useSelector((state) => state?.citizenships);
  const { languages } = useSelector((state) => state?.languages);

  useEffect(() => {
    dispatch(getTeacherInfo(lng === "arab" ? 1 : 2));
    dispatch(getGender(lng === "arab" ? 1 : 2));
    dispatch(getDegreeLevels(lng === "arab" ? 1 : 2));
    dispatch(getLicenseTypes(lng === "arab" ? 1 : 2));
    dispatch(getDocumentTypes(lng === "arab" ? 1 : 2));
    dispatch(getCurriculums(lng === "arab" ? 1 : 2));
    dispatch(getGrades(lng === "arab" ? 1 : 2));
    dispatch(getNewSubjects(lng === "arab" ? 1 : 2));
    dispatch(getIndustry(lng === "arab" ? 1 : 2));
    dispatch(getJobTitle(lng === "arab" ? 1 : 2));
    dispatch(getCitizenships(lng === "arab" ? 1 : 2));
    dispatch(getLanguages(lng === "arab" ? 1 : 2));
  }, [forceLoad]);
  useEffect(() => {
    setLoadingModalShow(loading);
  }, [loading]);
  console.log("teacherInfo", teacherInfo);

  const handleDeleteDocument = (item) => {
    setLoadingModalShow(true);
    dispatch(
      deleteTeacherDocument(
        item?.id,
        setLoadingModalShow,
        forceLoad,
        setForceLoad
      )
    );
  };
  async function handleViewDocument(item) {
    console.log('item', item)
      fetch(
        `https://api.madaresweb.mvp-apps.ae/api/viewFile?path=${item?.document_path}&type=AF`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("sess")}` },
          method: "GET",
        }
      )
      .then((response) => response.blob())
      .then((blob) => window.open(URL.createObjectURL(blob), "_blank"));
  };
  return (
    <Fragment>
      {loadingModalShow ? (
        <div
          style={{
            minHeight: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <>
          <div className="teacher-profile">
            <div className="row d-flex">
              <div className="col-lg-4 col-md-12">
                <div className="row">
                  <div className="col-12 mb-5">
                    <div className="teacher-card  d-flex align-items-center position-relative">
                      {teacherInfo?.avatar ? (
                        <img
                          src={teacherInfo?.avatar}
                          alt="logo"
                          style={{
                            width: "76px",
                            height: "76px",
                            borderRadius: "50%",
                            marginBottom: "5px",
                          }}
                        />
                      ) : (
                        <ProfileIcon style={{ marginBottom: "5px" }} />
                      )}
                      <EditIcon
                        className="edit-icon c-pointer"
                        onClick={() => {
                          setShowEditPersonalInfoModal(true);
                          setSelectedPersonalInfo(teacherInfo);
                        }}
                      />
                      <h6>
                        {teacherInfo?.first_name +
                          " " +
                          teacherInfo.last_name || "N/A"}
                      </h6>
                      <label
                        style={{
                          marginBottom: "30px",
                          textTransform: "uppercase",
                        }}
                      >
                        {teacherInfo?.highest_education_degree?.degree_level
                          ?.name +
                          t(" in ") +
                          teacherInfo?.highest_education_degree?.title}
                      </label>
                      <label
                        style={{
                          marginBottom: "20px",
                          textTransform: "capitalize",
                        }}
                      >
                        {teacherInfo?.highest_education_degree?.college}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 mb-5">
                    <div className="teacher-card">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="heading">{t("Documents")}</div>
                        <AddIcon
                          className="c-pointer"
                          onClick={() => setShowAddDocumentModal(true)}
                        />
                      </div>
                      <label
                        className="text-start"
                        style={{ marginBottom: "35px" }}
                      >
                        {t("Upload additional documents to verify your credentials")}
                      </label>
                      {teacherInfo?.documents?.map((item, index) => (
                        <div
                          key={index}
                          className="d-flex justify-content-between align-items-center"
                          style={{ marginBottom: "25px" }}
                        >
                          <h6>
                            {
                              documentTypes?.filter(
                                (x) => x.id == item?.document_type_id
                              )?.[0]?.name
                            }
                          </h6>
                          <div>
                            <EyeIcon
                              className="c-pointer"
                              style={{ marginRight: "15px" }}
                              onClick={() => handleViewDocument(item)}
                            />
                            <DeleteIcon
                              className="c-pointer"
                              onClick={() => handleDeleteDocument(item)}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="row">
                  <div className="col-lg-6 mb-5 col-md-12">
                    <div className="teacher-card">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <div className="heading">{t("About you")}</div>
                        <EditIcon
                          className="c-pointer"
                          onClick={() => {
                            setShowAddEditAboutYouModal(true);
                            setSelectedAboutYou(teacherInfo);
                          }}
                        />
                      </div>
                      <div
                        className="row"
                        style={{ marginTop: "30px", marginBottom: "30px" }}
                      >
                        <div className="col-md-4 mb-2">
                          <p>{t("State")}</p>
                          <h6>{teacherInfo?.location?.state}</h6>
                        </div>
                        <div className="col-md-4 mb-2">
                          <p>{t("City")}</p>
                          <h6>{teacherInfo?.location?.city}</h6>
                        </div>
                        <div className="col-md-4 mb-2">
                          <p>{t("Date of birth")}</p>
                          <h6>
                            {moment(teacherInfo?.dob).format("MMM DD, YYYY")}
                          </h6>
                        </div>
                      </div>
                      <div className="row" style={{ marginBottom: "30px" }}>
                        <div className="col-md-4 mb-2">
                          <p>{t("Phone")}</p>
                          <h6>{teacherInfo?.contact_number}</h6>
                        </div>
                        <div className="col-md-4 mb-2">
                          <p>{t("Citinzienship")}</p>
                          <h6>
                            {teacherInfo?.citizenships
                              ?.map((item) => item?.name)
                              ?.join(", ")}
                          </h6>
                        </div>
                        <div className="col-md-4 mb-2">
                          <p>{t("Gender")}</p>
                          <h6>
                            {
                              gender?.filter(
                                (x) => x.id == teacherInfo?.gender_id
                              )?.[0]?.name
                            }
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 mb-5">
                    <div
                      className="teacher-card"
                      style={{ paddingBottom: "30px" }}
                    >
                      <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ marginBottom: "30px" }}
                      >
                        <div className="heading">{t("Education")}</div>
                        <AddIcon
                          className="c-pointer"
                          onClick={() => setShowAddEditEducationModal(true)}
                        />
                      </div>
                      {teacherInfo?.educations?.map((item, index) => (
                        <div key={index}>
                          <div className="d-flex justify-content-between align-items-start">
                            <div>
                              <p>{t('Year of Graduation') + ' ' + '-' + ' ' + item?.year_of_graduation}</p>
                              <h6 style={{ textTransform: "capitalize" }}>
                                {item?.college}
                              </h6>
                              <label style={{ textTransform: "capitalize" }}>
                                {degrees?.filter(
                                  (x) => x.id == item?.degree_level_id
                                )?.[0]?.name +
                                  t(" in ") +
                                  item?.title}
                              </label>
                            </div>
                            <EditIcon
                              className="c-pointer"
                              onClick={() => {
                                setShowAddEditEducationModal(true);
                                setSelectedEducation(item);
                              }}
                            />
                          </div>
                          {index + 1 < teacherInfo?.educations?.length && (
                            <hr />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 mb-5">
                    <div
                      className="teacher-card"
                      style={{ paddingBottom: "30px" }}
                    >
                      <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ marginBottom: "30px" }}
                      >
                        <div className="heading">{t("Teaching experience")}</div>
                        <AddIcon
                          className="c-pointer"
                          onClick={() => setShowAddEditExperienceModal(true)}
                        />
                      </div>
                      {teacherInfo?.experiences?.map((item, index) => (
                        <div key={index}>
                          <div className="d-flex justify-content-between align-items-start">
                            <div>
                              <div className="d-flex flex-row align-items-center gap-2">
                                <p>
                                  {moment(item?.start_date).format("MMM YYYY") +
                                    " - " +
                                    (item?.still_work_there === "0"
                                      ? moment(item?.end_date).format("MMM YYYY")
                                      : t("Ongoing"))}
                                </p>
                                <div className="dot"></div>
                                <p>{item?.years_of_experience + ' ' + t('years') + ' ' + item?.months_of_experience + ' ' +  t('months')}</p>
                              </div>
                              <h6 style={{ textTransform: "capitalize" }}>
                                {item?.title}
                              </h6>
                              <label
                                style={{
                                  textTransform: "capitalize",
                                  textAlign: "start",
                                }}
                              >
                                {item?.school + " - " + item.country}
                              </label>
                              {item?.experience_type === "Teaching" ? (
                                <>
                                  <div className="d-flex flex-row ">
                                    <p className="p-label">{t("Curriculum: ")}</p>
                                    <h6 className="h-value">{item?.curriculum?.name}</h6>
                                  </div>
                                  <div className="d-flex flex-row ">
                                    <p className="p-label">{t("Subjects: ")}</p>
                                    <h6 className="h-value">{item?.subjects?.map((item) => item?.name)?.join(", ")}</h6>
                                  </div>
                                  <div className="d-flex flex-row mr-2 mt-1">
                                    <p className="p-label">{t("Grade Level: ")}</p>
                                    <h6 className="h-value">{item?.grades?.map((item) => item?.name)?.join(", ")}</h6>
                                  </div>
                                </>
                              ) : item?.experience_type === "Educational Administration" ? (
                                <>
                                  <div className="d-flex flex-row mr-2 mt-1">
                                    <p className="p-label">{t("School Size: ")}</p>
                                    <h6 className="h-value">{item?.school_size}</h6>
                                  </div>
                                </>
                              ) : item?.experience_type === "Relevant work experience" ? (
                                <>
                                  <div className="d-flex flex-row mr-2 mt-1">
                                    <p className="p-label">{t("Industry:")}</p>
                                    <h6 className="h-value">{item?.industry?.name}</h6>
                                  </div>
                                </>
                              ) : null}
                            </div>
                            <EditIcon
                              className="c-pointer"
                              onClick={() => {
                                setShowAddEditExperienceModal(true);
                                setSelectedExperience(item);
                              }}
                            />
                          </div>
                          {index + 1 < teacherInfo?.experiences?.length && (
                            <hr />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 mb-5">
                    <div
                      className="teacher-card"
                      style={{ paddingBottom: "30px" }}
                    >
                      <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ marginBottom: "30px" }}
                      >
                        <div className="heading">{t("Certificates and licenses")}</div>
                        <AddIcon
                          className="c-pointer"
                          onClick={() => setShowAddEditLicenseModal(true)}
                        />
                      </div>
                      {teacherInfo?.teaching_licenses?.map((item, index) => (
                        <div key={index}>
                          <div className="d-flex justify-content-between align-items-start">
                            <div>
                              <p>{
                              t('Valid from:') + 
                              ' ' + 
                              moment(item?.valid_from).format("MMM YYYY") + 
                              ' - ' +  
                              (item?.has_expiration_date === "0"? 
                              t("Ongoing"): 
                              moment(item?.valid_to).format("MMM YYYY"))
                              }</p>
                              <h6>
                                {
                                  item?.license_type || t("N/A")
                                  // licenseTypes?.filter(
                                  //   (x) =>
                                  //     x.id == item?.teaching_license_type_id
                                  // )?.[0]?.name
                                }
                              </h6>
                              <label>{item?.country}</label>
                            </div>
                            <EditIcon
                              className="c-pointer"
                              onClick={() => {
                                setShowAddEditLicenseModal(true);
                                setSelectedLicense(item);
                              }}
                            />
                          </div>
                          {index + 1 <
                            teacherInfo?.teaching_licenses?.length && <hr />}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AddEditEducation
              show={showAddEditEducationModal}
              onHide={() => {
                setShowAddEditEducationModal(false);
                setSelectedEducation(null);
              }}
              education={selectedEducation}
              degrees={degrees}
              setForceLoad={setForceLoad}
              forceLoad={forceLoad}
            />

            <AddEditExperience
              show={showAddEditExperienceModal}
              onHide={() => {
                setShowAddEditExperienceModal(false);
                setSelectedExperience(null);
              }}
              experience={selectedExperience}
              curriculums={curriculums}
              grades={grades}
              subjects={subjects}
              industry={industry}
              setForceLoad={setForceLoad}
              forceLoad={forceLoad}
            />

            <AddEditLicense
              show={showAddEditLicenseModal}
              onHide={() => {
                setShowAddEditLicenseModal(false);
                setSelectedLicense(null);
              }}
              license={selectedLicense}
              licenseTypes={licenseTypes}
              grades={grades}
              subjects={subjects}
              setForceLoad={setForceLoad}
              forceLoad={forceLoad}
            />

            <EditAboutYou
              show={showAddEditAboutYouModal}
              onHide={() => {
                setShowAddEditAboutYouModal(false);
                setSelectedAboutYou(null);
              }}
              aboutYou={selectedAboutYou}
              gender={gender}
              jobTitles={jobTitles}
              citizenships={citizenships}
              languages={languages}
              setForceLoad={setForceLoad}
              forceLoad={forceLoad}
            />

            <EditPersonalInfo
              show={showEditPersonalInfoModal}
              onHide={() => {
                setShowEditPersonalInfoModal(false);
                setSelectedPersonalInfo(null);
              }}
              personalInfo={selectedPersonalInfo}
              setForceLoad={setForceLoad}
              forceLoad={forceLoad}
            />

            <AddDocumentModal
              show={showAddDocumentModal}
              onHide={() => {
                setShowAddDocumentModal(false);
              }}
              documentTypes={documentTypes}
              setForceLoad={setForceLoad}
              forceLoad={forceLoad}
            />

            <ViewDocumentModal
              show={showViewDocumentModal}
              onHide={() => {
                setShowViewDocumentModal(false);
              }}
              documentData={documentData}
            />
          </div>
        </>
      )}
    </Fragment>
  );
};

export default NewMyProfile;

const AddEditEducation = ({
  show,
  onHide,
  education,
  degrees,
  setForceLoad,
  forceLoad,
}) => {
  const { t } = useTranslation();
  const isEdit = education ? true : false;
  const title = education ? t("Edit Education") : t("Add Education");

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [teacherEducation, setTeacherEducation] = useState({
    education_id: education?.id || "",
    degree_level_id: education?.degree_level_id || "",
    title: education?.title || "",
    college: education?.college || "",
    year_of_graduation: education?.year_of_graduation || new Date(),
  });

  useEffect(() => {
    setTeacherEducation({
      education_id: education?.id || "",
      degree_level_id: education?.degree_level_id || "",
      title: education?.title || "",
      college: education?.college || "",
      year_of_graduation: education?.year_of_graduation || new Date(),
    });
  }, [education]);

  const handleDelete = () => {
    setLoading(true);
    dispatch(
      deleteTeacherEducation(
        teacherEducation?.education_id,
        setLoading,
        onHide,
        forceLoad,
        setForceLoad
      )
    );
  };

  const handleSubmit = () => {
    setLoading(true);
    if (isEdit) {
      let data = {
        ...teacherEducation,
        year_of_graduation: new Date(
          teacherEducation.year_of_graduation
        ).getFullYear(),
      };
      let errorArr = [];
      if (!data.degree_level_id) {
        errorArr.push(t("Degree Level is required"));
      }
      if (!data.title) {
        errorArr.push(t("Title is required"));
      }
      if (!data.college) {
        errorArr.push(t("Collage is required"));
      }
      if (!data.year_of_graduation) {
        errorArr.push(t("Year of graduation is required"));
      }
      if (errorArr.length > 0) {
        toastAlert(errorArr, undefined, "warning");
        setLoading(false);
        return false;
      }
      dispatch(
        editTeacherEducation(data, setLoading, onHide, forceLoad, setForceLoad)
      );
    } else {
      let userData = [teacherEducation];
      userData[0].year_of_graduation = new Date(
        userData[0].year_of_graduation
      ).getFullYear();
      let errorArr = [];
      if (!userData[0].degree_level_id) {
        errorArr.push(t("Degree Level is required"));
      }
      if (!userData[0].title) {
        errorArr.push(t("Field of study is required"));
      }
      if (!userData[0].college) {
        errorArr.push(t("Place of Issurance is required"));
      }
      if (!userData[0].year_of_graduation) {
        errorArr.push(t("Year of graduation is required"));
      }
      if (errorArr.length > 0) {
        toastAlert(errorArr, undefined, "warning");
        setLoading(false);
        return false;
      }
      dispatch(
        addTeacherEducation(
          userData,
          setLoading,
          onHide,
          forceLoad,
          setForceLoad
        )
      );
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered size="xl">
      <div
        className={lng === 'arab'? "teacher-profile-modal forceArabicFont":"teacher-profile-modal forceEnglishFont"}
        style={{
          boxShadow: "0px 3px 6px #00000029",
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderRadius: "1.25rem",
        }}
      >
        <div className="d-flex flex-row justify-content-between mb-5">
          <div className="title">{title}</div>
          <CloseIcon className="c-pointer" onClick={onHide} />
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <p>{t("Degree Level")}</p>
            <select
              value={teacherEducation?.degree_level_id}
              onChange={(e) =>
                setTeacherEducation({
                  ...teacherEducation,
                  degree_level_id: e.target.value,
                })
              }
            >
              <option value="" disabled>{t("Select")}</option>
              {degrees?.map((item, i) => (
                <option key={i} value={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-lg-6 col-md-12">
            <p>{t("Field of study")}</p>
            <input
              type="text"
              placeholder={t("math")}
              value={teacherEducation?.title}
              name="title"
              onChange={(e) =>
                setTeacherEducation({
                  ...teacherEducation,
                  title: e.target.value,
                })
              }
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <p>{t("Place of Issurance")}</p>
            <input
              placeholder={t("university name")}
              type="text"
              value={teacherEducation?.college}
              name="college"
              onChange={(e) =>
                setTeacherEducation({
                  ...teacherEducation,
                  college: e.target.value,
                })
              }
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <p>{t("Year of graduation")}</p>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                autoOk
                label=""
                clearable
                name="year_of_graduation"
                views={["year"]}
                disableFuture
                value={teacherEducation?.year_of_graduation}
                onChange={(e) =>
                  setTeacherEducation({
                    ...teacherEducation,
                    year_of_graduation: e,
                  })
                }
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-end gap-5 mt-5">
          <ModalButton
            type={"cancel"}
            isEdit={isEdit}
            onClick={isEdit ? handleDelete : onHide}
          />
          <ModalButton type={"save"} onClick={handleSubmit} />
        </div>
      </div>
    </Modal>
  );
};

const AddEditExperience = ({
  show,
  onHide,
  experience,
  curriculums,
  grades,
  subjects,
  industry,
  setForceLoad,
  forceLoad,
}) => {
  const { t } = useTranslation();
  const isEdit = experience ? true : false;
  const title = experience ? t("Edit Experience") : t("Add Experience");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [teacherExperience, setTeacherExperience] = useState({
    experience_id: experience?.id || "",
    experience_type: experience?.experience_type || "",
    country: experience?.country || "",
    title: experience?.title || "",
    curriculum_id: experience?.curriculum_id || "",
    school: experience?.school || "",
    subjects:
      experience?.subjects?.map((item) => {
        return { value: item?.id, label: item?.name };
      }) || [],
    grades:
      experience?.grades?.map((item) => {
        return { value: item?.id, label: item?.name };
      }) || [],
    start_date: experience?.start_date || new Date(),
    end_date: experience?.end_date || new Date(),
    still_work_there: experience?.still_work_there || "0",
    years_of_experience: experience?.years_of_experience || "",
    industry_id: experience?.industry_id || "",
    school_size: experience?.school_size || "",
  });

  useEffect(() => {
    setTeacherExperience({
      experience_id: experience?.id || "",
      experience_type: experience?.experience_type || "",
      country: experience?.country || "",
      title: experience?.title || "",
      curriculum_id: experience?.curriculum_id || "",
      school: experience?.school || "",
      subjects:
        experience?.subjects?.map((item) => {
          return { value: item?.id, label: item?.name };
        }) || [],
      grades:
        experience?.grades?.map((item) => {
          return { value: item?.id, label: item?.name };
        }) || [],
      start_date: experience?.start_date || new Date(),
      end_date: experience?.end_date || new Date(),
      still_work_there: experience?.still_work_there || "0",
      years_of_experience: experience?.years_of_experience || "",
      industry_id: experience?.industry_id || "",
      school_size: experience?.school_size || "",
    });
  }, [experience]);

  const customStyles = {
    control: () => ({
      display: "flex",
      alignItems: "center",
      width: "100%",
      minHeight: "46px",
      color: "black",
      fontSize: "15px",
      fontWeight: 600,
      border: "0.6px solid rgba(221, 221, 221, 1)",
      backgroundColor: "rgba(255, 255, 255, 1)",
      borderRadius: "10px",
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
      minHeight: "46px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "unset",
      paddingBottom: "unset",
      paddingTop: "unset",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "black",
      fontSize: "15px",
      fontWeight: 600,
    }),
  };

  const handleDelete = () => {
    setLoading(true);
    dispatch(
      deleteTeacherExperience(
        teacherExperience?.experience_id,
        setLoading,
        onHide,
        forceLoad,
        setForceLoad
      )
    );
  };

  const handleSubmit = () => {
    setLoading(true);
    let userData = {
      experience_id: teacherExperience?.experience_id,
      experience_type: teacherExperience?.experience_type,
      title: teacherExperience?.title,
      country: teacherExperience?.country,
      school: teacherExperience?.school,
      start_date: formatDate(teacherExperience?.start_date),
      end_date:
        teacherExperience.still_work_there !== "1"
          ? formatDate(teacherExperience?.end_date)
          : "",
      still_work_there: teacherExperience?.still_work_there,
    };
    if (teacherExperience?.experience_type === "Teaching") {
      userData = {
        ...userData,
        curriculum_id: teacherExperience?.curriculum_id,
        subjects: teacherExperience?.subjects,
        grades: teacherExperience?.grades,
      };
    }
    if (teacherExperience?.experience_type === "Educational Administration") {
      userData = {
        ...userData,
        school_size: teacherExperience?.school_size,
      };
    }
    if (teacherExperience?.experience_type === "Relevant work experience") {
      userData = {
        ...userData,
        industry_id: teacherExperience?.industry_id,
      };
    }
    let errorArr = [];
    if (!userData.experience_type) {
      errorArr.push(t("experience type is required"));
    }
    if (!userData.title) {
      errorArr.push(t("title is required"));
    }
    if (!userData.country) {
      errorArr.push(t("Country is required"));
    }
    if (!userData.school) {
      errorArr.push(t("school is required"));
    }
    if (!userData.start_date) {
      errorArr.push(t("start date is required"));
    }
    if (!userData.still_work_there) {
      errorArr.push(t("still work is required"));
    }
    if (errorArr.length > 0) {
      toastAlert(errorArr, undefined, "warning");
      setLoading(false);
      return false;
    }
    dispatch(
      editTeacherExperience(
        userData,
        setLoading,
        onHide,
        forceLoad,
        setForceLoad
      )
    );
  };
  return (
    <Modal show={show} onHide={onHide} centered size="xl">
      <div
        className={lng === 'arab'? "teacher-profile-modal forceArabicFont":"teacher-profile-modal forceEnglishFont"}
        style={{
          boxShadow: "0px 3px 6px #00000029",
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderRadius: "1.25rem",
        }}
      >
        <div className="d-flex flex-row justify-content-between mb-5">
          <div className="title">{title}</div>
          <CloseIcon className="c-pointer" onClick={onHide} />
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <p>{t("Experience type")}</p>
            <select
              value={teacherExperience?.experience_type}
              name="experience_type"
              onChange={(e) =>
                setTeacherExperience({
                  ...teacherExperience,
                  experience_type: e.target.value,
                })
              }
            >
              <option value="" disabled>{t("Select")}</option>
              <option value={"Teaching"}>{t("Teaching")}</option>
              <option value={t("Educational Administration")}>
                {t("Educational Administration")}
              </option>
              <option value={"Relevant work experience"}>
                {t("Relevant work experience")}
              </option>
            </select>
          </div>
          <div className="col-lg-6 col-md-12">
            <p>{t("Country")}</p>
            <CountryDropdown
              defaultOptionLabel={t("Select Country")}
              value={teacherExperience?.country}
              name="country"
              onChange={(e) =>
                setTeacherExperience({
                  ...teacherExperience,
                  country: e,
                })
              }
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <p>{t("Position Title")}</p>
            <input
              type="text"
              placeholder={t("computer teacher")}
              value={teacherExperience?.title}
              onChange={(e) =>
                setTeacherExperience({
                  ...teacherExperience,
                  title: e.target.value,
                })
              }
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <p>{t("School or District")}</p>
            <input
              type="text"
              placeholder={t("name")}
              value={teacherExperience?.school}
              onChange={(e) =>
                setTeacherExperience({
                  ...teacherExperience,
                  school: e.target.value,
                })
              }
            />
          </div>
          {teacherExperience?.experience_type === "Teaching" ? (
            <>
              <div className="col-lg-6 col-md-12">
                <p>{t("Curriculum you taught")}</p>
                <select
                  value={teacherExperience?.curriculum_id}
                  onChange={(e) =>
                    setTeacherExperience({
                      ...teacherExperience,
                      curriculum_id: e.target.value,
                    })
                  }
                  name="curriculum_id"
                >
                  <option value="" disabled>{t("Select")}</option>
                  {curriculums?.map((item, i) => (
                    <option key={i} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-6 col-md-12">
                <p>{t("Subjects you taught")}</p>
                <Select
                  styles={customStyles}
                  value={teacherExperience?.subjects}
                  name="subject"
                  onChange={(e) =>
                    setTeacherExperience({
                      ...teacherExperience,
                      subjects: e,
                    })
                  }
                  placeholder={t("Select Subjects")}
                  options={subjects?.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                  }))}
                  isMulti
                />
              </div>
              <div className="col-lg-6 col-md-12">
                <p>{t("Grades you taught")}</p>
                <Select
                  styles={customStyles}
                  value={teacherExperience?.grades}
                  name="grades"
                  onChange={(e) =>
                    setTeacherExperience({
                      ...teacherExperience,
                      grades: e,
                    })
                  }
                  placeholder={t("Select Grade")}
                  options={grades?.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                  }))}
                  isMulti
                />
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <p>{t("From")}</p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk
                        label=""
                        clearable
                        name="date_of_birth"
                        format="dd/MM/yyyy"
                        value={teacherExperience?.start_date}
                        onChange={(date) =>
                          setTeacherExperience({
                            ...teacherExperience,
                            start_date: date,
                          })
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  {teacherExperience?.still_work_there == "1" ? null : (
                    <div className="col-lg-6 col-md-12">
                      <p>{t("To")}</p>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk
                          label=""
                          clearable
                          name="date_of_birth"
                          format="dd/MM/yyyy"
                          value={teacherExperience?.end_date}
                          onChange={(date) =>
                            setTeacherExperience({
                              ...teacherExperience,
                              end_date: date,
                            })
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : teacherExperience?.experience_type ===
            "Educational Administration" ? (
            <>
              <div className="col-lg-6 col-md-12">
                <p>{t("School size")}</p>
                <input
                  placeholder="number"
                  value={teacherExperience?.school_size}
                  onChange={(e) =>
                    setTeacherExperience({
                      ...teacherExperience,
                      school_size: e.target.value.replace(/\D/, ""),
                    })
                  }
                />
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <p>{t("From")}</p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk
                        label=""
                        clearable
                        name="date_of_birth"
                        format="dd/MM/yyyy"
                        value={teacherExperience?.start_date}
                        onChange={(date) =>
                          setTeacherExperience({
                            ...teacherExperience,
                            start_date: date,
                          })
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  {teacherExperience?.still_work_there == "1" ? null : (
                    <div className="col-lg-6 col-md-12">
                      <p>{t("To")}</p>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk
                          label=""
                          clearable
                          name="date_of_birth"
                          format="dd/MM/yyyy"
                          value={teacherExperience?.end_date}
                          onChange={(date) =>
                            setTeacherExperience({
                              ...teacherExperience,
                              end_date: date,
                            })
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : teacherExperience?.experience_type ===
            "Relevant work experience" ? (
            <>
              <div className="col-lg-6 col-md-12">
                <p>{t("Industry")}</p>
                <select
                  value={teacherExperience?.industry_id}
                  onChange={(e) =>
                    setTeacherExperience({
                      ...teacherExperience,
                      industry_id: e.target.value,
                    })
                  }
                  name="industry"
                >
                  <option value="" disabled>{t("Select")}</option>
                  {industry?.map((item, i) => (
                    <option key={i} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <p>{t("From")}</p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk
                        label=""
                        clearable
                        name="date_of_birth"
                        format="dd/MM/yyyy"
                        value={teacherExperience?.start_date}
                        onChange={(date) =>
                          setTeacherExperience({
                            ...teacherExperience,
                            start_date: date,
                          })
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  {teacherExperience?.still_work_there == "1" ? null : (
                    <div className="col-lg-6 col-md-12">
                      <p>{t("To")}</p>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk
                          label=""
                          clearable
                          name="date_of_birth"
                          format="dd/MM/yyyy"
                          value={teacherExperience?.end_date}
                          onChange={(date) =>
                            setTeacherExperience({
                              ...teacherExperience,
                              end_date: date,
                            })
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className="row mt-5">
          <div className="col-lg-6 col-md-12 d-flex flex-row align-items-center gap-2">
            <input
              type="checkbox"
              checked={teacherExperience?.still_work_there == "1"}
              value={teacherExperience?.still_work_there}
              onChange={() =>
                setTeacherExperience({
                  ...teacherExperience,
                  still_work_there:
                    teacherExperience?.still_work_there == "0" ? "1" : "0",
                })
              }
            />
            <div>{t("I am currently working in this role")}</div>
          </div>
          <div className="col-lg-6 col-md-12 d-flex align-items-center flex-row gap-5">
            <ModalButton
              type={"cancel"}
              isEdit={isEdit}
              onClick={isEdit ? handleDelete : onHide}
            />
            <ModalButton type={"save"} onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const AddEditLicense = ({
  show,
  onHide,
  license,
  licenseTypes,
  grades,
  subjects,
  setForceLoad,
  forceLoad,
}) => {
  const { t } = useTranslation();
  const isEdit = license ? true : false;
  const title = license ? t("Edit License") : t("Add License");
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [teacherLicense, setTeacherLicense] = useState({
    license_id: license?.id || "",
    country: license?.country || "",
    has_expiration_date: license?.has_expiration_date || "1",
    grades:
      license?.grades?.map((item) => {
        return { value: item?.id, label: item?.name };
      }) || [],
    subjects:
      license?.subjects?.map((item) => {
        return { value: item?.id, label: item?.name };
      }) || [],
    place_of_issuance: license?.place_of_issuance || "",
    teaching_license_type_id: license?.teaching_license_type_id || "",
    valid_from: license?.valid_from || new Date(),
    valid_to: license?.valid_to || new Date(),
    state: license?.state || "ss",
    license_type: license?.license_type || "",
    esl_certificate_type: license?.esl_certificate_type || "",
    hours_completed: license?.hours_completed || "",
  });

  useEffect(() => {
    setTeacherLicense({
      license_id: license?.id || "",
      country: license?.country || "",
      has_expiration_date: license?.has_expiration_date || "1",
      grades:
        license?.grades?.map((item) => {
          return { value: item?.id, label: item?.name };
        }) || [],
      subjects:
        license?.subjects?.map((item) => {
          return { value: item?.id, label: item?.name };
        }) || [],
      place_of_issuance: license?.place_of_issuance || "",
      teaching_license_type_id: license?.teaching_license_type_id || "",
      valid_from: license?.valid_from || new Date(),
      valid_to: license?.valid_to || new Date(),
      state: license?.state || "ss",
      license_type: license?.license_type || "",
      esl_certificate_type: license?.esl_certificate_type || "",
      hours_completed: license?.hours_completed || "",
    });
  }, [license]);

  const customStyles = {
    control: () => ({
      display: "flex",
      alignItems: "center",
      width: "100%",
      minHeight: "46px",
      color: "black",
      fontSize: "15px",
      fontWeight: 600,
      border: "0.6px solid rgba(221, 221, 221, 1)",
      backgroundColor: "rgba(255, 255, 255, 1)",
      borderRadius: "10px",
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
      minHeight: "46px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "unset",
      paddingBottom: "unset",
      paddingTop: "unset",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "black",
      fontSize: "15px",
      fontWeight: 600,
    }),
  };

  const handleSubmit = () => {
    setLoading(true);
    let userData = {
      country: teacherLicense?.country,
      state: teacherLicense?.state,
      license_type: teacherLicense?.license_type,
      place_of_issuance: teacherLicense?.place_of_issuance,
      has_expiration_date: teacherLicense?.has_expiration_date,
      license_id: teacherLicense?.license_id,
    };
    userData.valid_from = formatDate(teacherLicense?.valid_from);
    userData.valid_to =
      teacherLicense.has_expiration_date === "1"
        ? formatDate(teacherLicense?.valid_to)
        : "";
    if (teacherLicense?.license_type === "ESL Certification") {
      userData.esl_certificate_type = teacherLicense?.esl_certificate_type;
      userData.hours_completed = teacherLicense?.hours_completed;
    }
    if (teacherLicense?.license_type === "Teaching License") {
      userData.teaching_license_type_id =
        teacherLicense?.teaching_license_type_id;
      userData.grades = teacherLicense?.grades;
      userData.subjects = teacherLicense?.subjects;
    }
    let errorArr = [];
    if (!userData.country) {
      errorArr.push(t("Country is required"));
    }
    if (!userData.license_type) {
      errorArr.push(t("License type required"));
    }
    if (!userData.place_of_issuance) {
      errorArr.push(t("Place of issuance is required"));
    }
    if (!userData.valid_from) {
      errorArr.push(t("Valid from required"));
    }
    if (errorArr.length > 0) {
      toastAlert(errorArr, undefined, "warning");
      setLoading(false);
      return false;
    }
    dispatch(
      editTeacherLicense(userData, setLoading, onHide, forceLoad, setForceLoad)
    );
  };

  const handleDelete = () => {
    setLoading(true);
    dispatch(
      deleteTeacherLicense(
        teacherLicense?.license_id,
        setLoading,
        onHide,
        forceLoad,
        setForceLoad
      )
    );
  };
  return (
    <Modal show={show} onHide={onHide} centered size="xl">
      <div
        className={lng === 'arab'? "teacher-profile-modal forceArabicFont":"teacher-profile-modal forceEnglishFont"}
        style={{
          boxShadow: "0px 3px 6px #00000029",
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderRadius: "1.25rem",
        }}
      >
        {loading ? (
          <div
            style={{
              minHeight: "500px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loading />
          </div>
        ) : (
          <>
            <div className="d-flex flex-row justify-content-between mb-5">
              <div className="title">{title}</div>
              <CloseIcon className="c-pointer" onClick={onHide} />
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <p>{t("License/certificate type")}</p>
                <select
                  value={teacherLicense?.license_type}
                  onChange={(e) =>
                    setTeacherLicense({
                      ...teacherLicense,
                      license_type: e.target.value,
                    })
                  }
                >
                  <option value="" disabled>{t("Select")}</option>
                  <option value={"Teaching License"}>
                    {t("Teaching License")}
                  </option>
                  <option value={"ESL Certification"}>
                    {t("ESL Certification")}
                  </option>
                  <option value={"Administrator Qualification"}>
                    {t("Administrator Qualification")}
                  </option>
                </select>
              </div>
              <div className="col-lg-6 col-md-12">
                <p>{t("Place of Issurance")}</p>
                <input
                  type="text"
                  placeholder={t("name")}
                  value={teacherLicense?.place_of_issuance}
                  onChange={(e) =>
                    setTeacherLicense({
                      ...teacherLicense,
                      place_of_issuance: e.target.value,
                    })
                  }
                />
              </div>

              {teacherLicense?.license_type === "Teaching License" ? (
                <>
                  <div className="col-lg-6 col-md-12">
                    <p>{t("Teaching License Type")}</p>
                    <select
                      name="teaching_license_type_id"
                      value={teacherLicense?.teaching_license_type_id}
                      onChange={(e) =>
                        setTeacherLicense({
                          ...teacherLicense,
                          teaching_license_type_id: e.target.value,
                        })
                      }
                    >
                      <option value="" disabled>{t("Select")}</option>
                      {licenseTypes?.map((item, i) => (
                        <option key={i} value={item?.id}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <p>{t("Grades you are licensed to teach")}</p>
                    <Select
                      styles={customStyles}
                      value={teacherLicense.grades}
                      name="grades"
                      onChange={(e) =>
                        setTeacherLicense({ ...teacherLicense, grades: e })
                      }
                      placeholder={t("Select")}
                      options={grades?.map((item) => ({
                        label: item?.name,
                        value: item?.id,
                      }))}
                      isMulti
                    />
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <p>{t("Subjects you are licensed to teach")}</p>
                    <Select
                      styles={customStyles}
                      value={teacherLicense.subjects}
                      name="subjects"
                      onChange={(e) =>
                        setTeacherLicense({ ...teacherLicense, subjects: e })
                      }
                      placeholder={t("Select")}
                      options={subjects?.map((item) => ({
                        label: item?.name,
                        value: item?.id,
                      }))}
                      isMulti
                    />
                  </div>
                </>
              ) : teacherLicense?.license_type === "ESL Certification" ? (
                <>
                  <div className="col-lg-6 col-md-12">
                    <p>{t("ESL Certificate Type")}</p>
                    <select
                      value={teacherLicense?.esl_certificate_type}
                      onChange={(e) =>
                        setTeacherLicense({
                          ...teacherLicense,
                          esl_certificate_type: e.target.value,
                        })
                      }
                    >
                      <option disabled value="">{t("Select")}</option>
                      <option value={"TEFL"}>{"TEFL"}</option>
                      <option value={"TESL"}>{"TESL"}</option>
                      <option value={"TESOL"}>{"TESOL"}</option>
                      <option value={"CELTA"}>{"CELTA"}</option>
                      <option value={"DELTA"}>{"DELTA"}</option>
                    </select>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <p>{t("Hours Completed")}</p>
                    <select
                      value={teacherLicense?.hours_completed}
                      onChange={(e) =>
                        setTeacherLicense({
                          ...teacherLicense,
                          hours_completed: e.target.value,
                        })
                      }
                    >
                      <option value="" disabled>{t("Select")}</option>
                      <option value={"Less than 100"}>{t("Less than 100")}</option>
                      <option value={"100 or more"}>{t("100 or more")}</option>
                      <option value={"250+"}>{"250+"}</option>
                    </select>
                  </div>
                </>
              ) : null}
              <div className="col-lg-6 col-md-12">
                <p>{t("Country your license was issued in")}</p>
                <CountryDropdown
                  defaultOptionLabel={t("Select")}
                  value={teacherLicense.country}
                  name="country"
                  onChange={(e) =>
                    setTeacherLicense({ ...teacherLicense, country: e })
                  }
                />
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <p>{t("Valid from")}</p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk
                        label=""
                        clearable
                        name="valid_from"
                        format="dd/MM/yyyy"
                        value={teacherLicense?.valid_from}
                        onChange={(date) =>
                          setTeacherLicense({
                            ...teacherLicense,
                            valid_from: date,
                          })
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  {teacherLicense?.has_expiration_date == "1" ? (
                    <div className="col-lg-6 col-md-12">
                      <p>{t("Expiry")}</p>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk
                          label=""
                          clearable
                          name="valid_to"
                          format="dd/MM/yyyy"
                          value={teacherLicense.valid_to}
                          onChange={(date) =>
                            setTeacherLicense({
                              ...teacherLicense,
                              valid_to: date,
                            })
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-6 col-md-12 d-flex flex-row align-items-center gap-2">
                <input
                  type="checkbox"
                  checked={teacherLicense.has_expiration_date == "0"}
                  value={teacherLicense.has_expiration_date}
                  onChange={() =>
                    setTeacherLicense({
                      ...teacherLicense,
                      has_expiration_date:
                        teacherLicense.has_expiration_date == "0" ? "1" : "0",
                    })
                  }
                />
                <div>{t("No expiration date")}</div>
              </div>
              <div className="col-lg-6 col-md-12 d-flex align-items-center flex-row gap-5">
                <ModalButton
                  type={"cancel"}
                  isEdit={isEdit}
                  onClick={isEdit ? handleDelete : onHide}
                />
                <ModalButton type={"save"} onClick={handleSubmit} />
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

const EditAboutYou = ({
  show,
  onHide,
  aboutYou,
  gender,
  jobTitles,
  citizenships,
  languages,
  setForceLoad,
  forceLoad,
}) => {
  const { t } = useTranslation();
  const title = t("Edit About You");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [bannerFile, setBannerFile] = useState();
  const [bannerState, setBannerState] = useState("");

  const [teacherAboutYou, setTeacherAboutYou] = useState({
    country: aboutYou?.location?.country || "",
    first_name: aboutYou?.first_name || "",
    last_name: aboutYou?.last_name || "",
    allow_share_docs: aboutYou?.allow_share_docs || "",
    banner: aboutYou?.banner || "",
    state: aboutYou?.location?.state || "",
    city: aboutYou?.location?.city || "",
    date_of_birth:
      aboutYou?.dob ||
      `${formatDate(new Date().setFullYear(new Date().getFullYear() - 18))}`,
    contact_number: aboutYou?.contact_number || "",
    citizenships:
      aboutYou?.citizenships?.map((item) => {
        return { value: item?.id, label: item?.name };
      }) || [],
    gender_id: aboutYou?.gender_id || "",
    job_title_id: aboutYou?.job_title_id || "1",
    languages:
      aboutYou?.languages?.map((item) => {
        return { value: item?.id, label: item?.name };
      }) || [],
  });

  useEffect(() => {
    setTeacherAboutYou({
      country: aboutYou?.location?.country || "",
      first_name: aboutYou?.first_name || "",
      last_name: aboutYou?.last_name || "",
      allow_share_docs: aboutYou?.allow_share_docs || "",
      banner: aboutYou?.banner || "",

      state: aboutYou?.location?.state || "",
      city: aboutYou?.location?.city || "",
      date_of_birth:
        aboutYou?.dob ||
        `${formatDate(new Date().setFullYear(new Date().getFullYear() - 18))}`,
      contact_number: aboutYou?.contact_number || "",
      citizenships:
        aboutYou?.citizenships?.map((item) => {
          return { value: item?.id, label: item?.name };
        }) || [],
      gender_id: aboutYou?.gender_id || "",
      job_title_id: aboutYou?.job_title_id || "1",
      languages:
        aboutYou?.languages?.map((item) => {
          return { value: item?.id, label: item?.name };
        }) || [],
    });
  }, [aboutYou]);

  useEffect(() => {
    if (aboutYou?.location?.state) {
      handleState(aboutYou?.location?.state);
    }
  }, [aboutYou?.location?.state]);

  const handleState = (state) => {
    setTeacherAboutYou((prev) => ({ ...prev, state }));
    const code = State.getStatesOfCountry("SA").find(
      (x) => x.name === state
    ).isoCode;
    city = City.getCitiesOfState("SA", code);
  };

  const customStyles = {
    control: () => ({
      display: "flex",
      alignItems: "center",
      width: "100%",
      minHeight: "46px",
      color: "black",
      fontSize: "15px",
      fontWeight: 600,
      border: "0.6px solid rgba(221, 221, 221, 1)",
      backgroundColor: "rgba(255, 255, 255, 1)",
      borderRadius: "10px",
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
      minHeight: "46px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "unset",
      paddingBottom: "unset",
      paddingTop: "unset",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "black",
      fontSize: "15px",
      fontWeight: 600,
    }),
  };

  const handleSubmit = () => {
    setLoading(true);
    let errorArr = [];
    let userData = {
      ...teacherAboutYou,
      date_of_birth: formatDate(teacherAboutYou.date_of_birth),
      banner: bannerState,
    };
    if (!userData.state) {
      errorArr.push(t("state is required"));
    }
    if (!userData.city) {
      errorArr.push(t("city is required"));
    }
    if (!userData.contact_number) {
      errorArr.push(t("contact number is required"));
    }
    if (!userData.date_of_birth) {
      errorArr.push(t("date of birth required"));
    }
    if (!userData.citizenships?.length) {
      errorArr.push(t("citizenship is required"));
    }
    if (!userData.languages?.length) {
      errorArr.push(t("languages is required"));
    }
    if (!userData.gender_id) {
      errorArr.push(t("gender is required"));
    }
    if (!userData.job_title_id) {
      errorArr.push(t("job title is required"));
    }
    if (errorArr.length > 0) {
      toastAlert(errorArr, undefined, "warning");
      setLoading(false);
      return false;
    }
    console.log("userData", userData);
    dispatch(
      editTeacherGeneralInformation(
        userData,
        setLoading,
        onHide,
        setForceLoad,
        forceLoad
      )
    );
  };

  const handleRemoveBanner = () => {
    setLoading(true);
    dispatch(deleteAcademyBanner(setLoading, onHide, setForceLoad, forceLoad));
  };

  return (
    <Modal show={show} onHide={onHide} centered size="xl">
      <div
        className={lng === 'arab'? "teacher-profile-modal forceArabicFont":"teacher-profile-modal forceEnglishFont"}
        style={{
          boxShadow: "0px 3px 6px #00000029",
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderRadius: "1.25rem",
        }}
      >
        {loading ? (
          <div
            style={{
              minHeight: "500px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loading />
          </div>
        ) : (
          <>
            <div className="d-flex flex-row justify-content-between mb-3">
              <div className="title">{title}</div>
              <CloseIcon className="c-pointer" onClick={onHide} />
            </div>

            <div
              className="edit-about-you-info-group-1-of-1 mb-2"
              style={{ paddingTop: "15px" }}
            >
              {/* <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="edit-about-you-info-group-2-label">
                  Upload banner
                </div>
                <div style={{ cursor: "pointer" }} className="userProfile">
                  {bannerFile || teacherAboutYou?.banner ? (
                    <>
                      <img
                        src={bannerFile || teacherAboutYou?.banner}
                        alt="upload_image"
                      />
                    </>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="150"
                      height="150"
                      viewBox="0 0 44 44"
                    >
                      <defs>
                        <filter
                          id="Ellipse_7"
                          x="0"
                          y="0"
                          width="44"
                          height="44"
                          filterUnits="userSpaceOnUse"
                        >
                          <feOffset dy="3" input="SourceAlpha" />
                          <feGaussianBlur stdDeviation="3" result="blur" />
                          <feFlood floodOpacity="0.161" />
                          <feComposite operator="in" in2="blur" />
                          <feComposite in="SourceGraphic" />
                        </filter>
                      </defs>
                      <g id="edit" transform="translate(9 6)">
                        <g
                          transform="matrix(1, 0, 0, 1, -9, -6)"
                          filter="url(#Ellipse_7)"
                        >
                          <circle
                            id="Ellipse_7-2"
                            data-name="Ellipse 7"
                            cx="13"
                            cy="13"
                            r="13"
                            transform="translate(9 6)"
                            fill="#fff"
                          />
                        </g>
                        <g
                          id="Icon_feather-upload"
                          data-name="Icon feather-upload"
                          transform="translate(2.5 2.5)"
                        >
                          <path
                            id="Path_969"
                            data-name="Path 969"
                            d="M15.591,22.5v2.465A1.232,1.232,0,0,1,14.358,26.2H5.732A1.232,1.232,0,0,1,4.5,24.965V22.5"
                            transform="translate(0 -10.606)"
                            fill="none"
                            stroke="#fc563e"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                          />
                          <path
                            id="Path_970"
                            data-name="Path 970"
                            d="M16.661,7.581,13.581,4.5,10.5,7.581"
                            transform="translate(-3.535)"
                            fill="none"
                            stroke="#fc563e"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                          />
                          <path
                            id="Path_971"
                            data-name="Path 971"
                            d="M18,4.5v7.394"
                            transform="translate(-7.955)"
                            fill="none"
                            stroke="#fc563e"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                          />
                        </g>
                      </g>
                    </svg>
                  )}
                </div>
              </div> */}

              <FileUpload setImage={setBannerFile} setFile={setBannerState}>
                <>
                  <div className="edit-about-you-info-group-2-label">
                    {t("Upload banner")}
                  </div>
                  <div style={{ cursor: "pointer" }} className="userProfile">
                    {bannerFile || teacherAboutYou?.banner ? (
                      <>
                        <img
                          src={bannerFile || teacherAboutYou?.banner}
                          alt="upload_image"
                        />
                      </>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="150"
                        height="150"
                        viewBox="0 0 44 44"
                      >
                        <defs>
                          <filter
                            id="Ellipse_7"
                            x="0"
                            y="0"
                            width="44"
                            height="44"
                            filterUnits="userSpaceOnUse"
                          >
                            <feOffset dy="3" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="3" result="blur" />
                            <feFlood floodOpacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                          </filter>
                        </defs>
                        <g id="edit" transform="translate(9 6)">
                          <g
                            transform="matrix(1, 0, 0, 1, -9, -6)"
                            filter="url(#Ellipse_7)"
                          >
                            <circle
                              id="Ellipse_7-2"
                              data-name="Ellipse 7"
                              cx="13"
                              cy="13"
                              r="13"
                              transform="translate(9 6)"
                              fill="#fff"
                            />
                          </g>
                          <g
                            id="Icon_feather-upload"
                            data-name="Icon feather-upload"
                            transform="translate(2.5 2.5)"
                          >
                            <path
                              id="Path_969"
                              data-name="Path 969"
                              d="M15.591,22.5v2.465A1.232,1.232,0,0,1,14.358,26.2H5.732A1.232,1.232,0,0,1,4.5,24.965V22.5"
                              transform="translate(0 -10.606)"
                              fill="none"
                              stroke="#fc563e"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1"
                            />
                            <path
                              id="Path_970"
                              data-name="Path 970"
                              d="M16.661,7.581,13.581,4.5,10.5,7.581"
                              transform="translate(-3.535)"
                              fill="none"
                              stroke="#fc563e"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1"
                            />
                            <path
                              id="Path_971"
                              data-name="Path 971"
                              d="M18,4.5v7.394"
                              transform="translate(-7.955)"
                              fill="none"
                              stroke="#fc563e"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1"
                            />
                          </g>
                        </g>
                      </svg>
                    )}
                  </div>
                </>
              </FileUpload>

              {teacherAboutYou?.banner ? (
                <div
                  className="edit-about-you-info-group-1-of-3"
                  onClick={handleRemoveBanner}
                >
                  <div className="edit-about-you-info-group-1-of-3-label">
                    {t("Remove")}
                  </div>
                  <RemoveBannerIcon />
                </div>
              ) : null}
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-12">
                <p>{t("Gender")}</p>
                <select
                  value={teacherAboutYou.gender_id}
                  onChange={(e) =>
                    setTeacherAboutYou({
                      ...teacherAboutYou,
                      gender_id: e.target.value,
                    })
                  }
                  name="gender_id"
                >
                  {gender.map((item, i) => (
                    <option key={i} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-6 col-md-12">
                <p>{t("Date Of Birth")}</p>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk
                    label=""
                    clearable
                    name="date_of_birth"
                    format="dd/MM/yyyy"
                    value={teacherAboutYou.date_of_birth}
                    maxDate={`${formatDate(
                      new Date().setFullYear(new Date().getFullYear() - 18)
                    )}`}
                    disableFuture
                    onChange={(date) =>
                      setTeacherAboutYou({
                        ...teacherAboutYou,
                        date_of_birth: date,
                      })
                    }
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className="col-lg-6 col-md-12">
                <p>{t("State")}</p>
                <select
                  value={teacherAboutYou.state}
                  onChange={(e) => handleState(e.target.value)}
                  name="state"
                >
                  {State.getStatesOfCountry("SA")?.map((item, i) => (
                    <option key={i} value={item?.name}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-6 col-md-12">
                <p>{t("City")}</p>
                <select
                  value={teacherAboutYou.city}
                  onChange={(e) =>
                    setTeacherAboutYou({
                      ...teacherAboutYou,
                      city: e.target.value,
                    })
                  }
                  name="city"
                >
                  {city?.map((item, i) => (
                    <option key={i} value={item?.name}>
                      {item?.name}
                    </option>
                  ))}
                </select>{" "}
              </div>
              <div className="col-lg-6 col-md-12">
                <p>{t("Contact Number")}</p>
                <PhoneInput
                  id="phone"
                  style={{ direction: "ltr" }}
                  placeholder={t("Enter phone number")}
                  value={teacherAboutYou.contact_number}
                  onChange={(phone) =>
                    setTeacherAboutYou({
                      ...teacherAboutYou,
                      contact_number: phone,
                    })
                  }
                />
              </div>
              <div className="col-lg-6 col-md-12">
                <p>{t("Which job title fits you best?")}</p>
                <select
                  value={teacherAboutYou.job_title_id}
                  onChange={(e) =>
                    setTeacherAboutYou({
                      ...teacherAboutYou,
                      job_title_id: e.target.value,
                    })
                  }
                >
                  {jobTitles?.map((item, i) => (
                    <option key={i} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-6 col-md-12">
                <p>{t("Citizenship")}</p>
                <Select
                  styles={customStyles}
                  onChange={(selectedOption) =>
                    setTeacherAboutYou({
                      ...teacherAboutYou,
                      citizenships: selectedOption,
                    })
                  }
                  value={teacherAboutYou.citizenships}
                  placeholder={t("Select citizenship(s)")}
                  options={citizenships?.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                  }))}
                  isMulti
                />
              </div>
              <div className="col-lg-6 col-md-12">
                <p>{t("Fluent Language")}</p>
                <Select
                  styles={customStyles}
                  onChange={(selectedOption) =>
                    setTeacherAboutYou({
                      ...teacherAboutYou,
                      languages: selectedOption,
                    })
                  }
                  value={teacherAboutYou.languages}
                  placeholder={t("Select Language(s)")}
                  options={languages?.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                  }))}
                  isMulti
                />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-end gap-5 mt-5">
              <ModalButton type={"cancel"} onClick={onHide} />
              <ModalButton type={"save"} onClick={handleSubmit} />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

const EditPersonalInfo = ({
  show,
  onHide,
  personalInfo,
  setForceLoad,
  forceLoad,
}) => {
  const { t } = useTranslation();
  const title = t("Edit Personal Info");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [teacherPersonalInfo, setTeacherPersonalInfo] = useState({
    first_name: personalInfo?.first_name || "",
    last_name: personalInfo?.last_name || "",
    avatar: personalInfo?.avatar || "",
    resume: personalInfo?.resume || "",
    allow_share_docs: personalInfo?.allow_share_docs || "0",

    country: personalInfo?.location?.country || "",
    state: personalInfo?.location?.state || "",
    city: personalInfo?.location?.city || "",
    date_of_birth:
      personalInfo?.dob ||
      `${formatDate(new Date().setFullYear(new Date().getFullYear() - 18))}`,
    contact_number: personalInfo?.contact_number || "",
    citizenships:
      personalInfo?.citizenships?.map((item) => {
        return { value: item?.id, label: item?.name };
      }) || [],
    gender_id: personalInfo?.gender_id || "",
    job_title_id: personalInfo?.job_title_id || "1",
    languages:
      personalInfo?.languages?.map((item) => {
        return { value: item?.id, label: item?.name };
      }) || [],
  });
  useEffect(() => {
    setTeacherPersonalInfo({
      first_name: personalInfo?.first_name || "",
      last_name: personalInfo?.last_name || "",
      avatar: personalInfo?.avatar || "",
      resume: personalInfo?.resume || "",
      allow_share_docs: personalInfo?.allow_share_docs || "0",

      country: personalInfo?.location?.country || "",
      state: personalInfo?.location?.state || "",
      city: personalInfo?.location?.city || "",
      date_of_birth:
        personalInfo?.dob ||
        `${formatDate(new Date().setFullYear(new Date().getFullYear() - 18))}`,
      contact_number: personalInfo?.contact_number || "",
      citizenships:
        personalInfo?.citizenships?.map((item) => {
          return { value: item?.id, label: item?.name };
        }) || [],
      gender_id: personalInfo?.gender_id || "",
      job_title_id: personalInfo?.job_title_id || "1",
      languages:
        personalInfo?.languages?.map((item) => {
          return { value: item?.id, label: item?.name };
        }) || [],
    });
  }, [personalInfo]);

  const [documentFile, setDocumentFile] = useState("");
  const [logoFile, setLogoFile] = useState("");
  const [logo, setLogo] = useState("");
  console.log("teacherPersonalInfo", teacherPersonalInfo);
  const handleFile = (file) => {
    setDocumentFile(file);
  };
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        toastAlert(t("File uploaded successfuly"), undefined, "success");
        handleFile(acceptedFiles[0]);
      };
      reader.onerror = function (error) {
        toastAlert(t("Error while uploading files"), undefined, "warning");
      };
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleSubmit = () => {
    setLoading(true);
    let errorArr = [];
    let userData = {
      ...teacherPersonalInfo,
      date_of_birth: formatDate(teacherPersonalInfo.date_of_birth),
      resume: documentFile,
      avatar: logoFile,
    };
    if (!userData.state) {
      errorArr.push(t("state is required"));
    }
    if (!userData.city) {
      errorArr.push(t("city is required"));
    }
    if (!userData.date_of_birth) {
      errorArr.push(t("date of birth is required"));
    }
    if (!userData.contact_number) {
      errorArr.push(t("contact number is required"));
    }
    if (!userData.date_of_birth) {
      errorArr.push(t("date of birth required"));
    }
    if (!userData.citizenships?.length) {
      errorArr.push(t("citizenship is required"));
    }
    if (!userData.languages?.length) {
      errorArr.push(t("languages is required"));
    }
    if (!userData.gender_id) {
      errorArr.push(t("gender is required"));
    }
    if (!userData.job_title_id) {
      errorArr.push(t("job title is required"));
    }
    if (errorArr.length > 0) {
      toastAlert(errorArr, undefined, "warning");
      setLoading(false);
      return false;
    }
    console.log("userData", userData);
    dispatch(
      editTeacherGeneralInformation(
        userData,
        setLoading,
        onHide,
        setForceLoad,
        forceLoad
      )
    );
  };

  const handleRemoveAvatar = () => {
    console.log("handleRemoveAvatar");
    setLoading(true);
    dispatch(deleteAcademyAvatar(setLoading, onHide, setForceLoad, forceLoad));
  };

  const handleRemoveResume = () => {
    console.log("handleRemoveResume");
    setLoading(true);
    dispatch(deleteTeacherResume(setLoading, onHide, setForceLoad, forceLoad));
  };

  return (
    <Modal show={show} onHide={onHide} centered size="xl">
      <div
        className={lng === 'arab'? "teacher-profile-modal forceArabicFont":"teacher-profile-modal forceEnglishFont"}
        style={{
          boxShadow: "0px 3px 6px #00000029",
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderRadius: "1.25rem",
        }}
      >
        {loading ? (
          <div
            style={{
              minHeight: "500px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loading />
          </div>
        ) : (
          <>
            <div className="d-flex flex-row justify-content-between mb-3">
              <div className="title">{title}</div>
              <CloseIcon className="c-pointer" onClick={onHide} />
            </div>

            <div className="edit-personal-info-group-1-of-1 mb-3">
              <FileUpload setImage={setLogo} setFile={setLogoFile}>
                <div className="edit-personal-info-group-1-of-1">
                  {teacherPersonalInfo?.avatar || logo ? (
                    <img
                      src={logo || teacherPersonalInfo?.avatar}
                      alt="logo"
                      style={{
                        width: "76px",
                        height: "76px",
                        borderRadius: "38px",
                      }}
                    />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="76"
                      height="76"
                      viewBox="0 0 76 76"
                    >
                      <defs>
                        <linearGradient
                          id="linear-gradient"
                          x1="0.5"
                          y1="1"
                          x2="0.5"
                          gradientUnits="objectBoundingBox"
                        >
                          <stop offset="0" stop-color="#008c77" />
                          <stop offset="1" stop-color="#04c4a7" />
                        </linearGradient>
                        <linearGradient
                          id="linear-gradient-2"
                          x1="0.5"
                          y1="1"
                          x2="0.5"
                          gradientUnits="objectBoundingBox"
                        >
                          <stop offset="0" stop-color="#addcff" />
                          <stop offset="0.503" stop-color="#eaf6ff" />
                          <stop offset="1" stop-color="#eaf6ff" />
                        </linearGradient>
                      </defs>
                      <g
                        id="user_5_"
                        data-name="user (5)"
                        transform="translate(0.55 0.719)"
                      >
                        <g
                          id="Group_1219"
                          data-name="Group 1219"
                          transform="translate(-0.579 -0.747)"
                        >
                          <g
                            id="Group_1218"
                            data-name="Group 1218"
                            transform="translate(0 0)"
                          >
                            <circle
                              id="Ellipse_6"
                              data-name="Ellipse 6"
                              cx="38"
                              cy="38"
                              r="38"
                              transform="translate(0.028 0.028)"
                              fill="url(#linear-gradient)"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_1221"
                          data-name="Group 1221"
                          transform="translate(17.635 13.243)"
                        >
                          <g id="Group_1220" data-name="Group 1220">
                            <path
                              id="Path_930"
                              data-name="Path 930"
                              d="M151.7,101.963a10.963,10.963,0,1,0-10.963,10.963A10.976,10.976,0,0,0,151.7,101.963Zm-10.963,10.963A19.756,19.756,0,0,0,121,132.66v2.055a2.192,2.192,0,0,0,.729,1.633,28.043,28.043,0,0,0,38.009,0,2.192,2.192,0,0,0,.729-1.633V132.66A19.756,19.756,0,0,0,140.734,112.926Z"
                              transform="translate(-121 -91)"
                              fill="url(#linear-gradient-2)"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  )}
                  <svg
                    style={{
                      position: "absolute",
                      top: 0,
                      marginRight: "60px",
                      cursor: "pointer",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                  >
                    <defs>
                      <filter
                        id="Ellipse_7"
                        x="0"
                        y="0"
                        width="44"
                        height="44"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur" />
                        <feFlood floodOpacity="0.161" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                    </defs>
                    <g id="edit" transform="translate(9 6)">
                      <g
                        transform="matrix(1, 0, 0, 1, -9, -6)"
                        filter="url(#Ellipse_7)"
                      >
                        <circle
                          id="Ellipse_7-2"
                          data-name="Ellipse 7"
                          cx="13"
                          cy="13"
                          r="13"
                          transform="translate(9 6)"
                          fill="#fff"
                        />
                      </g>
                      <g
                        id="Icon_feather-upload"
                        data-name="Icon feather-upload"
                        transform="translate(2.5 2.5)"
                      >
                        <path
                          id="Path_969"
                          data-name="Path 969"
                          d="M15.591,22.5v2.465A1.232,1.232,0,0,1,14.358,26.2H5.732A1.232,1.232,0,0,1,4.5,24.965V22.5"
                          transform="translate(0 -10.606)"
                          fill="none"
                          stroke="#fc563e"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Path_970"
                          data-name="Path 970"
                          d="M16.661,7.581,13.581,4.5,10.5,7.581"
                          transform="translate(-3.535)"
                          fill="none"
                          stroke="#fc563e"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Path_971"
                          data-name="Path 971"
                          d="M18,4.5v7.394"
                          transform="translate(-7.955)"
                          fill="none"
                          stroke="#fc563e"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </FileUpload>
              {teacherPersonalInfo?.avatar && (
                <div
                  className="edit-personal-info-group-1-of-2"
                  onClick={() => handleRemoveAvatar()}
                >
                  <div className="edit-personal-info-group-1-of-2-label">
                    {t("Remove")}
                  </div>
                  <RemoveBannerIcon />
                </div>
              )}
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-12">
                <p>{t("First Name")}</p>
                <input
                  type="text"
                  placeholder={t("First Name")}
                  value={teacherPersonalInfo.first_name}
                  onChange={(e) =>
                    setTeacherPersonalInfo({
                      ...teacherPersonalInfo,
                      first_name: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-lg-6 col-md-12">
                <p>{t("Last Name")}</p>
                <input
                  type="text"
                  placeholder={t("Last Name")}
                  value={teacherPersonalInfo.last_name}
                  onChange={(e) =>
                    setTeacherPersonalInfo({
                      ...teacherPersonalInfo,
                      last_name: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-lg-6 col-md-12">
                <p>{t("Resume")}</p>
                {teacherPersonalInfo?.resume ? (
                  <>
                    <div className="file d-flex flex-row justify-content-between align-items-center c-pointer">
                      {t("Already Have Resume")}
                      <div
                        className="edit-personal-info-group-1-of-2"
                        onClick={() => handleRemoveResume()}
                      >
                        <div className="edit-personal-info-group-1-of-2-label">
                          {t("Remove")}
                        </div>
                        <RemoveBannerIcon />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="file d-flex flex-row justify-content-between align-items-center c-pointer"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      {documentFile?.path || "Add document"}
                      <UploadIcon className="c-pointer" />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-6 col-md-12 d-flex flex-row align-items-center gap-2">
                <input
                  type="checkbox"
                  checked={teacherPersonalInfo.allow_share_docs == 1}
                  value={teacherPersonalInfo.allow_share_docs}
                  onChange={(e) =>
                    setTeacherPersonalInfo({
                      ...teacherPersonalInfo,
                      allow_share_docs:
                        teacherPersonalInfo.allow_share_docs === 1 ? 0 : 1,
                    })
                  }
                />
                <div>
                  {t("I consent to CV SA receiving this document, storing this information and securely sharing with schools through the CV SA platform.")}
                </div>
              </div>
              <div className="col-lg-6 col-md-12 d-flex align-items-center flex-row gap-5">
                <ModalButton type={"cancel"} onClick={onHide} />
                <ModalButton type={"save"} onClick={handleSubmit} />
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

const AddDocumentModal = ({
  show,
  onHide,
  documentTypes,
  setForceLoad,
  forceLoad,
}) => {
  const { t } = useTranslation();
  const title = t("Add a document");

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [documentFile, setDocumentFile] = useState();

  const [teacherDocument, setTeacherDocument] = useState({
    document_type_id: "",
    is_consent: "1",
  });

  const handleSubmit = () => {
    setLoading(true);
    let userData = {
      ...teacherDocument,
      document: documentFile,
    };
    let errorArr = [];
    if (!userData.document_type_id) {
      errorArr.push(t("Document type is required"));
    }
    if (!userData.document) {
      errorArr.push(t("document is required"));
    }
    if (errorArr.length > 0) {
      toastAlert(errorArr, undefined, "warning");
      setLoading(false);
      return false;
    }
    dispatch(
      addDocument(userData, setLoading, onHide, forceLoad, setForceLoad)
    );
  };

  const handleFile = (file) => {
    setDocumentFile(file);
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        toastAlert(t("File uploaded successfuly"), undefined, "success");
        handleFile(acceptedFiles[0]);
      };
      reader.onerror = function (error) {
        toastAlert(t("Error while uploading files"), undefined, "warning");
      };
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Modal show={show} onHide={onHide} centered size="xl">
      <div
        className={lng === 'arab'? "teacher-profile-modal forceArabicFont":"teacher-profile-modal forceEnglishFont"}
        style={{
          boxShadow: "0px 3px 6px #00000029",
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderRadius: "1.25rem",
        }}
      >
        {loading ? (
          <div
            style={{
              minHeight: "500px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loading />
          </div>
        ) : (
          <>
            <div className="d-flex flex-row justify-content-between mb-5">
              <div className="title">{title}</div>
              <CloseIcon className="c-pointer" onClick={onHide} />
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <p>{t("Document type")}</p>
                <select
                  value={teacherDocument?.document_type_id}
                  onChange={(e) =>
                    setTeacherDocument({
                      ...teacherDocument,
                      document_type_id: e.target.value,
                    })
                  }
                >
                  <option value="" disabled>{t("Select")}</option>
                  {documentTypes?.map((item, i) => (
                    <option key={i} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-6 col-md-12">
                <p>{t("Upload document")}</p>
                <div
                  className="file d-flex flex-row align-items-center gap-4 c-pointer"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <UploadIcon className="c-pointer" />
                  {documentFile?.path || t("Add document")}
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-6 col-md-12 d-flex flex-row align-items-center gap-2">
                <input
                  type="checkbox"
                  checked={teacherDocument?.is_consent == "1"}
                  value={teacherDocument?.is_consent}
                  onChange={() =>
                    setTeacherDocument({
                      ...teacherDocument,
                      is_consent: teacherDocument.is_consent == "0" ? "1" : "0",
                    })
                  }
                />
                <div>
                  {t("I consent to CV SA receiving this document, storing this information and securely sharing with schools through the CV SA platform.")}
                </div>
              </div>
              <div className="col-lg-6 col-md-12 d-flex align-items-center flex-row gap-5">
                <ModalButton type={"cancel"} isEdit={false} onClick={onHide} />
                <ModalButton type={"save"} onClick={handleSubmit} />
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

const ViewDocumentModal = ({ show, onHide, documentData }) => {
  return (
    <Fragment>
      <Dialog open={show} maxWidth="md" fullWidth onClose={() => onHide()}>
        <DialogContent>
          <FileViewer
            fileType={documentData?.extension}
            filePath={`https://api.madaresweb.mvp-apps.ae/api/viewFile?path=${documentData?.document_path}`}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

const ModalButton = ({ type, isEdit, ...rest }) => {
  const { t } = useTranslation();
  const bg =
    type === "save" ? "rgba(0, 140, 119, 1)" : "rgba(255, 255, 255, 1)";
  const color = type === "save" ? "white" : "#9d9d9d";
  const text = type === "save" ? "Save" : isEdit ? "Delete" : "Cancel";
  const shadowColor = type === "save" ? "#008c774b" : "#0000004b";
  const borderColor = type === "save" ? "#008C77" : "rgba(221, 221, 221, 1)";
  return (
    <>
      <button
        style={{ borderColor, shadowColor, color, backgroundColor: bg }}
        {...rest}
      >
        {t(text)}
      </button>
    </>
  );
};
