import { t } from "i18next";
import React, { useEffect, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
// import './index.css';
import { toastAlert } from "../../../layouts/toastAlert/toastAlert";

const ManyFilePicker = ({
  children,
  setAcademyFiles,
  academyFiles,
  academyFilesInfo,
  setAcademyFilesInfo,
}) => {
  const [updatedAcademyFiles, setUpdatedAcademyFiles] = useState(
    academyFiles?.slice()
  );
  const [filesInfo, setFilesInfo] = useState([]);

  useEffect(() => {
    setUpdatedAcademyFiles(academyFiles?.slice());
  }, [academyFiles]);

  useEffect(() => {
    setFilesInfo(academyFilesInfo?.slice());
  }, [academyFilesInfo]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          let array = updatedAcademyFiles?.slice();
          let infoArr = filesInfo?.slice();
          array.push(file);
          infoArr.push(`${file.path} - ${file.size} bytes`);
          setAcademyFilesInfo([...infoArr]);
          setAcademyFiles([...array]);
          toastAlert(t("File uploaded successfuly"), undefined, "success");
        };
        reader.onerror = function (error) {
          toastAlert(t("Error while uploading files"), undefined, "warning");
        };
      });
    },
    [updatedAcademyFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  var files = filesInfo?.map((item, i) => (
    <li key={i} style={{ paddingTop: "15px" }}>
      {item}
      {/* <i
          title="remove avatar"
          aria-hidden="true"
          className="fa fa-window-close"
          style={{ color: "red", marginLeft: "8px" }}
          onClick={() => removeFile(item, i)}
        /> */}
      <svg
        style={{ cursor: "pointer", marginLeft: "10px" }}
        onClick={() => removeFile(item, i)}
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="17"
        viewBox="0 0 12.978 10.383"
      >
        <path
          id="Icon_material-delete-sweep"
          data-name="Icon material-delete-sweep"
          d="M11.436,13.787h2.6v1.3h-2.6Zm0-5.191h4.542v1.3H11.436Zm0,2.6h3.893v1.3H11.436ZM3.649,15.085a1.3,1.3,0,0,0,1.3,1.3H8.84a1.3,1.3,0,0,0,1.3-1.3V8.6H3.649Zm7.138-8.436H8.84L8.191,6H5.6l-.649.649H3v1.3h7.787Z"
          transform="translate(-3 -6)"
          fill="#fc3e3e"
        />
      </svg>
    </li>
  ));
  const removeFile = (item, i) => {
    const newAcademyFilesInfo = [...academyFilesInfo];
    newAcademyFilesInfo.splice(newAcademyFilesInfo.indexOf(item), 1);
    setAcademyFilesInfo(newAcademyFilesInfo);

    const newAcademyFiles = [...academyFiles];
    newAcademyFiles.splice(newAcademyFiles.indexOf(item), 1);
    setAcademyFiles(newAcademyFiles);
  };

  return (
    <>
      <div
        style={{ width: "50%", paddingTop: "20px" }}
        {...getRootProps({ className: "dropzone" })}
      >
        <input {...getInputProps()} />
        {children}
      </div>
      <aside>
        <ul>{files}</ul>
      </aside>
    </>
  );
};

export default ManyFilePicker;
