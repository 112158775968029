import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCitizenships,
  getDegreeLevels,
  getJobTitle,
  getRequiredCertification,
} from "../../../../../../store/actions/Jobs";
import Select from "react-select";
import { t } from "i18next";
import Steps from "./Steps";
import Rte from "../../../../Common/CreateArticle/Summernote/Rte";

let lng = JSON.parse(localStorage.getItem("language"))?.lang;

const Requirements = ({
  editPostHandler,
  back,
  setRequirementsInfo,
  handleRequirementsInfoChange,
  requirementsInfo,
  submitNewJobStep2,
  editPost,
  draft
}) => {
  const dispatch = useDispatch();
  const [loadingModalShow, setLoadingModalShow] = useState(true);
  const jobTitles = useSelector((state) => state?.jobTitles?.jobTitles);
  const jobTitlesLoading = useSelector((state) => state?.jobTitles?.loading);
  const degrees = useSelector((state) => state?.degrees?.degrees);
  const degreesLoading = useSelector((state) => state?.degrees?.loading);
  const requiredCertification = useSelector(
    (state) => state?.requiredCertification?.requiredCertification
  );
  const requiredCertificationLoading = useSelector(
    (state) => state?.requiredCertification?.loading
  );
  const citizenships = useSelector(
    (state) => state?.citizenships?.citizenships
  );
  const citizenshipsLoading = useSelector(
    (state) => state?.citizenships?.loading
  );
  useEffect(() => {
    setLoadingModalShow(
      jobTitlesLoading ||
        degreesLoading ||
        requiredCertificationLoading ||
        citizenshipsLoading
    );
  }, [
    jobTitlesLoading,
    degreesLoading,
    requiredCertificationLoading,
    citizenshipsLoading,
  ]);
  useEffect(() => {
    dispatch(getJobTitle(lng === "arab" ? 1 : 2));
    dispatch(getDegreeLevels(lng === "arab" ? 1 : 2));
    dispatch(getRequiredCertification(lng === "arab" ? 1 : 2));
    dispatch(getCitizenships(lng === "arab" ? 1 : 2));
  }, []);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  const customStyles = {
    control: () => ({
      display: "flex",
      alignItems: "center",
      width: width > 849 ? "100%" : "95%",
      minHeight: "50px",
      backgroundColor: "white",
      border: "1px solid",
      borderRadius: "6px",
      borderColor: "rgba(112, 112, 112, 1)",
      marginBottom: "30px",
    }),
  };

  return (
    <Fragment>
      <div className={editPost? "add-job-step-2 mt-5" : "add-job-step-2"}>
        {/* <div className={editPost ? null : "add-job-step-2-steps"}> */}
        <div className={editPost ? null : "container"}>
          <Steps currentStep={2} />
          <div style={editPost? {boxShadow: 'unset', marginTop: '0px', marginBottom: '0px', paddingTop:'0px'}:null} className="add-job-step-2-main">
            {/* Candidates and Education Section 1 */}
            <div className="add-job-step-2-candidates-education">
              <div className="add-job-step-2-candidates">
                <div className="add-job-step-2-title">Candidates</div>
                <div className="add-job-step-2-label">
                  Types of education who can apply for this job
                </div>
                {/* <input className="add-job-step-2-input-field" /> */}
                <Select
                  styles={customStyles}
                  value={requirementsInfo.types_of_educators}
                  name="types_of_educators"
                  onChange={(selectedOption) =>
                    handleRequirementsInfoChange({
                      target: {
                        value: selectedOption,
                        name: "types_of_educators",
                      },
                    })
                  }
                  options={jobTitles?.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                  }))}
                  isMulti
                />
                <div className="add-job-step-2-label">Special Major</div>
                <input
                  type="text"
                  name="specific_major"
                  value={requirementsInfo.specific_major}
                  onChange={handleRequirementsInfoChange}
                  className="add-job-step-2-input-field"
                />
              </div>
              <div className="add-job-step-2-educations">
                <div className="add-job-step-2-title">Education</div>
                <div className="add-job-step-2-label">
                  Min.level of education
                </div>
                <select
                  name="minimum_level_of_education_id"
                  value={requirementsInfo.minimum_level_of_education_id}
                  onChange={handleRequirementsInfoChange}
                  className="add-job-step-2-input-field"
                >
                  <option value="">{t("Select Level")}</option>
                  {degrees?.map((item, i) => (
                    <option key={i} value={item.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
                <div className="add-job-step-2-label">
                  Min. Teaching Experience
                </div>
                <input
                  name="minimum_teaching_experience"
                  value={requirementsInfo.minimum_teaching_experience}
                  onChange={(e) =>
                    handleRequirementsInfoChange({
                      target: {
                        value: e.target.value.replace(/\D/, ""),
                        name: "minimum_teaching_experience",
                      },
                    })
                  }
                  type="text"
                  className="add-job-step-2-input-field"
                  placeholder={t("Years")}
                />
              </div>
            </div>

            {/* Certifications Required Section 2  */}
            <div>
              <div className="add-job-step-2-title">
                Certifications Required
              </div>
              <div className="add-job-step-2-certification">
                {requiredCertification?.map((item, index) => (
                  <>
                    <div>
                      <input
                        className="add-job-step-2-certification-check-box"
                        type="checkbox"
                        defaultChecked={requirementsInfo?.required_certifications?.find((x) => x.value === item?.id)?.value}
                        value={requirementsInfo.required_certifications}
                        onChange={(selectedOption) =>
                          selectedOption?.target?.checked
                            ? setRequirementsInfo({
                                ...requirementsInfo,
                                required_certifications: [
                                  ...(requirementsInfo.required_certifications ||
                                    []),
                                  { value: item?.id, label: item?.name },
                                ],
                              })
                            : setRequirementsInfo({
                                ...requirementsInfo,
                                required_certifications:
                                  requirementsInfo.required_certifications?.filter(
                                    (x) => x.value !== item?.id
                                  ),
                              })
                        }
                      />
                    </div>
                    <div>
                      <label className="add-job-step-2-certification-label">
                        {item?.name}
                      </label>
                    </div>
                  </>
                ))}
              </div>
              <div>
                {requirementsInfo?.required_certifications?.filter(x => x.label === "Other Certification")?.[0]?
                  <>
                    <div className="add-job-step-2-label">
                  Other Certification
                </div>
                <input
                  name="other_required_certifications"
                  value={requirementsInfo?.other_required_certifications}
                  onChange={handleRequirementsInfoChange}
                  type="text"
                  className="add-job-step-2-input-field"
                />
                  </>: null
                }
              </div>

            </div>

            {/* Citizenship Match Required  Section 3 */}
            <div className="add-job-step-2-citizenship-title add-job-step-2-title add-job-step-2-citizenship-match">
              <input
                className="add-job-step-2-certification-check-box"
                type="checkbox"
                checked={requirementsInfo.citizenship_match_required == "1"}
                value={requirementsInfo.citizenship_match_required}
                onChange={() =>
                  handleRequirementsInfoChange({
                    target: {
                      value:
                        requirementsInfo.citizenship_match_required == "0"
                          ? "1"
                          : "0",
                      name: "citizenship_match_required",
                    },
                  })
                }
              />
              <div>Citizenship Match Required</div>
            </div>

            <div className="add-job-step-2-citizenship-required">
              {requirementsInfo?.citizenship_match_required == "1" ? (
                <Select
                  styles={customStyles}
                  value={requirementsInfo.citizenships}
                  name="citizenships"
                  onChange={(selectedOption) =>
                    handleRequirementsInfoChange({
                      target: { value: selectedOption, name: "citizenships" },
                    })
                  }
                  placeholder={t("Select Citizenship(s)")}
                  options={citizenships?.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                  }))}
                  isMulti
                />
              ) : null}
            </div>

            {/* Other Requirements Section 4 */}
            <div>
              <div className="add-job-step-2-label">Other Requirements</div>
              <Rte changeFunc={setRequirementsInfo} obj={requirementsInfo} name= 'other_requirements' />
              {/* <textarea
                className="add-job-step-2-text-area"
                rows="4"
                value={requirementsInfo.other_requirements}
                onChange={(e) =>
                  setRequirementsInfo({
                    ...requirementsInfo,
                    other_requirements: e.target.value,
                  })
                }
              /> */}
            </div>

            {/* Next And Back Button Section 4  */}
            <div className="add-job-step-2-next-back-btn">
              <button
                className="add-job-step-2-next-btn"
                onClick={draft? submitNewJobStep2 : editPost ? editPostHandler : submitNewJobStep2}
              >
                Continue To Benefits
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="Icon_material-arrow_back"
                    data-name="Icon material-arrow_back"
                    d="M6,16.5H24.255L15.87,8.115,18,6,30,18,18,30l-2.115-2.115,8.37-8.385H6Z"
                    transform="translate(-6 -6)"
                    fill="#fff"
                  />
                </svg>
              </button>
              <div className="add-job-step-2-back-btn" onClick={() => back(0)}>
                Back
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Requirements;
